import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {TournamentType} from "types/TournamentType";
import {ResultTable, TournamentReducerType} from "store/tournament/type/reducerType";
import {PaginationType} from "types/Pagination";

const initialState: TournamentReducerType = {
    list: null,
    listPagination: {limit: 12, total: null},
    fullTournament: null,
    editTournament: null,
    newLogo: null,
    resultTable: null,
    resultTableSortId: null,
    resultTableSortOrder: 'top',
}

export const slice = createSlice({
    name: 'TournamentReducer',
    initialState,
    reducers: {
        setTournamentListAction: (state, action: PayloadAction<Array<TournamentType> | null>) : void => {
            if (action.payload === null) {
                state.list = null
                // state.listPagination = {...state.listPagination, total: null}
                state.listPagination =  initialState.listPagination;
                return
            }
            state.list = [...state.list ?? [], ...action.payload]
        },
        setTournamentListPaginationAction: (state, action: PayloadAction<PaginationType>) : void => {
            state.listPagination = action.payload
        },
        changePositionAction: (state, action: PayloadAction<{firstId: number, secondId: number}>) : void => {
            if (state.list === null) return;

            let firstIndex = state.list.findIndex(el => el.id === action.payload.firstId)
            let secondIndex = state.list.findIndex(el => el.id === action.payload.secondId)

            if (firstIndex === undefined || secondIndex === undefined) return;

            const tmp =  state.list[firstIndex]

            state.list[firstIndex] = state.list[secondIndex]
            state.list[secondIndex] = tmp

            // state.listPagination = action.payload
        },

        setFullTournamentAction: (state, action: PayloadAction<TournamentType | null>) : void => {
            state.fullTournament = action.payload
        },
        setEditTournamentAction: (state, action: PayloadAction<TournamentType | null>) : void => {
            state.editTournament = action.payload
        },
        setNewLogoAction: (state, action: PayloadAction<File | null>) : void => {
            state.newLogo = action.payload
        },
        setResultTableAction: (state, action: PayloadAction<ResultTable | null>) : void => {
            state.resultTable = action.payload
        },
        setResultTableSortIdAction: (state, action: PayloadAction<number | null>) : void => {
            state.resultTableSortId = action.payload
        },
        setResultTableSortOrderAction: (state, action: PayloadAction<'top' | 'bottom'>) : void => {
            state.resultTableSortOrder = action.payload
        },
    }
})

export const TournamentReducer = slice.reducer
export const TournamentActions = slice.actions



