import s from './index.module.scss'
import React, {InputHTMLAttributes, useMemo} from "react";
import {css} from "lib/customClassName/index";

interface PropsType extends InputHTMLAttributes<HTMLInputElement> {
	title?: string
	value: number | ''
	modes?: Array<'maxWidth'|'center'|'transparent'>
	change?: Function
}

const InputNumber = (props: PropsType) => {
	const {title, placeholder = '', value, modes: modeProps = [''], change = (str: string) => {}, ...other} = props

	const modes = useMemo(() => {
		return modeProps.map(el => s[el])
	}, [modeProps])

	const validate = (e:any) => {
		let key = e.keyCode || e.which
		key = String.fromCharCode( key )
		let regex = /[0-9]|\./
		if( !regex.test(key) ) {
			e.returnValue = false;
			if(e.preventDefault) e.preventDefault();
		}
	}

	const changeHandler = (e:any) => {
		change(e.target.value)
	}

	return (
		<div className={css(s.InputText, ...modes)}>
			<div className={css(s.title)}>{title}</div>
			<input placeholder={placeholder} type={"number"} value={value} className={css(s.input, ...modes)} onChange={changeHandler} onKeyPress={validate} {...other}/>
		</div>
	);
};

export default InputNumber;
