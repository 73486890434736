import s from './index.module.scss'
import {css} from "lib/customClassName";
import {useMemo} from "react";
import loadGif from '../../assets/images/Load.gif'

type PropsType = {
    text: string
    modes?: Array<'maxWidth'|'red'|'dark'|'grey'|'yellow'|'green'|'uppercase'|'mobilSmall'|'noRadius'|'active'|'disableLite'|'maxHeight'|''>
    click?: Function
    disable?: boolean
    isLoading?: boolean
    style?: any
}
export const Button = (props: PropsType) => {
    const {text, modes: modeProps = [''], click = () => {}, isLoading = false, disable = false, ...other} = props

    const modes = useMemo(() => {
        return [...modeProps.map(el => s[el]), s['mobilSmall']]
    }, [modeProps])

    const clickHandler = (e) => {
        click(e)
    }

    return (
        <button disabled={disable} {...other} className={css(s.Button, (isLoading && !disable) && s.isLoading, ...modes)} onClick={clickHandler}>
            <span>{text}</span>
            {isLoading && !disable && <img src={loadGif} alt="load"/>}
        </button>
    );
};
