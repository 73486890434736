import s from './style.module.scss'
import {css} from "lib/customClassName";
import {Button} from "view/components/button";
import {useNavigate, useParams} from "react-router-dom";
import {categoryListSelector} from "store/category/selector/getCategoryList";
import {useSelector} from "react-redux";
import {AdminCategoryItem} from "view/module/admin/adminCategoryItem";
import loadGif from "view/assets/images/Load.gif";

type PropsType = {}
export const CategoryList = (props: PropsType) => {
    const {id} = useParams()

    const navigate = useNavigate()

    const categoryList = useSelector(categoryListSelector)

    const handleAddCategoryNavigate = () => {
        navigate(`/admin/tournament/category-list/${id}/edit`)
    }

    if (!id) {
        navigate(`/`)
    }
    return (
        <div className={css(s.CategoryList)}>

            <div className={css(s.container)}>
                <Button text={`←    Перейти к турниру`} modes={['mobilSmall']} click={()=>navigate(`/admin/tournament/edit/${id}`)}/>
                <div className={css(s.head)}>
                    <div className={css(s.headTitle)}>
                        Управление категориями
                    </div>
                    <Button text={`Добавить +`} modes={[`maxWidth`, `red`, 'mobilSmall']} click={handleAddCategoryNavigate}/>
                </div>
                <div className={css(s.list)}>
                    {categoryList?.map(category =><AdminCategoryItem key={category.id} category={category}/>)}
                    {categoryList === null && <div className={css(s.loadingList)}>
                        <img src={loadGif} alt="load"/>
                        <span>Загрузка...</span>
                    </div>}
                    {categoryList !== null && !categoryList.length && <div className={css(s.emptyList)}>
                        Нет категорий
                    </div>}
                </div>
            </div>
        </div>
    );
};
