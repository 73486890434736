import s from './index.module.scss'
import {css} from "lib/customClassName"
import {useEffect, useState} from "react";
import {useAuth} from "store/auth/hook/useAuth"
import {useClickOutside} from "lib/useClickOutside";
import {EmailWarningPopup} from "view/module/emailWarningPopup";

export const EmailWarning = () => {

    const user = useAuth()

    const [showPopup, setShowPopup] = useState(false)

    useClickOutside(() => {
        setShowPopup(false)
    }, [`EmailWarning`])

    useEffect(() => {
        if (showPopup && (window.innerWidth < 451)) document.body.style.overflow = 'hidden'
        else document.body.style.overflow = 'visible'
    }, [showPopup])

    const showHandler = () => {
        setShowPopup(prev => {
            return !prev
        })
    }

    return (
        <div id={'EmailWarning'} className={css(s.warning)}>
            <svg onClick={showHandler} className={'icon'} xmlns="https://www.w3.org/2000/svg" width="800" height="800" viewBox="0 0 24 24"><g><g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"><path d="M12 8L12 12"></path><path d="M12 16L12 16"></path><circle cx="12" cy="12" r="10" data-name="--Circle"></circle></g></g></svg>

            {showPopup && <div className={css(s.popupBox, showPopup && s.showPopup)}><EmailWarningPopup closePopup={setShowPopup}/></div>}
        </div>
    )
}
