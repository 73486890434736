import s from './style.module.scss'
import { css } from "lib/customClassName";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "view/components/button";
import { useActions, useThunks } from "lib/reduxHook";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TaskItem } from "view/components/taskItem";
import { categoryThunk } from "store/category/thunk/categoryThunk";
import { resultThunk } from "store/result/thunk/resultThunk";
import { categoryListWithTaskSelector } from "store/category/selector/getCategoryListWithTask";
import { CategoryActions } from "store/category/reducer/CategoryReducer";
import { getTournamentListPaginationSelector } from "store/tournament/selector/getTournamentListPagination";
import { authFetch } from "lib/authFetch";
import { authThunk } from "store/auth/thunk/authThunk";
import { serverHttp } from "config/api/api";
import { useClickOutside } from "lib/useClickOutside";
import loadGif from "view/assets/images/Load.gif";
import {Helmet} from "react-helmet";
import { DESCRIPTION, KEYWORDS } from "config/constants";

export const AddResultPage = () => {
    const { id } = useParams()
    const location = useLocation()
    const navigate = useNavigate()

    const categoryListWithTask = useSelector(categoryListWithTaskSelector)

    const { setCategoryListWithTaskAction } = useActions(CategoryActions)

    const { getListWithTask } = useThunks(categoryThunk)

    // const { addResultItems } = useThunks(resultThunk)

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    },[])

    useEffect(() => {
        setCategoryListWithTaskAction(null)
        getListWithTask(Number(id))
    }, [])

    // const getResultHandler = (id: number) => {
    //     if (!categoryListWithTask?.length) {
    //         addResultItems(Number(id))
    //     }
    // }

    const [ currentCategory, setCurrentCategory ] = useState<any>(null)

    if (!id) return null
    return (
        <div className={ css(s.AddResultPage) }>
            <Helmet>
                <title> Forge | Результаты</title>
                <meta name="description" content={DESCRIPTION} />
                <meta name="keywords" content={KEYWORDS} />
            </Helmet>
            <div className={ css(s.container) }>
                <div className={ css(s.main) }>
                    <Button text={ `←    Назад` } modes={[`mobilSmall`]} click={ () => navigate(`/tournament/${ id }`) }/>
                    <div className={ css(s.titlePage) }>ЗАНЕСЕНИЕ РЕЗУЛЬТАТОВ</div>
                    <SelectCategory currentCategory={ currentCategory } setCurrentCategory={ setCurrentCategory }
                                    categoryList={ categoryListWithTask }/>
                    { categoryListWithTask !== null && categoryListWithTask?.map(category =>
                        <div key={ category.id } className={ css(s.list) }>
                            { category.taskList?.filter(task => !currentCategory || task.categoryId === currentCategory.id).map(task =>
                                <TaskItem key={ task.id } task={ task }/>
                            ) }
                            {/*{category.taskList !== null && !category?.taskList?.length && <div className={css(s.emptyList)}>Нет доступных заданий</div>}*/ }
                        </div>
                    ) }
                    {categoryListWithTask === null && <div className={css(s.loadingList)}>
                        <img src={loadGif} alt="load"/>
                        <span>Загрузка...</span>
                    </div>}
                    { categoryListWithTask !== null && !categoryListWithTask.find((el) => !!el?.taskList?.length) &&
                        <div className={ css(s.emptyList) }>Нет доступных заданий</div> }
                </div>
            </div>
        </div>
    );
};

const SelectCategory = ({ currentCategory, setCurrentCategory, categoryList }: any) => {

    // // const categoryList = useSelector(getAdminResultCategoryListSelector)
    // const [categoryList, setCategoryList] = useState<any>(null)

    const [ showSelectBox, setShowSelectBox ] = useState<boolean>(false)

    useClickOutside(() => setShowSelectBox(false), [ `currentCategory` ])

    const handleSelectCategory = (e: any, category: any) => {
        e.stopPropagation()
        setCurrentCategory(category)
        setShowSelectBox(false)
    }

    return (
        <div className={ css(s.SelectTournament) }>
            <div className={ css(s.title) }>Выбрать категорию</div>
            <div id={ `currentCategory` } className={ css(s.currentTournament, showSelectBox && s.active) }
                 onClick={ () => setShowSelectBox(prev => !prev) }>
                <div
                    className={ css(s.name) }>{ currentCategory === null ? `Все категории` : currentCategory.name }</div>
                <div className={ css(s.showSelectBtn) }>
                    <svg width="30" height="30" viewBox="0 0 12 8" xmlns="https://www.w3.org/2000/svg">
                        <path
                            d="M5.59021 7.15047L0.452036 2.0122C0.333101 1.89336 0.267578 1.73471 0.267578 1.56556C0.267578 1.3964 0.333101 1.23776 0.452036 1.11891L0.83034 0.740515C1.07685 0.494289 1.47749 0.494289 1.72363 0.740515L6.03836 5.05525L10.3579 0.735728C10.4768 0.616886 10.6354 0.55127 10.8044 0.55127C10.9737 0.55127 11.1322 0.616886 11.2513 0.735728L11.6295 1.11413C11.7484 1.23306 11.8139 1.39161 11.8139 1.56077C11.8139 1.72993 11.7484 1.88857 11.6295 2.00741L6.48659 7.15047C6.36728 7.26959 6.20798 7.33502 6.03864 7.33464C5.86864 7.33502 5.70943 7.26959 5.59021 7.15047Z"/>
                    </svg>
                </div>
                { showSelectBox && <div className={ css(s.tournamentList) }>
                    <div className={ css(s.item) } onClick={ (e) => handleSelectCategory(e, null) }>Все категории</div>
                    { categoryList?.map(el => <div key={ el.id } className={ css(s.item) }
                                                   onClick={ (e) => handleSelectCategory(e, el) }>{ el.name }</div>) }
                    { categoryList === null && <div className={ css(s.loadingList) }>
                        <img src={ loadGif } alt="load"/>
                        Загрузка...
                    </div> }
                </div> }
            </div>
        </div>
    );
};
