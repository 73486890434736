import s from './style.module.scss'
import {css} from "lib/customClassName";
import {TaskItem} from "view/components/taskItem";
import {Button} from "view/components/button";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import { getCategoryWithTaskItemListSelector } from "store/category/selector/getCategoryWithTaskItemList";
import React, { useEffect, useLayoutEffect } from "react";
import {Helmet} from "react-helmet";
import { DESCRIPTION, KEYWORDS } from "config/constants";

type PropsType = {}
export const AddResultItemPage = (props: PropsType) => {
    const {id} = useParams()
    const navigate = useNavigate()

    const taskItem = useSelector(getCategoryWithTaskItemListSelector(Number(id)))

    // const {getListWithTask} = useThunks(categoryThunk)

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    },[])

    useEffect(() => {
        if (!id || !taskItem) {
            navigate(`/tournament/list`)
        }
    },[id, taskItem])

    if (!id || !taskItem) {
        return null
    }
    return (
        <div className={css(s.AddResultItemPage)}>
            <Helmet>
                <title> Forge | {`${taskItem.name}`}</title>
                <meta name="description" content={`${taskItem.description}`} />
                <meta name="keywords" content={KEYWORDS} />
            </Helmet>
            <div className={css(s.container)}>
                <div className={css(s.main)}>
                    <Button text={`←    Назад`} modes={[`mobilSmall`]} click={() => navigate(`/tournament/add/${taskItem.tournamentId}`, {state:true})}/>
                    {/*<div className={css(s.titlePage)}>ЗАНЕСЕНИЕ РЕЗУЛЬТАТОВ</div>*/}
                    <TaskItem task={taskItem} isBig={true}/>
                </div>
            </div>
        </div>
    );
};
