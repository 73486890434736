import s from './style.module.scss'
import {css} from "lib/customClassName";
import {useMatch, useNavigate} from "react-router-dom";

export const ManagerNavigate = () => {
    const navigate = useNavigate()

    const top = 110

    return (
        <div className={css(s.ManagerNavigate)}>
            {/*<div style={{top: `${top}px`}} className={css(s.navItem, !!useMatch("/admin/tournament/*") && s.active)} onClick={()=>navigate(`tournament`)}>*/}
            {/*    турниры*/}
            {/*</div>*/}
            <div style={{top: `${top+0}px`}} className={css(s.navItem, !!useMatch("/manager/result/*") && s.active)} onClick={()=>navigate(`result`)}>
                прием результатов
            </div>
            <div style={{top: `${top+30}px`}} className={css(s.navItem, !!useMatch("/manager/tournament-user/*") && s.active)} onClick={()=>navigate(`tournament-user`)}>
                участники турнира
            </div>
            {/*<div style={{top: `${top+90}px`}} className={css(s.navItem, !!useMatch("/admin/user/*") && s.active)} onClick={()=>navigate(`user`)}>*/}
            {/*    пользователи*/}
            {/*</div>*/}
            {/*<div style={{top: `${top+120}px`}} className={css(s.navItem, !!useMatch("/admin/statistic/*") && s.active)} onClick={()=>navigate(`statistic`)}>*/}
            {/*    статистика*/}
            {/*</div>*/}
            {/*<div style={{top: `${top+150}px`}} className={css(s.navItem, !!useMatch("/admin/partner/*") && s.active)} onClick={()=>navigate(`partner`)}>*/}
            {/*    партнёры*/}
            {/*</div>*/}
        </div>
    );
};
