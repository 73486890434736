import {AppDispatch, RootState} from "store/ReduxStore";
import {serverHttp} from "config/api/api";
import {authFetch} from "lib/authFetch";
import { authThunk } from "store/auth/thunk/authThunk";
import { StatisticActions } from "store/statistic/reducer/statisticReducer";

export const statisticThunk = {
    getStatisticList: (startDate: Date | null, endDate: Date | null, tournamentId?: number | undefined, categoryId?: number | undefined) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            await new Promise((resolve) => {
                setTimeout(resolve, 300)
            })

            const pagination = getState().StatisticReducer.statisticListPagination
            const total = getState().StatisticReducer.statisticList !== null ? getState().StatisticReducer.statisticList?.length : 0

            const response:any = await authFetch(() => dispatch(authThunk.checkAuth()))
            (`${serverHttp}/api/statistic/list?limit=${pagination.limit}&offset=${total}
            ${startDate ? '&startDate='+Date.parse(`${startDate}`) : ''}
            ${endDate ? '&endDate='+(Date.parse(`${endDate}`) + (24 * 60 * 60 * 1000)) : ''}
            ${tournamentId ? '&tournamentId='+tournamentId : ''}
            ${categoryId ? '&categoryId='+categoryId : ''}`, {
                method: `GET`,
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            const data = await response.json()
            if (data?.error) {
                console.log(data.message)
                return
            }

            dispatch(StatisticActions.setStatisticListAction(data.rows))
            dispatch(StatisticActions.setStatisticListPaginationAction({limit: pagination.limit, total: data.count}))
            dispatch(StatisticActions.setAllPriceAction(data.allPrice))

        } catch (error: any) {
            console.log('error client', error)
        } finally {
            // dispatch(FetchingActions.setIsLoadingUserListAction(false))
        }
    },
}
