import s from './style.module.scss'
import { css } from "lib/customClassName";
import { useMatch, useNavigate } from "react-router-dom";
import { useState } from "react";

export const AdminNavigate = () => {
    const navigate = useNavigate()

    const [ isMobileShow, setIsMobileShow ] = useState(false)

    const top = 110

    const handleNavigate = (path: string) => {
        setIsMobileShow(false)
        navigate(path)
    }

    return (
        <div className={ css(s.AdminNavigate, isMobileShow && s.mobileShow) }>
            { !isMobileShow && <div className={ css(s.burgerBtn) } onClick={ () => setIsMobileShow(prev => !prev) }>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72" width="64px" height="64px">
                    <path
                        d="M56 48c2.209 0 4 1.791 4 4 0 2.209-1.791 4-4 4-1.202 0-38.798 0-40 0-2.209 0-4-1.791-4-4 0-2.209 1.791-4 4-4C17.202 48 54.798 48 56 48zM56 32c2.209 0 4 1.791 4 4 0 2.209-1.791 4-4 4-1.202 0-38.798 0-40 0-2.209 0-4-1.791-4-4 0-2.209 1.791-4 4-4C17.202 32 54.798 32 56 32zM56 16c2.209 0 4 1.791 4 4 0 2.209-1.791 4-4 4-1.202 0-38.798 0-40 0-2.209 0-4-1.791-4-4 0-2.209 1.791-4 4-4C17.202 16 54.798 16 56 16z"/>
                </svg>
            </div> }
            { isMobileShow && <div className={ css(s.burgerBtn) } onClick={ () => setIsMobileShow(prev => !prev) }>
                <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" height="50px"><path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"/></svg>
            </div> }
            <div style={ { top: `${ top }px` } }
                 className={ css(s.navItem, !!useMatch("/admin/tournament/*") && s.active) }
                 onClick={ () => handleNavigate(`tournament`) }>
                турниры
            </div>
            <div style={ { top: `${ top + 30 }px` } }
                 className={ css(s.navItem, !!useMatch("/admin/result/*") && s.active) }
                 onClick={ () => handleNavigate(`result`) }>
                прием результатов
            </div>
            <div style={ { top: `${ top + 60 }px` } }
                 className={ css(s.navItem, !!useMatch("/admin/tournament-user/*") && s.active) }
                 onClick={ () => handleNavigate(`tournament-user`) }>
                участники турнира
            </div>
            <div style={ { top: `${ top + 90 }px` } }
                 className={ css(s.navItem, !!useMatch("/admin/user/*") && s.active) }
                 onClick={ () => handleNavigate(`user`) }>
                пользователи
            </div>
            <div style={ { top: `${ top + 120 }px` } }
                 className={ css(s.navItem, !!useMatch("/admin/statistic/*") && s.active) }
                 onClick={ () => handleNavigate(`statistic`) }>
                статистика
            </div>
            <div style={ { top: `${ top + 150 }px` } }
                 className={ css(s.navItem, !!useMatch("/admin/partner/*") && s.active) }
                 onClick={ () => handleNavigate(`partner`) }>
                партнёры
            </div>
        </div>
    );
};
