import s from './style.module.scss'
import {css} from "lib/customClassName";
import {PartnerType} from "types/PartnerType";
import Empty_img from "view/assets/images/empty_img.jpg";
import {serverHttp} from "config/api/api";

type PropsType = {
    partnerList: Array<PartnerType>
}
export const TournamentPartners = (props: PropsType) => {
    const {partnerList} = props

    return (
        <div className={css(s.TournamentPartners)}>
            <div className={css(s.title)}>
                НАШИ ПАРТНЕРЫ
            </div>
            <div className={css(s.list)}>
                {partnerList.map((partner) => {
                    return <a href={partner.link} target={`_blank`} key={partner.id} className={css(s.partnerItem)}>
                        {partner.img === '' && <img src={Empty_img} alt="partner"/>}
                        {partner?.img !== '' && <img src={`${serverHttp}/upload/` + partner.img} alt="partner"/>}
                    </a>
                })}
            </div>
        </div>
    );
};
