import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PartnerType} from "types/PartnerType";

interface stateType {
	list: null | Array<PartnerType>
}

const initialState: stateType = {
	list: null,
}

export const slice = createSlice({
	name: 'PartnerReducer',
	initialState,
	reducers: {
		setPartnerListAction: (state, action: PayloadAction<Array<PartnerType> | null>): void => {
			state.list = action.payload
		},
		unshiftPartnerListAction: (state, action: PayloadAction<PartnerType>): void => {
			if (state.list === null) return
			state.list.unshift(action.payload)
		},
		updatePartnerAction: (state, action: PayloadAction<PartnerType>): void => {
			if (state.list === null) return
			let partner = state.list.find(el => el.id === action.payload.id);
			if (!partner) return;
			partner.img = action.payload.img
			partner.link = action.payload.link
			partner.name = action.payload.name
		},
		deletePartnerAction: (state, action: PayloadAction<{ id: number }>): void => {
			if (state.list === null) return
			state.list = state.list.filter(el => el.id !== action.payload.id);
		}
	}
})

export const PartnerReducer = slice.reducer
export const PartnerActions = slice.actions
