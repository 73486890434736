import s from './style.module.scss'
import {css} from "lib/customClassName";
import {useActions, useThunks} from "lib/reduxHook";
import {resultThunk} from "store/result/thunk/resultThunk";
import {useEffect, useState} from "react";
import {getAdminResultCategoryListSelector, getAdminResultListSelector} from "store/result/selector/getAdminResultList";
import {useSelector} from "react-redux";
import loadGif from "view/assets/images/Load.gif";
import {AdminResultItem} from "view/components/adminResultItem";
import {useClickOutside} from "lib/useClickOutside";
import {serverHttp} from "config/api/api";
import {authFetch} from "lib/authFetch";
import {authThunk} from "store/auth/thunk/authThunk";
import {getAdminResultPaginationListSelector} from "store/result/selector/getAdminResultPaginationList";
import {Button} from "view/components/button";
import {ResultActions} from "store/result/reducer/ResultReducer";
import {getTournamentListPaginationSelector} from "store/tournament/selector/getTournamentListPagination";

export const ResultPage = () => {

    const adminResultList = useSelector(getAdminResultListSelector)

    const adminResultPaginationList = useSelector(getAdminResultPaginationListSelector)

    const [isLoaderMoreBtn, setIsLoaderMoreBtn] = useState(false)

    const {setAdminResultListAction} = useActions(ResultActions)

    const {getAdminList} = useThunks(resultThunk)

    const [currentTournament, setCurrentTournament] = useState<any>(null)
    const [currentCategory, setCurrentCategory] = useState<any>(null)

    useEffect(() => {
        const ctrl = new AbortController();
        getAdminList(Number(currentTournament?.id), ctrl.signal)
        return () => {
            ctrl && ctrl.abort();
            setAdminResultListAction(null)
        }
    }, [currentTournament?.id])

    const handleMoreResult = async () => {
        setIsLoaderMoreBtn(true)
        await getAdminList(Number(currentTournament?.id))
        setIsLoaderMoreBtn(false)
    }

    return (
        <div className={css(s.ResultPage)}>
            <div className={css(s.head)}>
                Приём результатов
            </div>
            <div className={css(s.selectBox)}>
                <SelectTournament currentTournament={currentTournament} setCurrentTournament={setCurrentTournament} setCurrentCategory={ setCurrentCategory }/>
                {currentTournament !== null &&
                    <SelectCategory currentTournament={currentTournament} currentCategory={currentCategory} setCurrentCategory={setCurrentCategory}/>}
            </div>
            <div className={css(s.list)}>
                {adminResultList !== null && adminResultList.length === 0 &&
                    <div className={css(s.emptyList)}>Список пуст</div>}
                {adminResultList !== null && adminResultList.length > 0 &&
                    adminResultList.filter(el => currentCategory === null ? true : el.task?.categoryId === currentCategory.id).map(result =>
                        <AdminResultItem key={result.id} result={result}/>)}
                {adminResultList !== null && adminResultList.length < (adminResultPaginationList.total ? adminResultPaginationList.total : 0) &&
                    <div className={css(s.btnBox)}>
                        <Button text={'показать больше'} modes={[`uppercase`, 'maxWidth', `mobilSmall`]} isLoading={isLoaderMoreBtn} click={handleMoreResult}/>
                    </div>}
            </div>
        </div>
    );
};

const SelectTournament = ({currentTournament, setCurrentTournament, setCurrentCategory}: any) => {

    const [showSelectBox, setShowSelectBox] = useState<boolean>(false)

    const {limit} = useSelector(getTournamentListPaginationSelector)

    const [isLoaderMoreBtn, setIsLoaderMoreBtn] = useState(false)

    const [tournamentList, setTournamentList] = useState<any>(null)
    const [totalTournamentList, setTotalTournamentList] = useState<any>(null)

    const getList = async () => {
        const countList = tournamentList !== null ? tournamentList?.length : 0

        await new Promise((resolve) => {
            setTimeout(resolve, 300)
        })

        const response = await authFetch(() => authThunk.checkAuth())
        (`${serverHttp}/api/tournament/list?limit=${limit}&offset=${countList}`, {
            method: `GET`,
            headers: {
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()
        if (data?.error) {
            console.log(data.message)
            return
        }

        const newRow = [...tournamentList ?? [], ...data.rows]

        setTournamentList(newRow)
        setTotalTournamentList(data.count)
    }

    useEffect(() => {
        if (showSelectBox) {
            getList()
        }
        return () => {
            setTournamentList(null)
            setTotalTournamentList(null)
        }
    }, [showSelectBox])

    useClickOutside(() => setShowSelectBox(false), [`currentTournament`, `MoreBtn`])

    const handleSelectTournament = (e: any, tournament: any) => {
        e.stopPropagation()
        if (tournament === null) {
            setCurrentCategory(null)
        }
        setCurrentTournament(tournament)
        setShowSelectBox(false)
    }

    const handleMoreTournament = async (e) => {
        e.stopPropagation()
        setIsLoaderMoreBtn(true)
        await getList()
        setIsLoaderMoreBtn(false)
    }

    return (
        <div className={css(s.SelectTournament)}>
            <div className={css(s.title)}>Выбрать турнир</div>
            <div id={`currentTournament`} className={css(s.currentTournament, showSelectBox && s.active)}
                 onClick={() => setShowSelectBox(prev => !prev)}>
                <div className={css(s.name)}>{currentTournament === null ? `Все турниры` : currentTournament.name}</div>
                <div className={css(s.showSelectBtn)}>
                    <svg width="30" height="30" viewBox="0 0 12 8" xmlns="https://www.w3.org/2000/svg">
                        <path
                            d="M5.59021 7.15047L0.452036 2.0122C0.333101 1.89336 0.267578 1.73471 0.267578 1.56556C0.267578 1.3964 0.333101 1.23776 0.452036 1.11891L0.83034 0.740515C1.07685 0.494289 1.47749 0.494289 1.72363 0.740515L6.03836 5.05525L10.3579 0.735728C10.4768 0.616886 10.6354 0.55127 10.8044 0.55127C10.9737 0.55127 11.1322 0.616886 11.2513 0.735728L11.6295 1.11413C11.7484 1.23306 11.8139 1.39161 11.8139 1.56077C11.8139 1.72993 11.7484 1.88857 11.6295 2.00741L6.48659 7.15047C6.36728 7.26959 6.20798 7.33502 6.03864 7.33464C5.86864 7.33502 5.70943 7.26959 5.59021 7.15047Z"/>
                    </svg>
                </div>
                {showSelectBox && <div className={css(s.tournamentList)}>
                    <div className={css(s.item)} onClick={(e) => handleSelectTournament(e, null)}>Все турниры</div>
                    {tournamentList?.map(el => <div key={el.id} className={css(s.item)} onClick={(e) => handleSelectTournament(e, el)}>
                        {el.name}
                    </div>)}
                    {tournamentList === null && <div className={css(s.loadingList)}>
                        <img src={loadGif} alt="load"/>
                        Загрузка...
                    </div>}
                    {tournamentList !== null && tournamentList.length < (totalTournamentList ? totalTournamentList : 0) &&
                        <div  id={`MoreBtn`} className={css(s.btnBox)}>
                            <Button text={'показать больше'} modes={[`uppercase`, 'maxWidth', `mobilSmall`,`noRadius`,`grey`]} isLoading={isLoaderMoreBtn} click={handleMoreTournament}/>
                        </div>}
                </div>}
            </div>
        </div>
    );
};
const SelectCategory = ({currentCategory, setCurrentCategory, currentTournament}: any) => {

    // const categoryList = useSelector(getAdminResultCategoryListSelector)
    const [categoryList, setCategoryList] = useState<any>(null)

    const [showSelectBox, setShowSelectBox] = useState<boolean>(false)

    useClickOutside(() => setShowSelectBox(false), [`currentCategory`])

    const getList = async () => {

        await new Promise((resolve) => {
            setTimeout(resolve, 300)
        })

        const response = await authFetch(() => authThunk.checkAuth())

        (`${serverHttp}/api/category/list/${Number(currentTournament.id)}`, {
            method: `GET`,
            headers: {
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()
        if (data?.error) {
            console.log(data.message)
            return
        }

        setCategoryList(data)
    }
    useEffect(() => {
        if (showSelectBox) {
            getList()
        }
        return () => {
            setCategoryList(null)
        }
    }, [showSelectBox])

    const handleSelectCategory = (e: any, category: any) => {
        e.stopPropagation()
        setCurrentCategory(category)
        setShowSelectBox(false)
    }

    return (
        <div className={css(s.SelectTournament)}>
            <div className={css(s.title)}>Выбрать категорию</div>
            <div id={`currentCategory`} className={css(s.currentTournament, showSelectBox && s.active)}
                 onClick={() => setShowSelectBox(prev => !prev)}>
                <div className={css(s.name)}>{currentCategory === null ? `Все категории` : currentCategory.name}</div>
                <div className={css(s.showSelectBtn)}>
                    <svg width="30" height="30" viewBox="0 0 12 8" xmlns="https://www.w3.org/2000/svg">
                        <path
                            d="M5.59021 7.15047L0.452036 2.0122C0.333101 1.89336 0.267578 1.73471 0.267578 1.56556C0.267578 1.3964 0.333101 1.23776 0.452036 1.11891L0.83034 0.740515C1.07685 0.494289 1.47749 0.494289 1.72363 0.740515L6.03836 5.05525L10.3579 0.735728C10.4768 0.616886 10.6354 0.55127 10.8044 0.55127C10.9737 0.55127 11.1322 0.616886 11.2513 0.735728L11.6295 1.11413C11.7484 1.23306 11.8139 1.39161 11.8139 1.56077C11.8139 1.72993 11.7484 1.88857 11.6295 2.00741L6.48659 7.15047C6.36728 7.26959 6.20798 7.33502 6.03864 7.33464C5.86864 7.33502 5.70943 7.26959 5.59021 7.15047Z"/>
                    </svg>
                </div>
                {showSelectBox && <div className={css(s.tournamentList)}>
                    <div className={css(s.item)} onClick={(e) => handleSelectCategory(e, null)}>Все категории</div>
                    {categoryList?.map(el => <div key={el.id} className={css(s.item)}
                                                 onClick={(e) => handleSelectCategory(e, el)}>{el.name}</div>)}
                    {categoryList === null && <div className={css(s.loadingList)}>
                        <img src={loadGif} alt="load"/>
                        Загрузка...
                    </div>}
                </div>}
            </div>
        </div>
    );
};
