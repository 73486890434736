import {ReactNode, useLayoutEffect, useState} from "react";
import {useAuth} from "store/auth/hook/useAuth";
import {useThunks} from "lib/reduxHook";
import {profileThunk} from "store/profile/thunk/profileThunk";
import {useSelector} from "react-redux";
import {getProfileSelector} from "store/profile/selector/getProfile";

type PropsType = {
    children: ReactNode
}
export const ProfileProvider = (props: PropsType) => {
    const {children} = props

    const user = useAuth()

    const [isCheck, setIsCheck] = useState(false)

    const profile = useSelector(getProfileSelector)

    const {getMyProfile} = useThunks(profileThunk)

    const checkProfile = async () => {
        if (profile !== null) return
        setIsCheck(false)
        !!user?.id && await getMyProfile()
        setIsCheck(true)
    }

    useLayoutEffect(() => {
        checkProfile().then(r => {})
    }, [user])

    if (!isCheck) return null
    else return <>{children}</>
};
