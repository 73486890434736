import s from 'view/module/tournament/tournamentTable/style.module.scss'
import { css } from "lib/customClassName";
import { TaskType } from "types/TaskType";
import { useSelector } from "react-redux";
import { getResultTableSelector } from "store/tournament/selector/getResultTable";
import { useEffect, useRef, useState } from "react";
import { useActions, useThunks } from "lib/reduxHook";
import { tournamentThunk } from "store/tournament/thunk/tournamentThunk";
import loadGif from "view/assets/images/Load.gif";
import { getCurrentCategoryListSelector } from "store/tournament/selector/getCurrentCategoryList";
import { CategoryType } from "types/CategoryType";
import { useClickOutside } from "lib/useClickOutside";
import { Popup } from "view/components/popup";
import { Button } from "view/components/button";
import { ResultActions } from "store/result/reducer/ResultReducer";
import { TournamentActions } from "store/tournament/reducer/tournamentReducer";
import Empty_img from "view/assets/images/empty_img.jpg";
import { serverHttp } from "config/api/api";
import { getResultSortOrderSelector } from "store/tournament/selector/getResultSortOrder";
import { useNavigate } from "react-router-dom";

type PropsType = {
    paidMe: any,
    tournamentStatus: string
}
export const TournamentTable = (props: PropsType) => {
    const { paidMe, tournamentStatus } = props

    const tableResultTable = useSelector(getResultTableSelector)
    const currentCategoryList = useSelector(getCurrentCategoryListSelector)
    const resultSortOrder = useSelector(getResultSortOrderSelector)

    const [ activeSelect, setActiveSelect ] = useState<CategoryType | null>(null)
    const [ showSelectBox, setShowSelectBox ] = useState<boolean>(false)

    useClickOutside(() => setShowSelectBox(false), [ `selectCategory` ])

    const { getResultTable } = useThunks(tournamentThunk)

    useEffect(() => {
        if (!!activeSelect) {
            setShowSelectBox(false)
            getResultTable(Number(activeSelect.id))
        }
    }, [ activeSelect ])

    useEffect(() => {
        if (!currentCategoryList) return
        const category = currentCategoryList?.find(el => el.id === paidMe?.categoryId)
        if (category) {
            setActiveSelect(category)
        } else setActiveSelect(currentCategoryList[0])
    }, [])

    const speed = useRef(2)
    const table = useRef<any>(null)
    const [ drag, setDrag ] = useState(false)
    const left = useRef(0)
    const coorX = useRef(0)

    const moveHandler = (e: any) => {
        if (!table?.current) return

        if (e.buttons === 1) {
            setDrag(true)
            if (window?.getSelection) {
                window?.getSelection()?.removeAllRanges();
            }
            table.current.scrollLeft = left.current - ( e.pageX - table.current.offsetLeft - coorX.current ) * speed.current;
        }
    }

    if (!currentCategoryList?.length) return null
    return (
        <div className={ css(s.TournamentTable) }>
            <div className={ css(s.tableTitle) }>Турнирная таблица</div>
            { tournamentStatus === `Завершен` &&
                <div className={ css(s.finishStatus) }><span>{ tournamentStatus }</span></div> }
            { tournamentStatus === `Завершен, ждет проверки` &&
                <div className={ css(s.finishStatus) }><span>Завершен, идёт проверка результатов</span></div> }
            { !!currentCategoryList?.length && <div id={ `selectCategory` } className={ css(s.categoryFilter) }>
                <div className={ css(s.activeSelect, showSelectBox && s.active) } onClick={ () => {
                    setShowSelectBox(prev => !prev)
                } }>
                    <span>{ activeSelect?.name }</span>
                    <div className={ css(s.showSelectBtn) }>
                        <svg width="30" height="30" viewBox="0 0 12 8" xmlns="https://www.w3.org/2000/svg">
                            <path
                                d="M5.59021 7.15047L0.452036 2.0122C0.333101 1.89336 0.267578 1.73471 0.267578 1.56556C0.267578 1.3964 0.333101 1.23776 0.452036 1.11891L0.83034 0.740515C1.07685 0.494289 1.47749 0.494289 1.72363 0.740515L6.03836 5.05525L10.3579 0.735728C10.4768 0.616886 10.6354 0.55127 10.8044 0.55127C10.9737 0.55127 11.1322 0.616886 11.2513 0.735728L11.6295 1.11413C11.7484 1.23306 11.8139 1.39161 11.8139 1.56077C11.8139 1.72993 11.7484 1.88857 11.6295 2.00741L6.48659 7.15047C6.36728 7.26959 6.20798 7.33502 6.03864 7.33464C5.86864 7.33502 5.70943 7.26959 5.59021 7.15047Z"/>
                        </svg>
                    </div>
                </div>
                { showSelectBox && <div className={ css(s.selectCategoryBox) }>
                    { currentCategoryList && currentCategoryList?.map(category =>
                        <div key={ category.id } className={ css(s.categoryItem) }
                             onClick={ () => setActiveSelect(category) }>
                            { category.name }
                        </div>
                    ) }
                </div> }
            </div> }
            { tableResultTable !== null &&
                <div className={ css(s.table, drag && s.drag) }
                     ref={ table }
                     onMouseMove={ moveHandler }
                    // onDragStart={ e => e.stopPropagation() }
                     onMouseDown={ e => {
                         coorX.current = e.pageX - table.current.offsetLeft;
                         // drag.current = true
                     } }
                     onMouseUp={ e => {
                         left.current = table.current.scrollLeft;
                         // drag.current = false
                         setDrag(false)
                     } }
                     onScroll={ (e: any) => {
                         if (!drag) {
                             left.current = table.current.scrollLeft;
                         }
                     } }
                >
                    <TableHead taskList={ tableResultTable?.taskList ?? [] }/>
                    { resultSortOrder === 'top' && tableResultTable.resultList && <div className={ css(s.tableList) }>
                        { tableResultTable.resultList.map(member => <TableResultItem key={ member.user.id }
                                                                                     member={ member }
                                                                                     taskIdList={ tableResultTable?.taskList?.map(task => Number(task.id)) }/>) }
                    </div> }
                    { resultSortOrder === 'bottom' && tableResultTable.resultList &&
                        <div className={ css(s.tableList) }>
                            { tableResultTable.resultList.map(member => <TableResultItem key={ member.user.id }
                                                                                         member={ member }
                                                                                         taskIdList={ tableResultTable?.taskList?.map(task => Number(task.id)) }/>).reverse() }
                        </div> }
                    { !tableResultTable.resultList && <div className={ css(s.loadingList) }>
                        <img src={ loadGif } alt="load"/>
                        <span>Загрузка...</span>
                    </div> }
                    { tableResultTable.resultList !== null && !tableResultTable.resultList.length &&
                        <div className={ css(s.emptyList) }>Нет участников</div> }
                </div> }
            { tableResultTable === null && <div className={ css(s.loadingList) }>
                <img src={ loadGif } alt="load"/>
                <span>Загрузка...</span>
            </div> }
        </div>
    );
};

type TableHeadPropsType = {
    taskList: Array<TaskType>
}
const TableHead = (props: TableHeadPropsType) => {
    const { taskList } = props

    const navigate = useNavigate()

    const { setResultTableSortIdAction } = useActions(TournamentActions)

    const resultSortOrder = useSelector(getResultSortOrderSelector)

    const { setResultTableSortOrderAction } = useActions(TournamentActions)

    const [ activeSortId, setActiveSortId ] = useState<null | number>(null)

    useEffect(() => {
        setResultTableSortOrderAction('top')
        setResultTableSortIdAction(activeSortId)
    }, [ activeSortId ])

    const [ fullTask, setFullTask ] = useState<any>(null)

    const setResultTableSortOrderHandler = (sortId: number | null) => {
        setResultTableSortOrderAction(resultSortOrder === 'top' ? 'bottom' : 'top')
        setActiveSortId(sortId)
    }

    const openTaskHandler = () => {
        console.log(fullTask.id)
        navigate(`/tournament/task-item/${ fullTask.id }`)
    }

    return (
        <div className={ css(s.TableHead) }>
            <div className={ css(s.name) }>Участник</div>
            <div className={ css(s.top) } onClick={ () => setResultTableSortOrderHandler(null) }>
                <div className={ css(s.sortBox, activeSortId === null && s.sort) }>
                        <span className={ css(s.topSort, resultSortOrder === 'top' && s.active) }
                        ></span>
                    <span className={ css(s.bottomSort, resultSortOrder === 'bottom' && s.active) }
                    ></span>
                </div>
                <span className={ css(s.topText) }>Балы</span>
            </div>
            {/*{!!taskList.length && <div className={css(s.place)}>Место</div>}*/ }
            {/*{ !!taskList.length &&*/ }
            {/*    <div className={ css(s.top) } onClick={ () => setResultTableSortOrderHandler(null) }>*/ }
            {/*        <div className={ css(s.sortBox, activeSortId === null && s.sort) }>*/ }
            {/*            <span className={ css(s.topSort, resultSortOrder === 'top' && s.active) }*/ }
            {/*            ></span>*/ }
            {/*            <span className={ css(s.bottomSort, resultSortOrder === 'bottom' && s.active) }*/ }
            {/*            ></span>*/ }
            {/*        </div>*/ }
            {/*        <span className={ css(s.topText) }>Балы</span>*/ }
            {/*    </div> }*/ }
            { taskList.map(task =>
                <div key={ task.id }
                     className={ css(s.task) }
                     onClick={ () => setResultTableSortOrderHandler(Number(task.id)) }
                >
                    <div className={ css(s.sortBox, ( activeSortId === task.id ) && s.sort) }>
                        <span className={ css(s.topSort, resultSortOrder === 'top' && s.active) }></span>
                        <span className={ css(s.bottomSort, resultSortOrder === 'bottom' && s.active) }></span>
                    </div>
                    <div className={ css(s.taskName) }>
                        <span title={ task.name }>{ task.name }</span>
                        <span onClick={ (e) => {
                            e.stopPropagation()
                            setFullTask(task)
                        } }>подробнее</span>
                    </div>
                </div>) }
            { fullTask !== null && <Popup clickCloseBtnCallback={ () => setFullTask(null) }>
                <div className={ css(s.FullTask) }>
                    <div className={ css(s.fullTaskName) }>{ fullTask.name }</div>
                    <div
                        className={ css(s.fullTaskDescription) }>{ fullTask.description.length === 0 ? `Задание не описанно` : fullTask.description }</div>
                    <div className={ css(s.showBigTaskBtn) }><span onClick={ openTaskHandler }>Подробное описание</span>
                    </div>
                    <div className={ css(s.closeBtn) }><Button text={ `Закрыть` } modes={ [ `maxWidth` ] }
                                                               click={ () => setFullTask(null) }/></div>
                </div>
            </Popup> }
        </div>
    );
};

const getResultString = (typeId: number, value: number, point: number) => {
    if (value === null) return '-'
    if (point === 0) return '-'
    switch (typeId) {
        case 1: {
            // const value = parseInt(resultValue)
            if (value === 0) return `-`
            return `${ point } ( ${ ( value - value % 60 ) / 60 } : ${ ( value % 60 ) < 10 ? '0' : '' }${ value % 60 } )`

        }
        case 2: {
            // const value = parseInt(resultValue)
            if (value === 0) return `-`
            return `${ point } ( ${ ( value - value % 60 ) / 60 } : ${ ( value % 60 ) < 10 ? '0' : '' }${ value % 60 } )`
        }
        case 3: {
            if (value === 0) return `-`
            return `${ point } ( ${ value } )`
        }
        case 4: {
            // const value = parseInt(resultValue)
            if (value === 0) return `-`
            const gram = ( value % 100 ) > 9 ? ( value % 100 ) > 100 ? `${ value % 100 }` : `0${ value % 100 }` : `00${ value % 100 }`
            return `${ point } ( ${ ( value - value % 1000 ) / 1000 } . ${ gram } )`
        }
        case 5: {
            // const value = parseInt(resultValue)
            if (value === 0) return `-`
            const SM = ( value % 100 ) >= 10 ? `${ value % 100 }` : `0${ value % 100 }`
            return `${ point } ( ${ ( value - value % 100 ) / 100 } . ${ SM } )`
        }
    }
}
const getStringStatus = (status: string) => {
    switch (status) {
        case `new`:
            return `Не заполнен`
        case `await`:
            return `Результат подан`
        case `change`:
            return `Изменён администратором`
        case `access`:
            return `Подтверждён администратором`
        case `reject`:
            return `Отменён администратором`
        default:
            return ``
    }
}

type TableResultItemPropsType = {
    member: any
    taskIdList: Array<number>
}
const TableResultItem = (props: TableResultItemPropsType) => {
    const { member, taskIdList } = props

    const [ openVideo, setOpenVideo ] = useState<any>(null)
    const [ openProfile, setOpenProfile ] = useState<any>(null)
    const [ openAdminComment, setOpenAdminComment ] = useState<any>(null)
    const [ openTeam, setOpenTeam ] = useState<any>(null)

    const getUrlYoutube = (url: string) => {
        return /^https?\:\/\/(www\.)?youtu\.be/.test(url) ? url.replace(/^https?\:\/\/(www\.)?youtu\.be\/([\w-]{11}).*/, "$2") : url.replace(/.*\?v\=([\w-]{11}).*/, "$1");
    }

    return (
        <div className={ css(s.TableResultItem) }>
            { !member.user.team
                ?
                <div className={ css(s.name) } onClick={ () => setOpenProfile(member.user.profile) }>
                    <span className={ css(s.place) }>{ member.place === 0 ? `-` : member.place }</span>
                    <span>
                        <span>
                            { `${ member.user.profile.lastName } ${ member.user.profile.firstName }` }
                        </span>
                    </span>
                </div>
                :
                <div className={ css(s.teamName) }>
                    <span className={ css(s.place) }>{ member.place === 0 ? `-` : member.place }</span>
                    <span>
                        <span className={ css(s.commandName) } onClick={ () => setOpenTeam(member.user.team) }>
                            { `${ member.user.team.name }` }
                        </span>
                        <br/>
                        <span className={ css(s.members) }>
                            { member.user.team.members.map((member, index, arr) => `${ member.lastName } ${ member.firstName }${ index === arr.length - 1 ? '.' : ', ' }`) }
                       </span>
                    </span>
                </div> }
            <div className={ css(s.top) }>{ member.allPoint === 0 ? `-` : member.allPoint }</div>
            { taskIdList.map(taskId => <div key={ taskId } className={ css(s.result) }>
                <div>
                    { getResultString(
                        Number(member.resultMapList[taskId].taskTypeId),
                        member.resultMapList[taskId].value,
                        Number(member.resultMapList[taskId].point))
                    }
                </div>
                { member.resultMapList[taskId].status !== 'new' && <div className={ css(s.resultInfo) }>
                    <div className={ css(s.status, s[`${ member.resultMapList[taskId].status }`]) }
                         onClick={ () => setOpenAdminComment(member.resultMapList[taskId].adminComment) }
                         title={ getStringStatus(`${ member.resultMapList[taskId].status }`) }>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="https://www.w3.org/2000/svg">
                            <path
                                d="M8.6 14.6L15.65 7.55L14.25 6.15L8.6 11.8L5.75 8.95L4.35 10.35L8.6 14.6ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20Z"/>
                        </svg>
                    </div>
                    <div className={ css(s.videoBtn) }
                         onClick={ () => setOpenVideo(`${ member.resultMapList[taskId].youtube }`) }>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="https://www.w3.org/2000/svg">
                            <path
                                d="M9.5 12.5L16.5 8L9.5 3.5V12.5ZM6 16C5.45 16 4.979 15.804 4.587 15.412C4.195 15.02 3.99934 14.5493 4 14V2C4 1.45 4.196 0.979002 4.588 0.587002C4.98 0.195002 5.45067 -0.000664969 6 1.69779e-06H18C18.55 1.69779e-06 19.021 0.196002 19.413 0.588002C19.805 0.980002 20.0007 1.45067 20 2V14C20 14.55 19.804 15.021 19.412 15.413C19.02 15.805 18.5493 16.0007 18 16H6ZM2 20C1.45 20 0.979002 19.804 0.587002 19.412C0.195002 19.02 -0.000664969 18.5493 1.69779e-06 18V4H2V18H16V20H2Z"
                                fill="white"/>
                        </svg>
                    </div>
                </div> }
            </div>) }
            { openVideo !== null &&
                <Popup clickCloseBtnCallback={ () => setOpenVideo(null) }>
                    <div className={ css(s.VideoBlock) }>
                        <iframe width="100%" height="100%"
                                src={ `https://www.youtube.com/embed/${ getUrlYoutube(openVideo) }` }
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                    </div>
                </Popup>
            }
            { openProfile !== null &&
                <Popup clickCloseBtnCallback={ () => setOpenProfile(null) }>
                    <div className={ css(s.profilePopup) }>
                        <div className={ css(s.avatar) }>
                            { openProfile.avatar === '' && <img src={ Empty_img } alt="tournament_item"/> }
                            { openProfile.avatar !== '' &&
                                <img src={ `${ serverHttp }/upload/` + openProfile.avatar } alt="tournament_item"/> }
                        </div>
                        <div className={ css(s.name) }>
                            { `${ openProfile.lastName } ${ openProfile.firstName } ${ openProfile.surname }` }
                        </div>
                        { !!openProfile.sex.length && <div className={ css(s.sex) }>
                            Пол: { openProfile.sex }
                        </div> }
                        { !!openProfile.city.length && <div className={ css(s.city) }>
                            Город: { openProfile.city }
                        </div> }
                        { !!openProfile.gym.length && <div className={ css(s.gym) }>
                            Зал: { openProfile.gym }
                        </div> }
                        { !!openProfile.size.length && <div className={ css(s.size) }>
                            Размер одежды: { openProfile.size }
                        </div> }
                        { !!openProfile.weight.length && <div className={ css(s.weight) }>
                            Вес: { openProfile.weight }
                        </div> }
                        { !!openProfile.height.length && <div className={ css(s.height) }>
                            Рост: { openProfile.height }
                        </div> }
                        <div className={ css(s.closeBtnBox) }>
                            <Button text={ `Закрыть` } modes={ [ `maxWidth` ] } click={ () => setOpenProfile(null) }/>
                        </div>
                    </div>
                </Popup>
            }
            { openAdminComment !== null &&
                <Popup clickCloseBtnCallback={ () => setOpenAdminComment(null) }>
                    <div className={ css(s.adminCommentPopup) }>
                        { !!openAdminComment.length ? openAdminComment : 'Комментарий отсутствует' }
                    </div>
                </Popup>
            }
            { openTeam !== null &&
                <Popup clickCloseBtnCallback={ () => setOpenTeam(null) }>
                    <div className={ css(s.teamPopup) }>
                        <div className={ css(s.popupCommandName) }>
                            Команда: <span>{ openTeam.name }</span>
                        </div>
                        <div className={ css(s.memberListSubTitle) }>Участники:</div>
                        <div className={ css(s.memberList) }>
                            { openTeam.members.map((member, index, arr) => (
                                <div key={ member.id } className={ css(s.member) } onClick={ () => setOpenProfile(member) }>
                                    { `${ member.lastName } ${ member.firstName }` }
                                    { index === 0 && <span className={css(s.cap)}>(Капитан)</span> }
                                </div>
                            )) }
                        </div>

                        {/*{ !!openTeam.length ? openTeam : 'Комментарий отсутствует' }*/ }
                    </div>
                </Popup>
            }
        </div>
    );
};
