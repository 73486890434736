import s from './style.module.scss'
import { css } from "lib/customClassName";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useActions, useThunks } from "lib/reduxHook";
import { InputText } from "view/components/InputText";
import { RadioButton } from "view/components/RadioButton";
import { Button } from "view/components/button";
import { Skeleton } from "view/components/skeleton";
import { TaskType } from "types/TaskType";
import { TaskActions } from "store/task/reducer/TaskReducer";
import { getEditTaskSelector } from "store/task/selector/getEditTask";
import { taskThunk } from "store/task/thunk/taskThunk";
import { InputTextArea } from "view/components/InputTextArea";
import { Popup } from "view/components/popup";
import { Editor } from "@tinymce/tinymce-react";
import { tinyMCE } from "config/TinyMCE";

type PropsType = {}
export const EditTaskForm = (props: PropsType) => {
    const location = useLocation()

    const navigate = useNavigate()

    const taskItem = location.state?.task ?? undefined
    const categoryId = location.state?.categoryId ?? undefined

    const [ isNew, setIsNew ] = useState<boolean | null>(null)

    const [ isLoad, setIsLoad ] = useState(false)

    const [ isShowBigDescription, setIsShowBigDescription ] = useState(false)

    const [ errorName, setErrorName ] = useState<string | null>(null)

    const { id } = useParams()

    const editTask = useSelector(getEditTaskSelector)

    const { setEditTaskAction } = useActions(TaskActions)

    const { create, update } = useThunks(taskThunk)

    useEffect(() => {

        if (!taskItem) {
            setEditTaskAction({
                categoryId: Number(categoryId),
                tournamentId: Number(editTask?.tournamentId),
                name: '',
                description: '',
                bigDescription: '',
                isOpen: false,
                isHidden: true,
                taskTypeId: 1,

            })
            setIsNew(true)
            // setTimeout(() => setIsNew(true), 1000)

        } else {
            setEditTaskAction(taskItem)
            setIsNew(false)
            // setTimeout(() => setIsNew(false), 1000)
        }

        return () => {
            setEditTaskAction(null)
        }
    }, [])

    const changeFieldHandler = <T extends keyof TaskType>(field: T, value: T | string) => {
        if (!editTask) return;
        switch (field) {
            case 'name':
                setErrorName(null)
                setEditTaskAction({ ...editTask, name: value })
                break
            case 'description':
                setEditTaskAction({ ...editTask, description: value })
                break
            case 'bigDescription':
                setEditTaskAction({ ...editTask, bigDescription: value })
                break
            case 'isOpen':
                setEditTaskAction({ ...editTask, isOpen: value === `Открыта` })
                break
            case 'isHidden':
                setEditTaskAction({ ...editTask, isHidden: value !== `Виден участникам` })
                break
            case 'taskTypeId':
                switch (value) {
                    case 'Время Минимум':
                        setEditTaskAction({ ...editTask, taskTypeId: Number(1) })
                        break
                    case 'Время Максимум':
                        setEditTaskAction({ ...editTask, taskTypeId: Number(2) })
                        break
                    case 'Количество':
                        setEditTaskAction({ ...editTask, taskTypeId: Number(3) })
                        break
                    case 'Вес':
                        setEditTaskAction({ ...editTask, taskTypeId: Number(4) })
                        break
                    case 'Длина':
                        setEditTaskAction({ ...editTask, taskTypeId: Number(5) })
                        break
                }
                break
        }
    }
    const createTaskHandler = () => {
        if (!editTask?.name.length) {
            setErrorName('Поля не заполнены')
            return
        }
        setIsLoad(true)
        create(() => {
            setTimeout(() => {
                setIsNew(false)
                setIsLoad(false)
                setIsNew(false)
            }, 1000)
            // closePopup()
        })
    }
    const updateTaskHandler = () => {
        setIsNew(null)
        setIsLoad(true)
        update((id: number) => {
            setIsNew(false)
            setIsLoad(false)

            // closePopup()
        })
    }

    const getStringTaskTypeId = (id: number) => {
        switch (id) {
            case 1:
                return `Время Минимум`
            case 2:
                return `Время Максимум`
            case 3:
                return `Количество`
            case 4:
                return `Вес`
            case 5:
                return `Длина`
            default:
                return `Время Минимум`
        }
    }

    return (
        <div className={ css(s.EditTaskForm) }>
            <div className={ css(s.backBtnBox) }>
                <Button text={ `←    Назад` } click={ () => navigate(`/admin/tournament/category-list/${ id }`) }/>
            </div>
            <div className={ css(s.container) }>
                <div className={ css(s.head) }>
                    { isNew === null ? `...` : isNew ? `Создание задания` : `Редактирование` }
                </div>
                <div className={ css(s.main) }>
                    <div className={ css(s.form) }>
                        <InputText title={ `Название задания` } value={ editTask?.name ?? '' }
                                   change={ str => changeFieldHandler('name', str) } placeholder={ `Введите текст` }
                                   modes={ [ `maxWidth` ] }/>
                        <InputTextArea title={ `Краткое описание` } value={ editTask?.description ?? '' }
                                       change={ str => changeFieldHandler('description', str) }
                                       placeholder={ `Введите текст` }
                                       modes={ [ `maxWidth` ] }/>
                        <div className={ css(s.bigDescription) }>
                            <div className={ css(s.title) }>
                                Подробное описание
                            </div>
                            <div className={ css(s.editor) }>
                                <Editor
                                    value={ editTask?.bigDescription ?? '' }
                                    onEditorChange={ (newValue, editor) => {
                                        changeFieldHandler('bigDescription', newValue)
                                    } }
                                    init={ {
                                        height: 400,
                                        branding: false,
                                        statusbar: true,
                                        plugins: tinyMCE.plugins,
                                        toolbar: tinyMCE.toolbar,
                                        menubar: true,
                                        resize: true,
                                        directionality: 'ltr',
                                        language: 'ru',
                                        image_title: true,
                                        automatic_uploads: true,
                                        file_picker_types: 'image',
                                        file_picker_callback: tinyMCE.file_picker_callback,
                                    } }
                                />
                            </div>
                        </div>
                        {/*<Button text={'Подробное описание'} click={() => setIsShowBigDescription(true)}/>*/ }
                        <RadioButton title={ `Подача результатов` } value={ editTask?.isOpen ? `Открыта` : `Закрыта` }
                                     change={ str => changeFieldHandler('isOpen', str) }
                                     list={ [ `Открыта`, `Закрыта` ] }/>
                        <RadioButton title={ `Не виден участникам` }
                                     value={ editTask?.isHidden ? `Не виден участникам` : `Виден участникам` }
                                     change={ str => changeFieldHandler('isHidden', str) }
                                     list={ [ `Не виден участникам`, `Виден участникам` ] }/>
                        { isNew && <RadioButton title={ `Значение результата` }
                                                value={ getStringTaskTypeId(editTask?.taskTypeId as number) }
                                                change={ str => changeFieldHandler('taskTypeId', str) }
                                                list={ [ `Время Минимум`, `Время Максимум`, `Количество`, `Вес`, `Длина` ] }/> }
                        { !isNew && <RadioButton title={ `Значение результата` }
                                                 value={ getStringTaskTypeId(editTask?.taskTypeId as number) }
                                                 list={ [ `${ getStringTaskTypeId(editTask?.taskTypeId as number) }` ] }/> }
                    </div>
                    <div className={ css(s.btnBox) }>
                        { errorName !== null &&
                            <Button text={ errorName ?? 'Hello' } disable={ true } isLoading={ isLoad }
                                    modes={ [ `maxWidth`, `noRadius` ] }/> }
                        { errorName === null && isNew === null &&
                            <Button text={ ` ` } isLoading={ isLoad } modes={ [ `red`, `maxWidth`, `noRadius` ] }/> }
                        { errorName === null && isNew === false &&
                            <Button text={ `Сохранить    →` } isLoading={ isLoad }
                                    modes={ [ `red`, `maxWidth`, `noRadius` ] }
                                    click={ updateTaskHandler }/> }
                        { errorName === null && isNew === true && <Button text={ `Создать    →` } isLoading={ isLoad }
                                                                          modes={ [ `red`, `maxWidth`, `noRadius` ] }
                                                                          click={ createTaskHandler }/> }
                    </div>
                </div>
                {/*{isNew === null && <Skeleton mods={[`grey`]}/>}*/ }
            </div>
            { isShowBigDescription && <Popup clickCloseBtnCallback={ () => setIsShowBigDescription(false) }>
                <div className={ css(s.editBigDescription) }>
                    <InputTextArea placeholder={ `Введите текст` } modes={ [ 'maxWidth' ] }
                                   value={ editTask?.bigDescription ?? '' }
                                   change={ (str) => changeFieldHandler('bigDescription', str) }/>
                </div>
            </Popup> }
        </div>
    );
};
