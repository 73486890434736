import {Button} from "view/components/button";
import {LoginPopup} from "view/module/loginPopup";
import {useState} from "react";
import { css } from "lib/customClassName";
import s from './index.module.scss'

type PropsType = {
    text: string
}
export const LoginButton = (props: PropsType) => {
    const {text} = props

    const [showPopup, setShowPopup] = useState(false)

    return (
        <div className={css(s.LoginButton)}>
            <Button text={text} modes={[`uppercase`, `maxWidth`, `mobilSmall`]} click={() => setShowPopup(true)}/>
            {showPopup && <LoginPopup mode={'login'} close={()=>setShowPopup(false)}/>}
        </div>
    );
};
