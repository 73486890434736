import s from './style.module.scss'
import {useNavigate, useSearchParams} from 'react-router-dom'
import { useCallback, useEffect, useLayoutEffect, useState } from "react"
import {css} from "lib/customClassName";
import {InputText} from "view/components/InputText";
import {Button} from "view/components/button";
import {useThunks} from "lib/reduxHook";
import {authThunk} from "store/auth/thunk/authThunk";
import loadGif from "view/assets/images/Load.gif";

export const RemindPasswordPage = () => {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const token = searchParams.get('code')
    const {verifyRemindPasswordLink, resetPassword} = useThunks(authThunk)

    const [resetPasswordData, setResetPasswordData] = useState<{token: string | null, password: string, rePassword: string}>({token: token, password: '', rePassword: ''})
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<string>('')
    const [verifyLinkError, setVerifyLinkError] = useState<string>('')
    const [successMessage, setSuccessMessage] = useState<string>('')

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    },[])

    const errorVerifyCallback = useCallback((str)=>{
        setVerifyLinkError(str)
    },[])

    const errorResetCallback = useCallback((str)=>{
        setError(str)
    },[])

    const successCallback = useCallback(()=>{
        // navigate('/')
        // setSuccessMessage('Пароль успешно изменен')
    },[])

    const verifyRemindPasswordLinkHandler = async () => {
        setLoading(true)
        await verifyRemindPasswordLink({
            data: {
                token: token ? token : '',
            },
            errorCallback: errorVerifyCallback,
        })
        setLoading(false)
    }

    /* Проверяем токен при переходе по ссылке */
    useEffect(() => {
        if (!token) navigate('/')

        if (token) {
            verifyRemindPasswordLinkHandler()
        }
    }, [])

    /* Запрос на смену пароля */
    const resetPasswordHandler = async (e) => {
        e.preventDefault()
        if (!checkRepeatPassword()) return
        if (validatePasswordFields() === false) return

        setLoading(true)
        await resetPassword({
            data: {
                token: resetPasswordData.token ? resetPasswordData.token : '',
                password: resetPasswordData.password,
            },
            errorCallback: errorResetCallback,
            successCallback,
        })
        setLoading(false)
    }

    /* Проверяем поля при запросе на смену проля */
    const validatePasswordFields = () => {
        for (const field in resetPasswordData) {
            switch (field) {
                case 'password':
                    if (resetPasswordData.password.length < 6) {
                        setError('Минимальная длинна пароля - 6 символов')
                        return false
                    }
                    break
                case 'rePassword':
                    if (resetPasswordData.rePassword.length < 6) {
                        setError('Минимальная длинна пароля - 6 символов')
                        return false
                    }
                    break
            }
        }
    }

    /* Проверяем пароли на идентичность */
    const checkRepeatPassword = () => {
        console.log(resetPasswordData.password)
        console.log(resetPasswordData.rePassword)
        if (resetPasswordData.password !== resetPasswordData.rePassword) {
            setError('Пароли не совпадают')
            return false
        }
        return true
    }

    const changePasswordHandler = (str) => {
        setResetPasswordData(prev => ({...prev, password: str}))
        setError('')
    }

    const changeRePasswordHandler = (str) => {
        setResetPasswordData(prev => ({...prev, rePassword: str}))
        setError('')
    }

    return (
        <div className={css(s.RemindPasswordPage)}>
            <div className={css(s.container)}>
                { loading ?
                    <div className={css(s.loading)}>
                        <img src={loadGif} alt="load"/>
                        <span>Загрузка...</span>
                    </div>
                    :
                    <>
                        { !verifyLinkError ?
                            (!successMessage ?
                                <>
                                    <div className={css(s.title)}>Восстановление пароля</div>
                                    {error && <div><div className={css(s.errorMessage)}>{error}</div></div>}
                                    <div className={css(s.main)}>
                                        <form className={css(s.form)} action="">
                                            <InputText
                                                value={resetPasswordData?.password ?? ''}
                                                change={str => changePasswordHandler(str)}
                                                placeholder={'Пароль'}
                                                title={'Введите новый пароль'}
                                                modes={[`maxWidth`]}
                                                type={'password'}/>
                                            <InputText
                                                value={resetPasswordData?.rePassword ?? ''}
                                                change={str => changeRePasswordHandler(str)}
                                                placeholder={'Повторите пароль'}
                                                title={'Повторите пароль'}
                                                modes={[`maxWidth`]}
                                                type={'password'}/>

                                            <div className={css(s.buttonBlock)}>
                                                <Button text={'Восстановить пароль'} modes={[`red`, `noRadius`, `maxWidth`]} isLoading={loading} disable={!!error} click={resetPasswordHandler}/>
                                            </div>
                                        </form>
                                    </div>
                                </>
                                :
                                <div className={css(s.title)}>{successMessage}</div>
                            )
                            :
                            <div className={css(s.title)}>{verifyLinkError}</div>
                        }
                    </>
                }
            </div>

        </div>
    )
}
