import s from './style.module.scss'
import { css } from "lib/customClassName";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import loadGif from "view/assets/images/Load.gif";
import { InputText } from "view/components/InputText";
import { Button } from "view/components/button";
import { RadioButton } from "view/components/RadioButton";
import { TournamentType } from "types/TournamentType";
import { useSelector } from "react-redux";
import { getEditTournamentSelector } from "store/tournament/selector/getEditTournament";
import { useActions, useThunks } from "lib/reduxHook";
import { TournamentActions } from "store/tournament/reducer/tournamentReducer";
import { tournamentThunk } from "store/tournament/thunk/tournamentThunk";
import Empty_img from "view/assets/images/empty_img.jpg";
import { getNewLogoTournamentSelector } from "store/tournament/selector/getNewLogoTournament";
import { serverHttp } from "config/api/api";
import { useClickOutside } from "lib/useClickOutside";
import { PartnerType } from "types/PartnerType";
import { authFetch } from "lib/authFetch";
import { authThunk } from "store/auth/thunk/authThunk";
import { Editor } from '@tinymce/tinymce-react';
import { tinyMCE } from "config/TinyMCE";

export const EditTournament = () => {
    const { id } = useParams()

    const navigate = useNavigate()

    const editTournament = useSelector(getEditTournamentSelector)

    const newLogoTournament = useSelector(getNewLogoTournamentSelector)

    const [ isNew, setIsNew ] = useState<boolean | null>(null)

    const [ isLoading, setIsLoading ] = useState<boolean>(false)

    const [ initialEditing, setInitialEditing ] = useState<boolean>(false)

    const [ error, setError ] = useState<string>('')

    const { setEditTournamentAction, setNewLogoAction } = useActions(TournamentActions)

    const { getAdminTournamentItem, createTournamentItem, updateTournamentItem } = useThunks(tournamentThunk)

    useEffect(() => {
        if (!id) {
            setEditTournamentAction({
                img: '',
                keywords: '',
                name: '',
                descriptionSmall: '',
                startDate: '',
                isOnline: false,
                isHidden: true,
                isShowTable: false,
                status: 'Скоро старт',
                description: '',
                prize: '',
                rules: '',
                paidMe: [],
                partnerList: [],
                permissionMode: "member",
            })
            setIsNew(true)
        } else {
            getAdminTournamentItem(Number(id))
            setIsNew(false)
        }
        return () => {
            setEditTournamentAction(null)
        }
    }, [])

    useEffect(() => {
        if (isNew === false && !id) {
            navigate(`/admin/tournament`)
        }
    }, [ isNew, id ])

    const changeFieldHandler = <T extends keyof TournamentType>(field: T, value: T | string) => {
        if (!editTournament) return;
        setInitialEditing(true)
        setError('')
        switch (field) {
            case 'keywords':
                setEditTournamentAction({ ...editTournament, keywords: value })
                break
            case 'name':
                setEditTournamentAction({ ...editTournament, name: value })
                break
            case 'descriptionSmall':
                setEditTournamentAction({ ...editTournament, descriptionSmall: value })
                break
            case 'startDate':
                setEditTournamentAction({ ...editTournament, startDate: value })
                break
            case 'isOnline':
                setEditTournamentAction({ ...editTournament, isOnline: value === `Online` })
                break
            case 'isHidden':
                setEditTournamentAction({ ...editTournament, isHidden: value === `Не виден в каталоге турниров` })
                break
            case 'isShowTable':
                setEditTournamentAction({ ...editTournament, isShowTable: value === `Доступна` })
                break
            case 'status':
                setEditTournamentAction({ ...editTournament, status: value })
                break
            case 'description':
                setEditTournamentAction({ ...editTournament, description: value })
                break
            case 'prize':
                setEditTournamentAction({ ...editTournament, prize: value })
                break
            case 'rules':
                setEditTournamentAction({ ...editTournament, rules: value })
                break
            case 'permissionMode':
                const modeList = {
                    "Только 1 участник": 'member',
                    "Только команды": 'command',
                    "Команда или 1 участник": 'all',
                }[`${value}`] as ('member' | 'command' | 'all') ?? 'member' as ('member' | 'command' | 'all')
                setEditTournamentAction({ ...editTournament, permissionMode: modeList })
                break
            default:
                return
        }
    }
    const changePartnerListHandler = (type: `add` | `remove`, partner: PartnerType) => {
        if (!editTournament) return;
        setInitialEditing(true)
        setError('')
        switch (type) {
            case 'add':
                if (editTournament.partnerList.find(el => el.id === partner.id)) return;
                setEditTournamentAction({
                    ...editTournament,
                    partnerList: [ ...editTournament.partnerList, partner ] as any
                })
                break
            case 'remove':
                setEditTournamentAction({
                    ...editTournament,
                    partnerList: editTournament.partnerList.filter(el => el.id !== partner.id)
                })
                break
        }
    }

    const createTournamentHandler = async () => {
        if (!checkEmptyFields()) return
        setIsLoading(true)
        await createTournamentItem((id: number) => {
            navigate(`/admin/tournament/edit/${ id }`)
            setIsNew(false)
        })
        setIsLoading(false)
    }
    const updateTournamentHandler = async () => {
        if (!checkEmptyFields()) return
        setIsLoading(true)
        await updateTournamentItem((id: number) => {

        })
        setIsLoading(false)
    }
    const changeLogoFilesHandler = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            setNewLogoAction(e.target.files[0])
            setInitialEditing(true)
        }
    }

    const checkEmptyFields = () => {
        for (const field in editTournament) {
            if (field != 'img' && field != 'isOnline' && field != 'isShowTable' && field != 'isHidden' && field != 'status' && !editTournament[field]) {
                setError('Заполните пустые поля')
                window.scrollTo(0, 0)
                return false
            }
        }
        return true
    }

    // <pre dangerouslySetInnerHTML={{ __html: editTournament.description }} ></pre>

    if (isNew === null || !editTournament) {
        return null
        // return <div className={css(s.loadingList)}>
        //     <img src={loadGif} alt="load"/>
        //     <span>Загрузка...</span>
        // </div>
    }

    // blobInfo: BlobInfo, progress: ProgressFn
    const uploadHandler = async (blobInfo: any, progress: any): Promise<string> => {
        console.log(blobInfo)
        return Promise.resolve('/hello')
    }

    return (
        <div className={ css(s.EditTournament) }>
            { !isNew && <div className={ css(s.editCategoryBtn) }>
                <Button text={ `Категории    →` } modes={ [ `maxWidth`, `red`, `mobilSmall` ] }
                        click={ () => navigate(`/admin/tournament/category-list/${ id }`) }/>
            </div> }
            <div className={ css(s.head) }>
                { isNew ? 'Создание турнира' : 'Редактирование турнира' }
                <div className={ css(s.error) }>{ error }</div>
            </div>
            <div className={ css(s.main) }>
                <div className={ css(s.form, isLoading && s.disable) }>
                    { isNew && <RadioButton title={ `Тип участников` } value={ {
                        'member': "Только 1 участник",
                        'command': "Только команды",
                        'all': "Команда или 1 участник",
                    }[`${editTournament.permissionMode}`] }
                                 change={ str => changeFieldHandler('permissionMode', str) }
                                 list={ [ `Только 1 участник`, `Только команды`, `Команда или 1 участник` ] }/>}
                    <InputText title={ `Ключевые слова (через запятую)` }
                               disabled={ isLoading }
                               value={ editTournament.keywords }
                               maxLength={ 1024 }
                               change={ str => changeFieldHandler('keywords', str) } placeholder={ `Введите ключевые слова` }
                               modes={ [ `maxWidth` ] }/>
                    <InputText title={ `Название турнира` }
                               disabled={ isLoading }
                               value={ editTournament.name }
                               maxLength={ 255 }
                               change={ str => changeFieldHandler('name', str) } placeholder={ `Введите текст` }
                               modes={ [ `maxWidth` ] }/>
                    <InputText title={ `Краткое описание` }
                               disabled={ isLoading }
                               value={ editTournament.descriptionSmall }
                               maxLength={ 255 }
                               change={ str => changeFieldHandler('descriptionSmall', str) }
                               placeholder={ `Введите текст` }
                               modes={ [ `maxWidth` ] }/>
                    <div className={ css(s.bigDescription, isLoading && s.disable) }>
                        <div className={ css(s.title) }>
                            О турнире
                        </div>
                        <div className={ css(s.editor) }>
                            <Editor
                                value={ editTournament?.description ?? '' }
                                onEditorChange={ (newValue, editor) => {
                                    changeFieldHandler('description', newValue)
                                } }
                                init={ {
                                    height: 400,
                                    branding: false,
                                    statusbar: true,
                                    plugins: tinyMCE.plugins,
                                    toolbar: tinyMCE.toolbar,
                                    menubar: true,
                                    resize: true,
                                    directionality: 'ltr',
                                    language: 'ru',
                                    image_title: true,
                                    automatic_uploads: true,
                                    file_picker_types: 'image',
                                    file_picker_callback: tinyMCE.file_picker_callback,
                                } }
                            />
                        </div>
                    </div>

                    <InputText title={ `Дата проведения` }
                               disabled={ isLoading }
                               value={ editTournament.startDate }
                               maxLength={ 255 }
                               change={ str => changeFieldHandler('startDate', str) } placeholder={ `Введите текст` }
                               modes={ [ `maxWidth` ] }/>
                    <RadioButton title={ `Тип турнира` } value={ editTournament.isOnline ? `Online` : `Offline` }
                                 change={ str => changeFieldHandler('isOnline', str) }
                                 list={ [ `Offline`, `Online` ] }/>
                    <RadioButton title={ `Видимость` }
                                 value={ editTournament.isHidden ? `Не виден в каталоге турниров` : `Виден в каталоге турниров` }
                                 change={ str => changeFieldHandler('isHidden', str) }
                                 list={ [ `Не виден в каталоге турниров`, `Виден в каталоге турниров` ] }/>
                    <RadioButton title={ `Турнирная таблица` }
                                 value={ editTournament.isShowTable ? `Доступна` : `Не доступна` }
                                 change={ str => changeFieldHandler('isShowTable', str) }
                                 list={ [ `Не доступна`, `Доступна` ] }/>
                    <RadioButton title={ `Выборка` } value={ editTournament.status }
                                 change={ str => changeFieldHandler('status', str) }
                                 list={ [ `Скоро старт`, `Сейчас идет`, `Завершен, ждет проверки`, `Завершен` ] }/>
                    <div className={ css(s.prize, isLoading && s.disable) }>
                        <div className={ css(s.title) }>
                            Призы
                        </div>
                        <div className={ css(s.editor) }>
                            <Editor
                                value={ editTournament?.prize ?? '' }
                                onEditorChange={ (newValue, editor) => {
                                    changeFieldHandler('prize', newValue)
                                } }
                                init={ {
                                    height: 400,
                                    branding: false,
                                    statusbar: true,
                                    plugins: tinyMCE.plugins,
                                    toolbar: tinyMCE.toolbar,
                                    menubar: true,
                                    resize: true,
                                    directionality: 'ltr',
                                    language: 'ru',
                                    image_title: true,
                                    automatic_uploads: true,
                                    file_picker_types: 'image',
                                    file_picker_callback: tinyMCE.file_picker_callback,
                                } }
                            />
                        </div>
                    </div>
                    <div className={ css(s.rules, isLoading && s.disable) }>
                        <div className={ css(s.title) }>
                            Правила
                        </div>
                        <div className={ css(s.editor) }>
                            <Editor
                                value={ editTournament?.rules ?? '' }
                                onEditorChange={ (newValue, editor) => {
                                    changeFieldHandler('rules', newValue)
                                } }
                                init={ {
                                    height: 400,
                                    branding: false,
                                    statusbar: true,
                                    plugins: tinyMCE.plugins,
                                    toolbar: tinyMCE.toolbar,
                                    menubar: true,
                                    resize: true,
                                    directionality: 'ltr',
                                    language: 'ru',
                                    image_title: true,
                                    automatic_uploads: true,
                                    file_picker_types: 'image',
                                    file_picker_callback: tinyMCE.file_picker_callback,
                                } }
                            />
                        </div>
                    </div>
                    <AddPartner changePartnerListHandler={ changePartnerListHandler }/>
                    <div className={ css(s.partnerList) }>
                        { editTournament?.partnerList?.map(el =>
                            <div key={ el.id }
                                 className={ css(s.item) }>
                                <div className={ css(s.img) }>
                                    { el.img === '' && <img src={ Empty_img } alt="tournament_item"/> }
                                    { el?.img !== '' &&
                                        <img src={ `${ serverHttp }/upload/` + el.img } alt="tournament_item"/> }
                                </div>
                                <div className={ css(s.itemMain) }>
                                    <div>{ el.name }</div>
                                </div>
                                <Button text={ `Удалить` } modes={ [ `maxWidth`, `red` ] }
                                        click={ () => changePartnerListHandler(`remove`, el) }/>
                            </div>) }
                    </div>
                    <div className={ css(s.btnBox) }>
                        { !isNew &&
                            <Button text={ `Сохранить    →` } disable={ !initialEditing } isLoading={ isLoading }
                                    modes={ [ `red`, `maxWidth`, `noRadius` ] }
                                    click={ updateTournamentHandler }/> }
                        { isNew &&
                            <Button text={ `Создать    →` } isLoading={ isLoading }
                                    modes={ [ `red`, `maxWidth`, `noRadius` ] }
                                    click={ createTournamentHandler }/> }
                    </div>
                </div>
                <div className={ css(s.avatar) }>
                    <div className={ css(s.imgBox) }>
                        { newLogoTournament === null && editTournament.img === '' &&
                            <img src={ Empty_img } alt="tournament_item"/> }
                        { newLogoTournament === null && editTournament.img !== '' &&
                            <img src={ `${ serverHttp }/upload/` + editTournament.img } alt="tournament_item"/> }
                        { newLogoTournament !== null && <img src={ `${ newLogoTournament }` } alt="tournament_item"/> }
                    </div>
                    <label className={ css(s.changeFile, isLoading && s.disable) }>
                        <div className={ css(s.fileName) }> редактировать</div>
                        <input className={ css(s.inputLogo) } type="file" accept={ '.png, .jpg, .jpeg, .gif' }
                               multiple={ false }
                               onChange={ changeLogoFilesHandler }/>
                    </label>
                    {/*<Button text={`редактировать`}/>*/ }
                </div>
            </div>
        </div>
    );
};

export const AddPartner = ({ changePartnerListHandler }: any) => {

    const [ showSelectBox, setShowSelectBox ] = useState<boolean>(false)

    const [ partnerList, setPartnerList ] = useState<null | Array<PartnerType>>(null)

    useEffect(() => {
        if (showSelectBox)
            authFetch(() => authThunk.checkAuth())
            (`${ serverHttp }/api/partner/get-list`, {
                method: `GET`,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(data => data.json())
                .then(data => {
                    return new Promise(res => setTimeout(() => {
                        setPartnerList(data)
                    }, 1000))

                })
                .catch(err => console.log(err))
    }, [ showSelectBox ])

    useClickOutside(() => setShowSelectBox(false), [ `partnerBox` ])

    const handleAddPartner = (e: any, partner: any) => {
        e.stopPropagation()
        changePartnerListHandler('add', partner)
        console.log(`handleAddPartner`)
        // setCurrentTournament(tournament)
        // setShowSelectBox(false)
    }

    return (
        <div className={ css(s.AddPartner) }>
            <div className={ css(s.title) }>Добавить партнёров</div>
            <div id={ `partnerBox` } className={ css(s.main, showSelectBox && s.active) }
                 onClick={ () => setShowSelectBox(prev => !prev) }>
                <div className={ css(s.name) }>Выбрать партнёров из списка</div>
                <div className={ css(s.showSelectBtn) }>
                    <svg width="30" height="30" viewBox="0 0 12 8" xmlns="https://www.w3.org/2000/svg">
                        <path
                            d="M5.59021 7.15047L0.452036 2.0122C0.333101 1.89336 0.267578 1.73471 0.267578 1.56556C0.267578 1.3964 0.333101 1.23776 0.452036 1.11891L0.83034 0.740515C1.07685 0.494289 1.47749 0.494289 1.72363 0.740515L6.03836 5.05525L10.3579 0.735728C10.4768 0.616886 10.6354 0.55127 10.8044 0.55127C10.9737 0.55127 11.1322 0.616886 11.2513 0.735728L11.6295 1.11413C11.7484 1.23306 11.8139 1.39161 11.8139 1.56077C11.8139 1.72993 11.7484 1.88857 11.6295 2.00741L6.48659 7.15047C6.36728 7.26959 6.20798 7.33502 6.03864 7.33464C5.86864 7.33502 5.70943 7.26959 5.59021 7.15047Z"/>
                    </svg>
                </div>
                { showSelectBox && <div className={ css(s.list) } onClick={ e => e.stopPropagation() }>
                    { partnerList?.map(el =>
                        <div key={ el.id }
                             className={ css(s.item) }>
                            <div className={ css(s.img) }>
                                { el.img === '' && <img src={ Empty_img } alt="tournament_item"/> }
                                { el?.img !== '' &&
                                    <img src={ `${ serverHttp }/upload/` + el.img } alt="tournament_item"/> }
                            </div>
                            <div className={ css(s.itemMain) }>
                                <div>{ el.name }</div>
                            </div>
                            <Button text={ `Добавить` } modes={ [ `maxWidth`, `red` ] }
                                    click={ (e) => handleAddPartner(e, el) }/>
                        </div>) }
                    { partnerList === null && <div className={ css(s.loadingList) }>
                        <img src={ loadGif } alt="load"/>
                        Загрузка...
                    </div> }
                    { partnerList !== null && !partnerList.length && <div className={ css(s.emptyList) }>
                        Список партнёров пуст
                    </div> }
                </div> }
            </div>
        </div>
    );
};
