import s from './style.module.scss'
import {css} from "lib/customClassName"
import {InputText} from "../../components/InputText";
import InputNumber from "../../components/InputNumber";
import {RadioButton} from "../../components/RadioButton";
import {Button} from "../../components/button";
import {serverHttp} from "../../../config/api/api";
import Empty_img from "../../assets/images/empty_img.jpg";
import { useCallback, useLayoutEffect, useState } from "react";
import {ProfileType} from "../../../types/ProfileType";
import {useThunks} from "lib/reduxHook";
import {authThunk} from "store/auth/thunk/authThunk";
import {useNavigate} from "react-router-dom";

export const ChangePasswordPage = () => {
    const {changePassword} = useThunks(authThunk)
    const navigate = useNavigate()

    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const [changeSuccess, setChangeSuccess] = useState<boolean>(false)

    const [data, setData] = useState({
        oldPassword: {
            value: '',
            error: ''
        },
        newPassword: {
            value: '',
            error: ''
        },
        repeatNewPassword: {
            value: '',
            error: ''
        },
    })

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    },[])

    const errorCallback = useCallback((message: string)=>{
        setError(message)
    },[])

    const successCallback = useCallback(() => {
        setChangeSuccess(true)
    }, [])

    const ChangePasswordHandler = async () => {
        if (!validationFields()) return
        if (!checkRepeatPassword()) return
        setLoading(true)
        await changePassword({
            data: {
                oldPassword: data.oldPassword.value,
                newPassword: data.newPassword.value,
            },
            errorCallback: errorCallback,
            successCallback: successCallback,
        })
        setLoading(false)
    }

    const changePasswordHandler = (field, value) => {
        setError('')
        switch (field) {
            case 'oldPassword':
                setData({...data, oldPassword: {value: value, error: ''}})
                break
            case 'newPassword':
                setData({...data, newPassword: {value: value, error: ''}})
                break
            case 'repeatNewPassword':
                setData({...data, repeatNewPassword: {value: value, error: ''}})
                break
        }
    }

    const checkRepeatPassword = () => {
        if (data.newPassword.value !== data.repeatNewPassword.value) {
            setError('Пароли не совпадают')
            return false
        }
        return true
    }

    const validationFields = () => {
        for (const field in data) {
            switch (field) {
                case 'oldPassword':
                    if (data[field].value.length < 6) {
                        setData({...data, oldPassword: {...data.oldPassword, error: 'Неверная длинна пароля'} })
                        return false
                    }
                    break
                case 'newPassword':
                    if (data[field].value.length < 6) {
                        setData({...data, newPassword: {...data.newPassword, error: 'Неверная длинна пароля'} })
                        return false
                    }
                    break
                case 'repeatNewPassword':
                    if (data[field].value.length < 6) {
                        setData({...data, repeatNewPassword: {...data.repeatNewPassword, error: 'Неверная длинна пароля'} })
                        return false
                    }
                    break
                default:
                    return true
            }
        }
        return true
    }

    return (
        <div className={css(s.ChangePasswordPage)}>
            <div className={css(s.container)}>
                {!changeSuccess ?
                    <>
                        <div className={css(s.titlePage)}>Сменя пароля</div>
                        <div className={css(s.error)}>{error}</div>
                        <div className={css(s.main)}>
                            <div className={css(s.form)}>
                                <InputText
                                    value={data.oldPassword.value}
                                    title={`Введите старый пароль`}
                                    error={data.oldPassword.error}
                                    type={'password'}
                                    change={str => changePasswordHandler('oldPassword', str)}
                                    modes={[`maxWidth`]}
                                    placeholder={'Старый пароль'}
                                    readOnly={isLoading}/>
                                <InputText
                                    value={data.newPassword.value}
                                    title={`Введите новый пароль`}
                                    error={data.newPassword.error}
                                    type={'password'}
                                    change={str => changePasswordHandler('newPassword', str)}
                                    modes={[`maxWidth`]}
                                    placeholder={'Новый пароль'}
                                    readOnly={isLoading}/>
                                <InputText
                                    value={data.repeatNewPassword.value}
                                    title={`Повторите новый пароль`}
                                    error={data.repeatNewPassword.error}
                                    type={'password'}
                                    change={str => changePasswordHandler('repeatNewPassword', str)}
                                    modes={[`maxWidth`]}
                                    placeholder={'Повторите новый пароль'}
                                    readOnly={isLoading}/>
                                <div className={css(s.sendBtnBox)}>
                                    <Button text={isLoading ? "Сохранение..." : `Изменить пароль    →`} isLoading={isLoading} modes={[`red`, `noRadius`, `maxWidth`]} click={ChangePasswordHandler}/>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <div className={css(s.titlePage)}>Пароль успешно изменен</div>
                }
            </div>
        </div>
    );
};
