import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProfileType } from 'types/ProfileType';
import {PaginationType} from "types/Pagination";

interface stateType {
    statisticList: null | Array<any>
    statisticListPagination: PaginationType,
    allPrice: number | null,
}
const initialState: stateType = {
    statisticList: null,
    statisticListPagination: {limit: 12, total: null},
    allPrice: null,
}

export const slice = createSlice({
    name: 'StatisticReducer',
    initialState,
    reducers: {
        setStatisticListAction: (state, action: PayloadAction<Array<any> | null>) : void => {
            if (action.payload === null) {
                state.statisticList = null
                state.statisticListPagination = {...state.statisticListPagination, total: null}
                return
            }
            state.statisticList = [...state.statisticList ?? [], ...action.payload]
        },
        setStatisticListPaginationAction: (state, action: PayloadAction<PaginationType>) : void => {
            state.statisticListPagination = action.payload
        },
        setAllPriceAction: (state, action: PayloadAction<number | null>) : void => {
            state.allPrice = action.payload
        },
    }
})

export const StatisticReducer = slice.reducer
export const StatisticActions = slice.actions



