import s from './style.module.scss'
import {css} from "lib/customClassName";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {useActions, useThunks} from "lib/reduxHook";
import {TournamentActions} from "store/tournament/reducer/tournamentReducer";
import {CategoryActions} from "store/category/reducer/CategoryReducer";
import {categoryThunk} from "store/category/thunk/categoryThunk";

export const CategoryListLayout = () => {

    const {id} = useParams()

    const navigate = useNavigate()

    const {setCategoryListAction} = useActions(CategoryActions)

    const {getAdminList} = useThunks(categoryThunk)

    useEffect(() => {
        if (!id) return
        getAdminList(Number(id))
        return () => {
            setCategoryListAction(null)
        }
    }, [])

    if (!id) {
        navigate(`/`)
    }
    return (
        <div className={css(s.TournamentLayout)}>
            <Outlet/>
        </div>
    );
};
