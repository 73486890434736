import s from './style.module.scss'
import {useParams} from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import {serverHttp} from "config/api/api";
import {css} from "lib/customClassName";

export const ConfirmEmailPage = () => {
    const {token, email} = useParams()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    },[])

    useEffect(() => {
        if (token) {
            const verifyEmailFetch = async () => {
                setLoading(true)
                const response = await fetch(`${serverHttp}/api/confirmation/email`, {
                    method: `POST`,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: token,
                    })
                })

                const data = await response.json()
                if (data?.error) {
                    setError(`${data?.message}`)
                    setLoading(false)
                    return
                }

                if (response.status === 200) {
                    setLoading(false)
                    setError('')
                    return
                }
            }

            verifyEmailFetch()
        }
    }, [])

    return (
        <div className={css(s.ConfirmEmailPage)}>
            <div className={css(s.message)}>
                {
                    loading ? <div>Loading...</div> : !!error.length ? <div>{error}</div> : <div>Почта подтверждена</div>
                }
            </div>

            <a href={'/'} className={css(s.homeButton)}>на главную</a>
        </div>
    );
};
