import s from './style.module.scss'
import {css} from "lib/customClassName";
import {Button} from "view/components/button";
import {useNavigate, useParams} from "react-router-dom";
import {getTournamentRulesSelector} from "store/tournament/selector/getTournamentRules";
import {useSelector} from "react-redux";
import React, { useEffect, useLayoutEffect } from "react";
import {Helmet} from "react-helmet";
import { getFullTournamentSelector } from "store/tournament/selector/getFullTournament";
import { DESCRIPTION, KEYWORDS } from "config/constants";

export const RulesPage = () => {
    const {id} = useParams()

    const navigate = useNavigate()

    const tournamentRules = useSelector(getTournamentRulesSelector)

    const tournament = useSelector(getFullTournamentSelector)

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    },[])

    useEffect(()=>{
        if (tournamentRules === undefined) {
            navigate(`/tournament/${id}`)
        }
    },[])

    if (!id || tournamentRules === undefined) return null
    return (
        <div className={css(s.RulesPage)}>
            <Helmet>
                <title> {tournament?.name} | Правила</title>
                <meta name="description" content={!tournamentRules.length ? DESCRIPTION : tournamentRules.replace(/(<([^>]+)>)/gi, '')} />
                <meta name="keywords" content={KEYWORDS} />
            </Helmet>
            <div className={css(s.container)}>
                <div className={css(s.main)} >
                    <Button text={`←    Назад`} modes={[`mobilSmall`]} click={()=>navigate(`/tournament/${id}`)}/>
                    <div className={css(s.titlePage)}>ПРАВИЛА УЧАСТИЯ В ТУРНИРЕ</div>
                    {tournamentRules.length > 0 && <div className={css(s.text)}><pre dangerouslySetInnerHTML={{ __html: tournamentRules }} /></div>}

                    {tournamentRules.length === 0 &&
                        <div className={css(s.emptyList)}>Правила не описаны</div>}
                </div>
            </div>
        </div>
    );
};
