import s from './style.module.scss'
import { css } from "lib/customClassName";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getEditCategorySelector } from "store/category/selector/getEditCategory";
import { useActions, useThunks } from "lib/reduxHook";
import { CategoryActions } from "store/category/reducer/CategoryReducer";
import { CategoryType } from "types/CategoryType";
import loadGif from "view/assets/images/Load.gif";
import { InputText } from "view/components/InputText";
import { RadioButton } from "view/components/RadioButton";
import { Button } from "view/components/button";
import { categoryThunk } from "store/category/thunk/categoryThunk";
import { Skeleton } from "view/components/skeleton";
import { InputTextArea } from "view/components/InputTextArea";
import { Popup } from "view/components/popup";
import { Editor } from "@tinymce/tinymce-react";

type PropsType = {
    // categoryItem?: CategoryType
}
export const EditCategoryForm = (props: PropsType) => {
    // const {categoryItem} = props

    const location = useLocation()

    const navigate = useNavigate()

    const categoryItem = location.state ?? undefined

    const { id } = useParams()

    const [ isNew, setIsNew ] = useState<boolean | null>(null)

    const [isLoad, setIsLoad] = useState(false)

    const [isShowBigDescription, setIsShowBigDescription] = useState(false)

    const [ error, setError ] = useState<string>('')

    const editCategory = useSelector(getEditCategorySelector)

    const { setEditCategoryAction } = useActions(CategoryActions)

    const { create, update } = useThunks(categoryThunk)

    useEffect(() => {

        if (!categoryItem) {
            setEditCategoryAction({
                tournamentId: Number(id),
                name: '',
                description: '',
                bigDescription: '',
                isSubmissionResult: false,
                isAccessBuy: false,
                isHidden: true,
                price: 0,
                taskList: []
            })
            setIsNew(true)
            // setTimeout(() => setIsNew(true), 1000)

        } else {
            setEditCategoryAction(categoryItem)
            setIsNew(false)
            // setTimeout(() => setIsNew(false), 1000)
        }

        return () => {
            setEditCategoryAction(null)
        }
    }, [])

    const changeFieldHandler = <T extends keyof CategoryType>(field: T, value: T | string) => {
        if (!editCategory) return;
        setError('')
        switch (field) {
            case 'price':
                const price = !!value.length ? parseInt(value) : 0
                if (price > 1_000_000) break
                if (price < 0) break
                setEditCategoryAction({ ...editCategory, price: price })
                break
            case 'name':
                setEditCategoryAction({ ...editCategory, name: value })
                break
            case 'description':
                setEditCategoryAction({ ...editCategory, description: value })
                break
            case 'bigDescription':
                setEditCategoryAction({...editCategory, bigDescription: value})
                break
            case 'isAccessBuy':
                setEditCategoryAction({ ...editCategory, isAccessBuy: value === `Доступен для покупки` })
                break
            case 'isHidden':
                setEditCategoryAction({ ...editCategory, isHidden: value === `Не виден в категориях` })
                break
            case 'isSubmissionResult':
                setEditCategoryAction({ ...editCategory, isSubmissionResult: value === `Открыта` })
                break
        }
    }
    const createCategoryHandler = () => {
        if (!checkEmptyFields()) return
        setIsNew(null)
        setIsLoad(true)
        create(() => {
            navigate(`/admin/tournament/category-list/${ id }`)
            // setIsNew(false)
            // closePopup()
        })
    }
    const updateCategoryHandler = () => {
        if (!categoryItem) return
        setIsNew(null)
        setIsLoad(true)
        update((id: number) => {
            navigate(`/admin/tournament/category-list/${ categoryItem.tournamentId }`)
            // setIsNew(false)
            // closePopup()
        })
    }

    const checkEmptyFields = () => {
        if (!editCategory) return
        if (!editCategory.name.length) return setError('Заполните обязательные поля')
        if (!editCategory.description.length) return setError('Заполните обязательные поля')
        // Это так не работает!
        // for (const field in editCategory) {
        //     if (field != 'isAccessBuy' && field != 'isHidden' && field != 'isSubmissionResult' && field != 'price' && !editCategory[field]) {
        //         setError('Заполните пустые поля')
        //         return false
        //     }
        // }
        return true
    }

    // if (isNew === null || !editCategory) {
    //     return <div className={css(s.loadingList)}>
    //         <img src={loadGif} alt="load"/>
    //         <span>Загрузка...</span>
    //     </div>
    // }
    return (
        <div className={ css(s.EditCategoryForm) }>
            <div className={ css(s.backBtnBox) }>
                <Button text={ `←    Назад` } click={ () => navigate(`/admin/tournament/category-list/${ id }`) }/>
            </div>
            <div className={ css(s.container) }>
                <div className={ css(s.head) }>
                    { isNew === null ? `...` : isNew ? `Создание категории` : `Редактирование` }
                </div>
                <div className={ css(s.main) }>
                    <div className={ css(s.form) }>
                        <InputText title={ `Название категории` } value={ editCategory?.name ?? '' }
                                   change={ str => changeFieldHandler('name', str) } placeholder={ `Введите текст` }
                                   modes={ [ `maxWidth` ] }/>
                        <InputTextArea title={ `Описание` } value={ editCategory?.description ?? '' }
                                       change={ str => changeFieldHandler('description', str) }
                                       placeholder={ `Введите текст` }
                                       modes={ [ `maxWidth` ] }/>
                        {/*<div className={css(s.bigDescription)}>*/}
                        {/*    <div className={css(s.title)}>*/}
                        {/*        Полное описание*/}
                        {/*    </div>*/}
                        {/*    <Editor*/}
                        {/*        value={ editCategory?.bigDescription ?? '' }*/}
                        {/*        onEditorChange={ (newValue, editor) => {*/}
                        {/*            changeFieldHandler('bigDescription', newValue)*/}
                        {/*        } }*/}
                        {/*        init={ {*/}
                        {/*            height: 400,*/}
                        {/*            // menubar: false,*/}
                        {/*            branding: false,*/}
                        {/*            statusbar: true,*/}
                        {/*            plugins: [*/}
                        {/*                "print", "preview", "paste", " searchreplace", "autolink", "directionality", "visualblocks", "visualchars", "fullscreen", "image", "link", "media", "template", "codesample", "table", "charmap", "hr", "pagebreak", "nonbreaking", "anchor", "toc", "insertdatetime", "advlist", "lists", "wordcount", "imagetools", "textpattern",*/}
                        {/*                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',*/}
                        {/*                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',*/}
                        {/*                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'*/}
                        {/*            ],*/}
                        {/*            toolbar: 'fullscreen | fontsize blocks | '+*/}
                        {/*                'bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter ' +*/}
                        {/*                'alignright alignjustify | bullist numlist outdent indent formatselect | link image media | blockquote | undo redo',*/}
                        {/*            // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',*/}
                        {/*            // image_advtab: true,*/}
                        {/*            menubar: true,*/}
                        {/*            resize: true,*/}
                        {/*        } }*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*<Button text={'Подробное описание'} click={() => setIsShowBigDescription(true)}/>*/}
                        <InputText title={ `Цена (рубли)` } value={ editCategory?.price.toString() ?? '' }
                                   change={ str => changeFieldHandler('price', str) } placeholder={ `.руб` }
                                   modes={ [] }/>
                        <RadioButton title={ `Покупка` }
                                     value={ editCategory?.isAccessBuy ? `Доступен для покупки` : `Не доступен для покупки` }
                                     change={ str => changeFieldHandler('isAccessBuy', str) }
                                     list={ [ `Доступен для покупки`, `Не доступен для покупки` ] }/>
                        <RadioButton title={ `Видимость` }
                                     value={ editCategory?.isHidden ? `Не виден в категориях` : `Виден в категориях` }
                                     change={ str => changeFieldHandler('isHidden', str) }
                                     list={ [ `Не виден в категориях`, `Виден в категориях` ] }/>
                        <RadioButton title={ `Подача результатов` }
                                     value={ editCategory?.isSubmissionResult ? `Открыта` : `Закрыта` }
                                     change={ str => changeFieldHandler('isSubmissionResult', str) }
                                     list={ [ `Открыта`, `Закрыта` ] }/>

                    </div>
                    <div className={ css(s.btnBox) }>
                        { !isNew && <Button text={ `Сохранить    →` }
                                            isLoading={isLoad}
                                            modes={ [ `red`, `maxWidth`, `noRadius` ] }
                                            click={ updateCategoryHandler }/> }
                        { isNew && <Button text={ `Создать    →` }
                                           isLoading={isLoad}
                                           modes={ [ `red`, `maxWidth`, `noRadius` ] }
                                           click={ createCategoryHandler }/> }
                    </div>
                </div>
                {/*{ isNew === null && <Skeleton mods={ [ `grey` ] }/> }*/}
            </div>
            {/*{isShowBigDescription && <Popup clickCloseBtnCallback={() => setIsShowBigDescription(false)}>*/}
            {/*    <div className={css(s.editBigDescription)}>*/}
            {/*        <InputTextArea placeholder={`Введите текст`} modes={['maxWidth']} value={editCategory?.bigDescription ?? ''} change={(str) => changeFieldHandler('bigDescription', str)}/>*/}
            {/*    </div>*/}
            {/*</Popup>}*/}
        </div>
    );
};
