import s from './index.module.scss'
import { css } from "lib/customClassName";
import loadGif from "view/assets/images/Load.gif";
import { useActions, useThunks } from "lib/reduxHook";
import { authThunk } from "store/auth/thunk/authThunk";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserListSelector } from "store/auth/selector/getUserList";
import { Button } from "view/components/button";
import { UserType } from "types/UserType";
import { ProfileType } from "types/ProfileType";
import { AuthActions } from "store/auth/reducer/authReducer";
import { getUserListPaginationSelector } from "store/auth/selector/getUserListPagination";
import { getTournamentListPaginationSelector } from "store/tournament/selector/getTournamentListPagination";
import { authFetch } from "lib/authFetch";
import { serverHttp } from "config/api/api";
import { useClickOutside } from "lib/useClickOutside";

export const TournamentUserPage = () => {

    const userList = useSelector(getUserListSelector)

    const userListPagination = useSelector(getUserListPaginationSelector)

    const [ isLoaderMoreBtn, setIsLoaderMoreBtn ] = useState(false)

    const [ currentTournament, setCurrentTournament ] = useState<any>(null)
    const [ currentCategory, setCurrentCategory ] = useState<any>(null)

    const { setUserListAction } = useActions(AuthActions)

    const { getTournamentUserList } = useThunks(authThunk)

    useEffect(() => {
        if (!!currentTournament) {
            getTournamentUserList(currentTournament?.id, currentCategory?.id)
        }
        return () => {
            setUserListAction(null)
        }
    }, [ currentTournament, currentCategory ])

    const handleMoreUser = async () => {
        if (!!currentTournament) {
            setIsLoaderMoreBtn(true)
            await getTournamentUserList(currentTournament?.id, currentCategory?.id)
            setIsLoaderMoreBtn(false)
        }
    }

    return (
        <div className={ css(s.UserPage) }>
            <div className={ css(s.head) }>
                Участники турнира
            </div>
            <div className={ css(s.selectBox) }>
                <SelectTournament currentTournament={ currentTournament }
                                  setCurrentTournament={ setCurrentTournament }
                                  setCurrentCategory={ setCurrentCategory }/>
                { currentTournament !== null &&
                    <SelectCategory currentTournament={ currentTournament } currentCategory={ currentCategory }
                                    setCurrentCategory={ setCurrentCategory }/> }
            </div>
            <div className={ css(s.list) }>
                { !currentTournament && !userList?.length && <div className={ css(s.emptyList) }>
                    Турнир не выбран
                </div> }
                { userList !== null && !userList.length && <div className={ css(s.emptyList) }>
                    Список пользователей пуст
                </div> }
                { userList?.map(user => <UserItem key={ user.id } user={ user }/>) }
                { userList !== null && userList.length < ( userListPagination.total ? userListPagination.total : 0 ) &&
                    <div className={ css(s.btnBox) }>
                        <Button text={ 'показать больше' } modes={ [ `uppercase`, 'maxWidth', `mobilSmall` ] }
                                isLoading={ isLoaderMoreBtn } click={ handleMoreUser }/>
                    </div> }
            </div>
        </div>
    );
};

export const UserItem = (props: { user: UserType & { profile: ProfileType } & { team?: any } }) => {
    const { user } = props

    const [ isLoad, setIsLoad ] = useState(false)

    const { blocked } = useThunks(authThunk)

    const isProfile = !!Object.keys(user.profile).length

    const blockedHandler = async (e, id) => {
        setIsLoad(true)
        await blocked(Number(id))
        setIsLoad(false)
    }

    return (
        <div className={ css(s.userItem) }>
            { user.team && <div className={css(s.commandName)}>Команда: { user.team.name }</div> }
            { user.team && user.team.members.map(member => (
                <div key={member.id} className={ css(s.main, s.team) }>
                    { !!member.firstName?.length && !!member.lastName?.length && <div
                        className={ css(s.name) }><span
                        className={ css(s.prevValue) }>Имя:</span><span>{ `${ member.firstName } ${ member.lastName }` }</span>
                    </div> }
                    { !isProfile && <div className={ css(s.emptyName) }>Нет данных...</div> }
                    {/*<div className={ css(s.login) }><span*/ }
                    {/*    className={ css(s.prevValue) }>E-mail:</span><span>{ member.login }</span></div>*/ }
                    { isProfile && <div className={ css(s.field) }><span
                        className={ css(s.prevValue) }>Пол:</span><span>{ member.sex }</span></div> }
                    { isProfile && <div className={ css(s.field) }><span
                        className={ css(s.prevValue) }>Дата рождения:</span><span>{ member.birthDate }</span>
                    </div> }
                    { isProfile && <div className={ css(s.field) }><span
                        className={ css(s.prevValue) }>Город:</span><span>{ member.city }</span></div> }
                    { isProfile && <div className={ css(s.field) }><span
                        className={ css(s.prevValue) }>Зал:</span><span>{ member.gym }</span></div> }
                    { isProfile && <div className={ css(s.field) }><span
                        className={ css(s.prevValue) }>Вес:</span><span>{ member.weight }</span></div> }
                    { isProfile && <div className={ css(s.field) }><span
                        className={ css(s.prevValue) }>Рост:</span><span>{ member.height }</span></div> }
                    { isProfile && <div className={ css(s.field) }><span
                        className={ css(s.prevValue) }>Размер одежды:</span><span>{ member.size }</span></div> }
                    { isProfile && <div className={ css(s.field) }><span
                        className={ css(s.prevValue) }>Социальная сеть:</span><span>{ member.socialMedia }</span>
                    </div> }
                </div>
            )) }
            { !user.team && <div className={ css(s.main) }>
                { !!user.profile?.firstName?.length && !!user.profile?.lastName?.length && <div
                    className={ css(s.name) }><span
                    className={ css(s.prevValue) }>Имя:</span><span>{ `${ user.profile.firstName } ${ user.profile.lastName }` }</span>
                </div> }
                { !isProfile && <div className={ css(s.emptyName) }>Нет данных...</div> }
                {/*<div className={ css(s.login) }><span*/}
                {/*    className={ css(s.prevValue) }>E-mail:</span><span>{ user.login }</span></div>*/}
                { isProfile && <div className={ css(s.field) }><span
                    className={ css(s.prevValue) }>Пол:</span><span>{ user.profile.sex }</span></div> }
                { isProfile && <div className={ css(s.field) }><span
                    className={ css(s.prevValue) }>Дата рождения:</span><span>{ user.profile.birthDate }</span></div> }
                { isProfile && <div className={ css(s.field) }><span
                    className={ css(s.prevValue) }>Город:</span><span>{ user.profile.city }</span></div> }
                { isProfile && <div className={ css(s.field) }><span
                    className={ css(s.prevValue) }>Зал:</span><span>{ user.profile.gym }</span></div> }
                { isProfile && <div className={ css(s.field) }><span
                    className={ css(s.prevValue) }>Вес:</span><span>{ user.profile.weight }</span></div> }
                { isProfile && <div className={ css(s.field) }><span
                    className={ css(s.prevValue) }>Рост:</span><span>{ user.profile.height }</span></div> }
                { isProfile && <div className={ css(s.field) }><span
                    className={ css(s.prevValue) }>Размер одежды:</span><span>{ user.profile.size }</span></div> }
                { isProfile && <div className={ css(s.field) }><span
                    className={ css(s.prevValue) }>Социальная сеть:</span><span>{ user.profile.socialMedia }</span>
                </div> }
            </div> }
        </div>
    );
};

export const SelectTournament = ({ currentTournament, setCurrentTournament, setCurrentCategory }: any) => {

    const [ showSelectBox, setShowSelectBox ] = useState<boolean>(false)

    const { limit } = useSelector(getTournamentListPaginationSelector)

    const [ isLoaderMoreBtn, setIsLoaderMoreBtn ] = useState(false)

    const [ tournamentList, setTournamentList ] = useState<any>(null)
    const [ totalTournamentList, setTotalTournamentList ] = useState<any>(null)

    const { checkAuth } = useThunks(authThunk)

    const getList = async () => {
        const countList = tournamentList !== null ? tournamentList?.length : 0

        await new Promise((resolve) => {
            setTimeout(resolve, 300)
        })

        const response = await authFetch(() => checkAuth())
        (`${ serverHttp }/api/tournament/list?limit=${ limit }&offset=${ countList }`, {
            method: `GET`,
            headers: {
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()
        if (data?.error) {
            console.log(data.message)
            return
        }

        const newRow = [ ...tournamentList ?? [], ...data.rows ]

        setTournamentList(newRow)
        setTotalTournamentList(data.count)
    }

    useEffect(() => {
        if (showSelectBox) {
            getList()
        }
        return () => {
            setTournamentList(null)
            setTotalTournamentList(null)
        }
    }, [ showSelectBox ])

    useClickOutside(() => setShowSelectBox(false), [ `currentTournament`, `MoreBtn` ])

    const handleSelectTournament = (e: any, tournament: any) => {
        e.stopPropagation()
        if (tournament === null) {
            setCurrentCategory(null)
        }
        setCurrentTournament(tournament)
        setShowSelectBox(false)
    }

    const handleMoreTournament = async (e) => {
        e.stopPropagation()
        setIsLoaderMoreBtn(true)
        await getList()
        setIsLoaderMoreBtn(false)
    }

    return (
        <div className={ css(s.SelectTournament) }>
            <div className={ css(s.title) }>Выбрать турнир</div>
            <div id={ `currentTournament` } className={ css(s.currentTournament, showSelectBox && s.active) }
                 onClick={ () => setShowSelectBox(prev => !prev) }>
                <div
                    className={ css(s.name) }>{ currentTournament === null ? `Не выбран` : currentTournament.name }</div>
                <div className={ css(s.showSelectBtn) }>
                    <svg width="30" height="30" viewBox="0 0 12 8" xmlns="https://www.w3.org/2000/svg">
                        <path
                            d="M5.59021 7.15047L0.452036 2.0122C0.333101 1.89336 0.267578 1.73471 0.267578 1.56556C0.267578 1.3964 0.333101 1.23776 0.452036 1.11891L0.83034 0.740515C1.07685 0.494289 1.47749 0.494289 1.72363 0.740515L6.03836 5.05525L10.3579 0.735728C10.4768 0.616886 10.6354 0.55127 10.8044 0.55127C10.9737 0.55127 11.1322 0.616886 11.2513 0.735728L11.6295 1.11413C11.7484 1.23306 11.8139 1.39161 11.8139 1.56077C11.8139 1.72993 11.7484 1.88857 11.6295 2.00741L6.48659 7.15047C6.36728 7.26959 6.20798 7.33502 6.03864 7.33464C5.86864 7.33502 5.70943 7.26959 5.59021 7.15047Z"/>
                    </svg>
                </div>
                { showSelectBox && <div className={ css(s.tournamentList) }>
                    <div className={ css(s.item) } onClick={ (e) => handleSelectTournament(e, null) }>Не выбран</div>
                    { tournamentList?.map(el => <div key={ el.id } className={ css(s.item) }
                                                     onClick={ (e) => handleSelectTournament(e, el) }>
                        { el.name }
                    </div>) }
                    { tournamentList === null && <div className={ css(s.loadingList) }>
                        <img src={ loadGif } alt="load"/>
                        Загрузка...
                    </div> }
                    { tournamentList !== null && tournamentList.length < ( totalTournamentList ? totalTournamentList : 0 ) &&
                        <div id={ `MoreBtn` } className={ css(s.btnBox) }>
                            <Button text={ 'показать больше' }
                                    modes={ [ `uppercase`, 'maxWidth', `mobilSmall`, `noRadius`, `grey` ] }
                                    isLoading={ isLoaderMoreBtn } click={ handleMoreTournament }/>
                        </div> }
                </div> }
            </div>
        </div>
    );
};
export const SelectCategory = ({ currentCategory, setCurrentCategory, currentTournament }: any) => {

    // const categoryList = useSelector(getAdminResultCategoryListSelector)
    const [ categoryList, setCategoryList ] = useState<any>(null)

    const [ showSelectBox, setShowSelectBox ] = useState<boolean>(false)

    useClickOutside(() => setShowSelectBox(false), [ `currentCategory` ])

    const { checkAuth } = useThunks(authThunk)

    const getList = async () => {

        await new Promise((resolve) => {
            setTimeout(resolve, 300)
        })

        const response = await authFetch(() => checkAuth())

        (`${ serverHttp }/api/category/list/${ Number(currentTournament.id) }`, {
            method: `GET`,
            headers: {
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()
        if (data?.error) {
            console.log(data.message)
            return
        }

        setCategoryList(data)
    }
    useEffect(() => {
        if (showSelectBox) {
            getList()
        }
        return () => {
            setCategoryList(null)
        }
    }, [ showSelectBox ])

    const handleSelectCategory = (e: any, category: any) => {
        e.stopPropagation()
        setCurrentCategory(category)
        setShowSelectBox(false)
    }

    return (
        <div className={ css(s.SelectTournament) }>
            <div className={ css(s.title) }>Выбрать категорию</div>
            <div id={ `currentCategory` } className={ css(s.currentTournament, showSelectBox && s.active) }
                 onClick={ () => setShowSelectBox(prev => !prev) }>
                <div
                    className={ css(s.name) }>{ currentCategory === null ? `Все категории` : currentCategory.name }</div>
                <div className={ css(s.showSelectBtn) }>
                    <svg width="30" height="30" viewBox="0 0 12 8" xmlns="https://www.w3.org/2000/svg">
                        <path
                            d="M5.59021 7.15047L0.452036 2.0122C0.333101 1.89336 0.267578 1.73471 0.267578 1.56556C0.267578 1.3964 0.333101 1.23776 0.452036 1.11891L0.83034 0.740515C1.07685 0.494289 1.47749 0.494289 1.72363 0.740515L6.03836 5.05525L10.3579 0.735728C10.4768 0.616886 10.6354 0.55127 10.8044 0.55127C10.9737 0.55127 11.1322 0.616886 11.2513 0.735728L11.6295 1.11413C11.7484 1.23306 11.8139 1.39161 11.8139 1.56077C11.8139 1.72993 11.7484 1.88857 11.6295 2.00741L6.48659 7.15047C6.36728 7.26959 6.20798 7.33502 6.03864 7.33464C5.86864 7.33502 5.70943 7.26959 5.59021 7.15047Z"/>
                    </svg>
                </div>
                { showSelectBox && <div className={ css(s.tournamentList) }>
                    <div className={ css(s.item) } onClick={ (e) => handleSelectCategory(e, null) }>Все категории</div>
                    { categoryList?.map(el => <div key={ el.id } className={ css(s.item) }
                                                   onClick={ (e) => handleSelectCategory(e, el) }>{ el.name }</div>) }
                    { categoryList === null && <div className={ css(s.loadingList) }>
                        <img src={ loadGif } alt="load"/>
                        Загрузка...
                    </div> }
                </div> }
            </div>
        </div>
    );
};

