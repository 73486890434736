import {RootState} from "store/ReduxStore";
import {CategoryType} from "types/CategoryType";

export const getAdminResultListSelector = (state: RootState) => {
    return state.ResultReducer.adminResultList

}
export const getAdminResultCategoryListSelector = (state: RootState) => {
    const categoryIdList: Array<number> = []
    const categoryList: Array<CategoryType> = []
    state.ResultReducer.adminResultList?.map((el:any) => {
        if (!!el?.task?.category?.id && !categoryIdList.includes(el?.task?.category?.id)) {
            categoryIdList.push(el.task.category.id)
            categoryList.push(el.task.category)
        }
    })
    return categoryList
}
