import s from './style.module.scss'
import { css } from "lib/customClassName";
import { Button } from "view/components/button";
import { InputText } from "view/components/InputText";
import { Skeleton } from "view/components/skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "lib/reduxHook";
import { getBigTaskItemByIdSelector } from "store/tournament/selector/getBigTaskItemById";
import React, { useLayoutEffect } from "react";
import {Helmet} from "react-helmet";
import { DESCRIPTION, KEYWORDS } from "config/constants";

type PropsType = {}
export const BigTaskItem = (props: PropsType) => {

    const {taskId} = useParams()
    const navigate = useNavigate()

    const task = useAppSelector(getBigTaskItemByIdSelector(Number(taskId)))

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    },[])

    const backHandler = () => {
        if (!task) {
            navigate(`/tournament/list`)
        } else {
            navigate(`/tournament/${ task.tournamentId }`)
        }
    }

    if (!task) return null
    return (
        <div className={ css(s.TaskItem) }>
            <Helmet>
                <title> Forge | {task.name}</title>
                <meta name="description" content={`${task.description}`} />
                <meta name="keywords" content={KEYWORDS} />
            </Helmet>
            <div className={css(s.backBtn)}><Button text={`←    Назад`} modes={[`mobilSmall`]} click={backHandler}/></div>
            <div className={ css(s.title) }>
                <div className={ css(s.name) }>{ task.name }</div>
            </div>
            <pre className={ css(s.bigDescription) } dangerouslySetInnerHTML={ { __html: task.bigDescription } }/>
        </div>
    );
};
