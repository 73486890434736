import s from './style.module.scss'
import {css} from "lib/customClassName";
import {Button} from "view/components/button";
import {useNavigate, useParams} from "react-router-dom";
import {getTournamentPrizeSelector} from "store/tournament/selector/getTournamentPrize";
import {useSelector} from "react-redux";
import React, { useEffect, useLayoutEffect } from "react";
import {Helmet} from "react-helmet";
import { getFullTournamentSelector } from "store/tournament/selector/getFullTournament";
import { DESCRIPTION, KEYWORDS } from "config/constants";

export const PrizePage = () => {
    const {id} = useParams()

    const navigate = useNavigate()

    const tournamentPrize = useSelector(getTournamentPrizeSelector)
    const tournament = useSelector(getFullTournamentSelector)

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    },[])

    useEffect(()=>{
        if (tournamentPrize === undefined) {
            navigate(`/tournament/${id}`)
        }
    },[])

    if (!id || tournamentPrize === undefined) return null
    return (
        <div className={css(s.PrizePage)}>
            <Helmet>
                <title> {tournament?.name} | Правила</title>
                <meta name="description" content={!tournamentPrize.length ? DESCRIPTION : tournamentPrize.replace(/(<([^>]+)>)/gi, '')} />
                <meta name="keywords" content={KEYWORDS} />
            </Helmet>
            <div className={css(s.container)}>
                <div className={css(s.main)} >
                    <Button text={`←    Назад`} modes={[`mobilSmall`]} click={()=>navigate(`/tournament/${id}`)}/>
                    <div className={css(s.titlePage)}>ПРИЗЫ</div>
                    {tournamentPrize.length > 0 && <div className={css(s.text)}><pre dangerouslySetInnerHTML={{ __html: tournamentPrize }} ></pre></div>}

                    {tournamentPrize.length === 0 &&
                        <div className={css(s.emptyList)}>Призы не описаны</div>}
                </div>
            </div>
        </div>
    );
};
