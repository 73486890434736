import s from './style.module.scss'
import { css } from "lib/customClassName";
import { TournamentType } from "types/TournamentType";
import { Button } from "view/components/button";
import { useNavigate } from "react-router-dom";
import Empty_img from "view/assets/images/empty_img.jpg";
import { serverHttp } from "config/api/api";
import { useMemo } from "react";

type PropsType = {
    tournament: TournamentType
}
export const TournamentHead = (props: PropsType) => {
    const { tournament } = props

    const navigate = useNavigate()

    const disableTournament = useMemo(() => {
        if (tournament.status === `Скоро старт`) return false
        if (tournament.status === `Сейчас идет`) return false
        if (tournament.status === `Завершен`) return true
        if (tournament.status === `Завершен, ждет проверки`) return true
        return true
    }, [])

    return (
        <div className={ css(s.TournamentHead) }>
            <div className={ css(s.logo) }>
                { tournament.img === '' && <img src={ Empty_img } alt="tournament_item"/> }
                { tournament?.img !== '' &&
                    <img src={ `${ serverHttp }/upload/` + tournament.img } alt="tournament_item"/> }
            </div>
            <div className={ css(s.main) }>
                <div className={ css(s.info) }>
                    <div className={ css(s.name) }>
                        { tournament.name }
                    </div>
                    <div className={ css(s.smallInfo) }>
                        { !!tournament.startDate.length && <div className={ css(s.date) }>
                            { tournament.startDate }
                        </div> }
                        <div className={ css(s.isOnline) }>
                            { tournament.isOnline ? <span className={ css(s.true) }>ONLINE</span> :
                                <span className={ css(s.false) }>OFFLINE</span> }
                        </div>
                    </div>
                </div>
                <div className={ css(s.btnBox) }>
                    <div className={ css(s.btnRow) }>
                        <Button text={ 'категории' } modes={ [ `uppercase`, `maxWidth`, `mobilSmall` ] }
                                click={ () => navigate(`/tournament/select/${ tournament.id }`) }/>
                        <Button text={ 'правила' } modes={ [ `uppercase`, `maxWidth`, `mobilSmall` ] }
                                click={ () => navigate(`/tournament/rules/${ tournament.id }`) }/>
                        <Button text={ 'призы' } modes={ [ `uppercase`, `maxWidth`, `mobilSmall` ] }
                                click={ () => navigate(`/tournament/prize/${ tournament.id }`) }/>
                    </div>
                    {/*<div className={css(s.btnRow, s.oneItem)}>*/ }
                    {/*    <Button text={'категории'} modes={[`uppercase`, `maxWidth`]}*/ }
                    {/*            click={() => navigate(`/tournament/select/${tournament.id}`)}/>*/ }
                    {/*</div>*/ }
                    <div className={ css(s.btnRow, s.oneItem) }>
                        { tournament.status === `Скоро старт` && !!tournament?.paidMe?.length &&
                            <Button text={ 'добавить результат' } disable={ true }
                                    modes={ [ `uppercase`, `maxWidth`, `mobilSmall` ] }/> }
                        { disableTournament && <Button text={ 'Турнир завершён' } disable={ true }
                                                       modes={ [ `uppercase`, `maxWidth`, `mobilSmall` ] }/> }
                        { !disableTournament && !tournament?.paidMe?.length &&
                            <Button text={ 'ПРИНЯТЬ УЧАСТИЕ' } modes={ [ `uppercase`, `maxWidth`, `mobilSmall`, `red` ] }
                                    click={ () => navigate(`/tournament/select/${ tournament.id }`) }/> }
                        { !disableTournament && !!tournament?.paidMe?.length && tournament.status !== `Скоро старт` &&
                            <Button text={ 'добавить результат ' } modes={ [ `uppercase`, `maxWidth`, `mobilSmall`, `red` ] }
                                    click={ () => navigate(`/tournament/add/${ tournament.id }`) }/> }
                        {!!tournament?.paidMe?.length && <div className={css(s.myCategory)}>
                            <span className={css(s.subTitle)}>Вы зарегистрированы в категории: </span>{tournament?.paidMe.map(el => <span key={el.id} className={css(s.categoryName)}>{el.category.name}</span>)}
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
};
