import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ProfileType} from "types/ProfileType";

interface stateType {
	profile: null | ProfileType
}

const initialState: stateType = {
	profile: null,
}

export const slice = createSlice({
	name: 'ProfileReducer',
	initialState,
	reducers: {
		setMyProfileAction: (state, action: PayloadAction<ProfileType | null>): void => {
			state.profile = action.payload
		}
	}
})

export const ProfileReducer = slice.reducer
export const ProfileActions = slice.actions
