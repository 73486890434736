import {RootState} from "store/ReduxStore";

export const getResultTableSelector = (state: RootState) => {
    const activeSortId = state.TournamentReducer.resultTableSortId

    if (!state.TournamentReducer.resultTable?.resultList) return null
    if (activeSortId === null) return state.TournamentReducer.resultTable

    const sortResultList = [...state.TournamentReducer.resultTable.resultList]

    return {
        ...state.TournamentReducer.resultTable,
        resultList: sortResultList.sort((a: any, b: any) => {
            if (a.resultMapList[`${activeSortId}`].point > b.resultMapList[`${activeSortId}`].point) return 1;
            if (a.resultMapList[`${activeSortId}`].point === b.resultMapList[`${activeSortId}`].point) return 0;
            if (a.resultMapList[`${activeSortId}`].point < b.resultMapList[`${activeSortId}`].point) return -1;
            return 0
        })

    }
}


