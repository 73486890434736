import {RootState} from "store/ReduxStore";
import {TaskType} from "types/TaskType";

export const getCategoryWithTaskItemListSelector = (taskId: number) => (state: RootState): TaskType | null => {
    const categoryList = state.CategoryReducer.categoryListWithTask
    if (!categoryList) return null
    for (let i = 0; i < categoryList?.length; i++) {
        const taskList = categoryList[i].taskList
        if (!taskList) return null
        for (let j = 0; j < taskList.length; j++) {
            if (taskList[j].id === taskId) return taskList[j]
        }
    }
    return null
}
