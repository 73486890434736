import s from './index.module.scss'
import {css} from "lib/customClassName";
import {useClickOutside} from "lib/useClickOutside";
import {createPortal} from "react-dom";
import {useCallback, useState} from "react";
import {authThunk} from "store/auth/thunk/authThunk";
import {useThunks} from "lib/reduxHook";
import {Button} from "view/components/button";
import {REG_EXP} from 'config/regExp'
import {useNavigate} from "react-router-dom";

type PropsType = {
    close: Function,
    mode?: 'login'|'registration',
    element?: HTMLElement
}
export const LoginPopup = (props: PropsType) => {
    const {close, element = document.body, mode = 'login'} = props

    const [currentMode, setCurrentMode] = useState(mode)
    const [isLoading, setIsLoading] = useState(false)

    useClickOutside(() => !isLoading && close(), [`LoginPopup`], [isLoading])

    return createPortal(
            <div className={css(s.LoginPopup)} onClick={e => e.stopPropagation()}>
                <div id={`LoginPopup`} className={css(s.main)}>
                    {currentMode === 'login' ?
                        <Login setCurrentMode={setCurrentMode} isLoading={isLoading} setIsLoading={setIsLoading} close={close}/>
                        : currentMode === 'registration' ?
                        <Registration setCurrentMode={setCurrentMode} isLoading={isLoading} setIsLoading={setIsLoading}/>
                        :
                        <RemindPassword setCurrentMode={setCurrentMode} isLoading={isLoading} setIsLoading={setIsLoading}/>
                    }
                    {!isLoading && <div className={css(s.closeBtn)} onClick={() => close()}/>}
                </div>
            </div>
    , element);
};

type LoginPropsType = {
    setCurrentMode: Function
    setIsLoading: Function
    isLoading: boolean
    close: Function
}
const Login = (props: LoginPropsType) => {
    const {setCurrentMode, isLoading = false, setIsLoading, close} = props

    const [data, setData] = useState<{ login: string, password: string }>({login: '', password: ''})
    const [error, setError] = useState<string|null>(null)
    const navigate = useNavigate()

    const {login} = useThunks(authThunk)

    const errorCallback = useCallback((message: string)=>{
        setError(message)
    },[])

    const clickOnRemindPassword = () => {
        close()
        navigate('/password_remind')
    }

    const loginHandler = async (e: any) => {
        e.preventDefault()
            if (!checkIsValidPassword()) return
        if (!checkIsValidEmail) return
        setIsLoading(true)
        await login({
            data: {
                login: data.login,
                pswd: data.password
            },
            errorCallback: errorCallback
        })
        setIsLoading(false)
    }

    const checkIsValidEmail = () => {
        if (!data.login) {
            setError('Пожалуйста, заполните пустые поля')
            return false
        }
        return true
    }

    const checkIsValidPassword = () => {
        if (!data.password) {
            setError('Пожалуйста, заполните пустые поля')
            return false
        }
        return true
    }

    const changeLoginHandler = (e) => {
        setData(prev => ({...prev, login: e.target.value}))
        setError(null)
    }
    const changePasswordHandler = (e) => {
        setData(prev => ({...prev, password: e.target.value}))
        setError(null)
    }

    return (
        <div className={css(s.Login)}>
            <div className={css(s.title)}>Вход</div>
            {error !== null && <div className={css(s.errorMessage)}>{error}</div>}
            <form onSubmit={loginHandler}>
                <input type="text" disabled={isLoading} placeholder={`Email:`} onChange={changeLoginHandler}/>
                <input type="password" disabled={isLoading} placeholder={`Пароль:`} onChange={changePasswordHandler}/>
                <div className={css(s.sendBtnBox)}>
                    <Button text={`Войти`} modes={[`maxWidth`,`red`,`noRadius`]} isLoading={isLoading}/>
                </div>
            </form>
            <div className={css(s.changeMode)}>
                <span>У вас нет аккаунта?</span>
                <span className={css(s.btn)} onClick={() => !isLoading && setCurrentMode('registration')}>Создать</span>
            </div>

            <div className={css(s.changeMode)}>
                <span>Забыли пароль?</span>
                <span className={css(s.btn)} onClick={() => !isLoading && setCurrentMode('remind')}>Восстановить</span>
            </div>
        </div>
    );
};

type RegistrationPropsType = {
    setCurrentMode: Function
    setIsLoading: Function
    isLoading: boolean
}
const Registration = (props: RegistrationPropsType) => {
    const {setCurrentMode, isLoading, setIsLoading} = props

    const [data, setData] = useState<{ login: string, password: string, confirm: string }>({login: '', password: '', confirm: ''})
    const [error, setError] = useState<string|null>(null)

    const {registration} = useThunks(authThunk)

    const errorCallback = useCallback((message: string)=>{
        setError(message)
    },[])

    const registrationHandler = async (e: any) => {
        e.preventDefault()
        if (!checkIsValidPassword()) return
        if  (!checkIsValidEmail()) return
        if (data.password !== data.confirm) {
            setError(`Пароли не совпадают`)
            return
        }
        setIsLoading(true)
        await registration({
            data: {
                login: data.login,
                pswd: data.password
            },
            errorCallback: errorCallback
        })
        setIsLoading(false)
    }
    const changeLoginHandler = (e) => {
        setData(prev => ({...prev, login: e.target.value}))
        setError(null)
    }
    const changePasswordHandler = (e) => {
        setData(prev => ({...prev, password: e.target.value}))
        setError(null)
    }
    const changeConfirmHandler = (e) => {
        setData(prev => ({...prev, confirm: e.target.value}))
        setError(null)
    }

    const checkIsValidEmail = () => {
        if (!data.login.match(REG_EXP.EMAIL_VALIDATE)) {
            setError('Некорректный логин')
            return false
        }
        return true
    }

    const checkIsValidPassword = () => {
        if (!data.password.match(REG_EXP.PASSWORD_VALIDATE) || !data.confirm.match(REG_EXP.PASSWORD_VALIDATE)) {
            setError('Пароль должен содержать хотя бы одну цифру, одну букву в нижнем регистре и одну букву в верхнем регистре')
            return false
        }
        return true
    }

    return (
        <div className={css(s.Registration)}>
            <div className={css(s.title)}>Регистрация</div>
            {error !== null && <div className={css(s.errorMessage)}>{error}</div>}
            <form onSubmit={registrationHandler}>
                <input type="text" disabled={isLoading} placeholder={`Email:`} onChange={changeLoginHandler}/>
                <input type="password" disabled={isLoading} placeholder={`Пароль:`} onChange={changePasswordHandler}/>
                <input type="password" disabled={isLoading} placeholder={`Повторите пароль:`} onChange={changeConfirmHandler}/>
                <div className={css(s.sendBtnBox)}>
                    <Button text={`Создать`} modes={[`maxWidth`,`red`,`noRadius`]} isLoading={isLoading}/>
                </div>
            </form>
            <div className={css(s.changeMode)}>
                <span>У вас уже есть аккаунт?</span>
                <span className={css(s.btn)} onClick={() => !isLoading && setCurrentMode('login')}>Войти</span>
            </div>
        </div>
    );
};

type RemindPasswordPropsType = {
    setCurrentMode: Function
    setIsLoading: Function
    isLoading: boolean
}
const RemindPassword = (props: RemindPasswordPropsType) => {
    const {setCurrentMode, isLoading, setIsLoading} = props

    const [data, setData] = useState<{email: string}>({email: ''})
    const [error, setError] = useState<string|null>(null)
    const [success, setSuccess] = useState<string|null>(null)

    const {getRemindPasswordLink} = useThunks(authThunk)

    const errorCallback = useCallback((message: string)=>{
        setError(message)
    },[])

    const successCallback = useCallback(()=>{
        setSuccess('На указанную почту отправлена ссылка для восстановления пароля')
    },[])

    const remindPasswordHandler = async (e: any) => {
        e.preventDefault()
        if  (!checkIsValidEmail()) return

        setIsLoading(true)
        await getRemindPasswordLink({
            data: {
                email: data.email
            },
            errorCallback: errorCallback,
            successCallback: successCallback,
        })
        setIsLoading(false)
    }
    const changeEmailHandler = (e) => {
        setData(prev => ({...prev, email: e.target.value}))
        setError(null)
    }

    const checkIsValidEmail = () => {
        if (!data.email.match(REG_EXP.EMAIL_VALIDATE)) {
            setError('Некорректный логин')
            return false
        }
        return true
    }

    return (
        <div className={css(s.Registration)}>
            {!success ?
                <>
                    <div className={css(s.title)}>Восстановление <br/>пароля</div>
                    {error !== null && <div className={css(s.errorMessage)}>{error}</div>}
                    <form onSubmit={remindPasswordHandler}>
                        <input type="text" disabled={isLoading} placeholder={`Email:`} onChange={changeEmailHandler}/>
                        <div className={css(s.sendBtnBox)}>
                            <Button text={`Восстановить пароль`} modes={[`maxWidth`,`red`,`noRadius`]} isLoading={isLoading}/>
                        </div>
                    </form>
                    <div className={css(s.changeMode)}>
                        <span>У вас нет аккаунта?</span>
                        <span className={css(s.btn)} onClick={() => !isLoading && setCurrentMode('registration')}>Создать</span>
                    </div>
                </>
                :
                <div className={css(s.success)}>
                    <div className={css(s.successMessageTitle)}>Восстановление пароля</div>
                    <div className={css(s.successMessage)}>{success}</div>
                </div>
            }

        </div>
    );
};
