import s from './index.module.scss'
import {css} from "lib/customClassName";
import {useNavigate} from "react-router-dom";
import {TournamentType} from "types/TournamentType";
import {Button} from "view/components/button";
import Empty_img from "view/assets/images/empty_img.jpg";
import {serverHttp} from "config/api/api";
import {useState} from "react";
import loadGif from "view/assets/images/Load.gif";

type PropsType = {
    item: TournamentType
    isEditable?: boolean
    changePosition?: (tournamentId: number, isPrev: boolean) => void
}
export const TournamentItem = (props: PropsType) => {
    const {item, isEditable = false, changePosition} = props
    const navigate = useNavigate()

    const [isLoader, setIsLoader] = useState(false)

    const openTournamentHandler = () => {
        navigate(`/tournament/${item.id}`)
    }
    const openAdminTournamentHandler = () => {
        navigate(`/admin/tournament/edit/${item.id}`)
    }
    const openAdminCategoryHandler = () => {
        navigate(`/admin/tournament/category-list/${item.id}`)
    }
    const prevPositionTournamentHandler = async () => {
        if (!changePosition) return
        setIsLoader(true)
        await changePosition(Number(item.id), true)
        setIsLoader(false)
    }
    const nextPositionTournamentHandler = async () => {
        if (!changePosition) return
        setIsLoader(true)
        await changePosition(Number(item.id), false)
        setIsLoader(false)
    }

    return (
        <div className={css(s.BestsellerItem)} onClick={openTournamentHandler}>
            <div className={css(s.imgBox)}>
                {item.img === '' && <img src={Empty_img} alt="tournament_item"/>}
                {item.img !== '' && <img src={`${serverHttp}/upload/` + item.img} alt="tournament_item"/>}
            </div>
            <div className={css(s.title)}>{item.name}</div>
            <div className={css(s.text)}>{item.descriptionSmall}</div>
            <div className={css(s.status)}>
                {(() => {
                    switch (item.status) {
                        case 'Завершен':
                            return <span className={css(s.finish)}>{item.status}</span>
                        case 'Завершен, ждет проверки':
                            return <span className={css(s.finish)}>{item.status}</span>
                        case 'Сейчас идет':
                            return <span className={css(s.work)}>{item.status}</span>
                        case 'Скоро старт':
                            return <span className={css(s.soon)}>{item.status}</span>
                        default:
                            return <span className={css(s.soon)}>{item.status}</span>
                    }
                })()}
            </div>
            {isEditable && <div className={css(s.isEditable)} onClick={e => e.stopPropagation()}>
                <Button text={`Редактировать турнир`} modes={[`maxWidth`, `mobilSmall`, `red`]}
                        click={openAdminTournamentHandler}/>
                <Button text={`Редактировать категории`} modes={[`maxWidth`, `mobilSmall`, `red`]}
                        click={openAdminCategoryHandler}/>
                <Button text={`Открыть турнир`} modes={[`grey`, `maxWidth`, `mobilSmall`]}
                        click={openTournamentHandler}/>
                <div className={css(s.PositionBtn)}>
                    {!isLoader && <div>
                        <div className={css(s.title)}>{`Позиция`}</div>
                        <div className={css(s.btnBox)}>
                            <div className={css(s.prevBtn)} onClick={prevPositionTournamentHandler}>←</div>
                            <div className={css(s.nextBtn)} onClick={nextPositionTournamentHandler}>→</div>
                        </div>
                    </div>}
                    {isLoader && <div className={css(s.loadingBtn)}>
                        <img src={loadGif} alt="load"/>
                    </div>}
                </div>
            </div>}
        </div>
    );
};
