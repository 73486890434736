import s from './style.module.scss'
import {css} from "lib/customClassName";
import {Outlet} from "react-router-dom";
import React from "react";
import {AdminNavigate} from "view/module/admin/navigate";
import {Helmet} from "react-helmet";
import { DESCRIPTION, KEYWORDS } from "config/constants";

export const AdminLayout = () => {

    return (
        <div className={css(s.AdminLayout)}>
            <Helmet>
                <title>Forge | Список турниров</title>
                <meta name="description" content={DESCRIPTION} />
                <meta name="keywords" content={KEYWORDS} />
            </Helmet>
            <div className={css(s.container)}>
                <AdminNavigate />
                <Outlet/>
            </div>
        </div>
    );
};
