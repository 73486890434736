import s from './style.module.scss'
import { css } from "lib/customClassName";
import { Popup } from "view/components/popup";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { RadioButton } from "view/components/RadioButton";
import { useProfile } from "store/auth/hook/useProfile";
import { useClickOutside } from "lib/useClickOutside";
import { Skeleton } from "view/components/skeleton";
import { authFetch } from "lib/authFetch";
import { serverHttp } from "config/api/api";
import { authThunk } from "store/auth/thunk/authThunk";
import { useAppDispatch } from "lib/reduxHook";
import { ProfileType } from "types/ProfileType";
import { useAuth } from "store/auth/hook/useAuth";

interface IProps {
    isLoad: boolean
    currentBtn: (command?: { name: string, members: any[] }) => ReactNode
    closePopupBtn: () => void
    isOnlyCommand?: boolean
}

export const AllBuyPopup = ({ isLoad, currentBtn, closePopupBtn, isOnlyCommand = false }: IProps) => {

    const profile = useProfile()

    const user = useAuth()

    const [ errorCommandName, setErrorCommandName ] = useState('')

    const [ permissionMode, setPermissionMode ] = useState<`Одиночное участие` | `Командное участие` | ''>(``)

    const [ commandName, setCommandName ] = useState<string>('')

    const [ commandMembers, setCommandMembers ] = useState<ProfileType[]>([])

    useEffect(() => {
        if (isOnlyCommand) {
            setPermissionMode(`Командное участие`)
        }
    }, [ isOnlyCommand ])

    const addMemberHandler = (newMember: ProfileType) => {
        if (commandMembers.find(member => member.id === newMember.id)) return
        setCommandMembers(prev => [ ...prev, newMember ])
    }
    const deleteMemberHandler = (id: number) => {
        setCommandMembers(prev => prev.filter(member => member.id !== id))
    }

    const currentBuyBtn = () => {
        if (!user) return
        if (permissionMode === `Одиночное участие`) {
            return currentBtn && currentBtn()
        }
        if (permissionMode === `Командное участие`) {
            return currentBtn && currentBtn({
                name: commandName ?? '',
                members: [ user.id, ...commandMembers.map(member => Number(member.userId)) ]
            })
        }
        return null;
    }

    if (!profile) return null
    return (
        <Popup clickCloseBtnCallback={ closePopupBtn }>
            <div className={ css(s.AllBuyPopup, isLoad && s.load, isOnlyCommand && s.isOnlyCommand) }>
                { !isOnlyCommand && <RadioButton title={ `Укажите режим участия` }
                                                 modes={ [ `white` ] }
                                                 value={ permissionMode }
                                                 change={ str => setPermissionMode(str) }
                                                 list={ [ `Одиночное участие`, `Командное участие` ] }/> }

                { permissionMode === 'Командное участие' &&
                    <div className={ css(s.commandName) }>
                        <div className={ css(s.title) }>Название команды</div>
                        <input className={ css(commandName.trim().length < 4 && s.error) }
                               type="text"
                               placeholder={ 'Название' }
                               value={ commandName ?? '' }
                               onChange={ ({ target: { value } }) => setCommandName(value) }/>
                    </div>
                }
                { permissionMode === 'Командное участие' &&
                    <div className={ css(s.command) }>
                        <div className={ css(s.title) }>Участники команды</div>
                        <div className={ css(s.list) }>
                            <Member
                                member={ { id: Date.now(), firstName: profile.firstName, lastName: profile.lastName } }
                                index={ 1 }/>
                            { commandMembers.map((member, index) => <Member key={ member.id }
                                                                            deleteMember={ deleteMemberHandler }
                                                                            member={ member } index={ index + 2 }/>) }
                        </div>
                        <div className={ css(s.addBtn) }>
                            <AddMemberBtn addMember={ addMemberHandler }/>
                        </div>
                    </div> }

                <div
                    className={ css(s.currentBtn, ( permissionMode === '' || ( permissionMode === 'Командное участие' && ( !commandMembers.length || commandName.trim().length < 4 ) ) ) && s.disable) }>
                    { currentBuyBtn() }
                </div>

            </div>
        </Popup>

    );
};

interface IMemberProps {
    member: {
        id: number
        firstName: string
        lastName: string
    }
    index: number
    deleteMember?: (id: number) => void
}

const Member = ({ member, index, deleteMember }: IMemberProps) => {
    return (
        <div className={ css(s.Member) }>
            <div className={ css(s.index) }>{ index }.</div>
            <div className={ css(s.fullName) }>{ `${ member.firstName } ${ member.lastName }` }</div>
            { deleteMember &&
                <div className={ css(s.deleteBtn) } onClick={ () => deleteMember(member.id) }>удалить</div> }
        </div>
    )
}

interface IAddMemberBtnProps {
    addMember: (member: ProfileType) => void
}

const AddMemberBtn = ({ addMember }: IAddMemberBtnProps) => {
    const [ isShowInput, setIsShowInput ] = useState(false)
    const [ error, setError ] = useState('')

    const btnHandler = (member: ProfileType) => {
        addMember(member)
        setIsShowInput(false);
    }

    const openSelectMemberHandler = () => {
        return setIsShowInput(true)
    }


    return (
        <div className={ css(s.AddMemberBtn) }>
            <div className={ css(s.errorText, error && s.error) }>{ error }</div>
            <div id={ `AddMemberBtn` } className={ css(s.AddMemberBtn) }>
                {/*<input placeholder={ 'Фамилия Имя' } value={ newMember } onChange={ changeInputHandler }*/ }
                {/*       className={ css(isShowInput && s.active) } type="text"/>*/ }
                <div className={ css(s.topBtn) } onClick={ openSelectMemberHandler }>
                    <span>Добавить участника</span>
                </div>
            </div>
            { isShowInput && <SelectMember addMember={ btnHandler } closeSelect={() => setIsShowInput(false)}/> }
        </div>

    )
}

interface ISelectMemberProps {
    addMember: (member: ProfileType) => void
    closeSelect: any
}

const SelectMember = ({ addMember, closeSelect }: ISelectMemberProps) => {
    const dispatch = useAppDispatch()
    const [ search, setSearch ] = useState('')
    const [ list, setList ] = useState<Array<ProfileType> | null>(null)
    const [ pagination, setPagination ] = useState({
        limit: 100,
        totalCount: 0
    })

    const getList = async () => {
        try {
            const response: any = await authFetch(() => dispatch(authThunk.checkAuth()))
            (`${ serverHttp }/api/profile/members?limit=${ pagination.limit }&offset=${ 0 }&search=${ search }`, {
                method: `GET`,
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            const data = await response.json()

            setList(data.rows)
            setPagination(prev => ( { ...prev, totalCount: data.count } ))
        } catch (err) {
            setList([])
            setPagination(prev => ( { ...prev, totalCount: 0 } ))
        } finally {

        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            getList().then();
        }, 200)

        return () => {
            clearTimeout(timer)
        }
    }, [ search ])

    const changeInputHandler = ({ target: { value } }: any) => {
        // setError('')
        const srt = value.replace(/  +/g, ' ');
        if (srt === ' ') {
            return
        }
        if (srt[0] === ' ') {
            return
        }
        if (srt.split(' ').length > 2) {
            return
        }
        setSearch(srt)
    }

    return (
        <div className={ css(s.SelectMember) }>
            <div className={css(s.backSelectBtn)} onClick={closeSelect}>назад</div>
            <div className={ css(s.title) }>Добавление участника</div>
            <input placeholder={ 'Поиск: Фамилия Имя' } value={ search } onChange={ changeInputHandler }
                   className={ css(s.searchInput) } type="text"/>
            <div className={ css(s.membersList) }>
                { list?.map(member => <div key={ member.id } className={ css(s.memberItem) }>
                    <div className={ css(s.fullMemberName) }>
                        <div>{ member.lastName } { member.firstName }</div>
                        { !!member.gym.trim().length && <div>Зал: { member.gym }</div>}
                        { !!member.city.trim().length && <div>Город: { member.city }</div>}
                    </div>
                    <div className={ css(s.addMemberBtn) } onClick={ () => addMember(member) }>добавить</div>
                </div>) }
                { !list && <><Skeleton/>
                    <div className={ css(s.loadList) }>Загрузка...</div>
                </> }
                { !!list && list.length === 0 && <div className={ css(s.emptyList) }>Нет подходящих</div> }
            </div>
        </div>
    );
};
