import s from './style.module.scss'
import { css } from "lib/customClassName";
import { Button } from "view/components/button";
import { useNavigate, useParams } from "react-router-dom";
import { CategoryType } from "types/CategoryType";
import { LoginButton } from "view/components/loginButton";
import { useAuth } from "store/auth/hook/useAuth";
import { useProfile } from "store/auth/hook/useProfile";
import { useThunks } from "lib/reduxHook";
import { categoryThunk } from "store/category/thunk/categoryThunk";
import { useCallback, useMemo, useState } from "react";
import { Popup } from "view/components/popup";
import { CommandBuyPopup } from "view/module/commandBuyPopup";
import { AllBuyPopup } from "view/module/allBuyPopup";

type PropsType = {
    category: CategoryType,
}
export const CategoryItem = (props: PropsType) => {
    const { category } = props

    const { id } = useParams()

    const navigate = useNavigate()

    // const [showPopup, setShowPopup] = useState(category.permissionMode ?? 'member')
    const [ showPopup, setShowPopup ] = useState(false)

    const user = useAuth()

    const profile = useProfile()

    const [ isLoad, setIsLoad ] = useState(false)

    const { buyCategory } = useThunks(categoryThunk)

    const buyCategoryHandler = async (command?: {name: string, members: Array<number>}) => {
        if (!showPopup && category.permissionMode !== 'member') {
            return setShowPopup(true)
        }
        setIsLoad(true)
        await buyCategory(Number(category.id), (link: string | undefined) => {
            if (link) {
                window.location.replace(link)
            } else {
                navigate(`/tournament/${ id }`)
            }
        }, command)
        setShowPopup(false)
        setIsLoad(false)
    }

    const currentBtn = (command?: {name: string, members: Array<number>}) => {
        if (!category.isPadeMe && profile !== null && !!user && !!user.id)
            return (
                <Button text={ `${ Number(category.price) === 0 ? `Бесплатно` : `Купить` }    →` }
                        isLoading={ isLoad }
                        disable={ !category.isAccessBuy }
                        modes={ [ `red`, `maxWidth`, `noRadius`, `mobilSmall` ] }
                        click={ () => buyCategoryHandler(command) }/> )

        if (!!category.isPadeMe && !category.isSubmissionResult)
            return (
                <Button text={ `Подача результатов закрыта` }
                        disable={ true }
                        modes={ [ `noRadius`, `mobilSmall` ] }/> )

        if (!!category.isPadeMe && category.isSubmissionResult && profile !== null && !!user && !!user.id)
            return (
                <Button text={ `Подать результаты` }
                        modes={ [ `maxWidth`, `noRadius`, `mobilSmall` ] }
                        click={ () => navigate(`/tournament/add/${ category.tournamentId }`) }/> )

        if (!!user && !user.id )
            return (
                <LoginButton text={ `Войти` }/> )

        if (!!user && !!user.id && profile === null)
            return (
                <Button text={ 'Заполнить профиль' }
                        modes={ [ `uppercase`, `maxWidth`, `mobilSmall` ] }
                        click={ () => navigate(`/profile`) }/> )

        return ( <Button text={ 'Заполнить профиль' }
                         modes={ [ `uppercase`, `maxWidth`, `mobilSmall` ] }
                         click={ () => navigate(`/profile`) }/> )
    }

    if (!id) return null
    return (
        <div className={ css(s.CategoryItem) }>
            <div className={ css(s.name) }>
                <span>{ category.name }</span>
                {/*<div className={css(s.isOnline)}>*/ }
                {/*    {category.isOnline ? <span className={css(s.true)}>ONLINE</span> :*/ }
                {/*        <span className={css(s.false)}>OFFLINE</span>}*/ }
                {/*</div>*/ }
            </div>
            <div className={ css(s.description) }>{ category.description }</div>
            <div className={ css(s.price) }>Цена: { category.price } Р</div>
            <div className={ css(s.bayBtn) }>
                {currentBtn()}
            </div>
            { showPopup && {
                'command': <AllBuyPopup isLoad={isLoad}
                                        isOnlyCommand={true}
                                            currentBtn={currentBtn}
                                           closePopupBtn={ () => setShowPopup(false) }/>,
                'all': <AllBuyPopup
                    isLoad={isLoad}
                    currentBtn={currentBtn}
                    closePopupBtn={ () => setShowPopup(false) }/>,
            }[`${ category.permissionMode }`] }
        </div>
    );
};
