import s from './style.module.scss'
import {css} from "lib/customClassName";
import {TournamentItem} from "view/components/tournamentItem";
import {Button} from "view/components/button";
import {useSelector} from "react-redux";
import {getTournamentListSelector} from "store/tournament/selector/getTournamentList";
import {useActions, useThunks} from "lib/reduxHook";
import {tournamentThunk} from "store/tournament/thunk/tournamentThunk";
import React, { useEffect, useLayoutEffect, useState } from "react";
import loadGif from 'view/assets/images/Load.gif'
import {TournamentActions} from "store/tournament/reducer/tournamentReducer";
import {getTournamentListPaginationSelector} from "store/tournament/selector/getTournamentListPagination";
import {Helmet} from "react-helmet";
import { DESCRIPTION, KEYWORDS } from "config/constants";
export const TournamentListPage = () => {

    const tournamentList = useSelector(getTournamentListSelector)

    const tournamentListPagination = useSelector(getTournamentListPaginationSelector)

    const [isLoaderMoreBtn, setIsLoaderMoreBtn] = useState(false)

    const [filter, setFilter] = useState('all')

    const {setTournamentListAction} = useActions(TournamentActions)

    const {getList} = useThunks(tournamentThunk)

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    },[])

    useEffect(() => {
        const ctrl = new AbortController();
        getList(filter, ctrl.signal)
        return () => {
            ctrl && ctrl.abort();
            setTournamentListAction(null)
        }
    }, [filter])

    const handleMoreTournament = async () => {
        setIsLoaderMoreBtn(true)
        await getList(filter)
        setIsLoaderMoreBtn(false)
    }

    const handleSetFilter = async (name: string) => {
        setFilter(name)
    }

    return (
        <div className={css(s.TournamentListPage)}>
            <Helmet>
                <title>Forge | Список турниров</title>
                <meta name="description" content={DESCRIPTION} />
                <meta name="keywords" content={KEYWORDS} />
            </Helmet>
            <div className={css(s.container)}>
                <div className={css(s.head)}>
                    <div className={css(s.headTitle)}>
                        каталог турниров
                    </div>
                </div>
                <div className={css(s.filter)}>
                    <Button text={'все'} modes={[`uppercase`, `mobilSmall`, filter === 'all' ? `active`:``]} click={()=>handleSetFilter('all')}/>
                    <Button text={'скоро старт'} modes={[`uppercase`, `mobilSmall`, filter === 'soon' ? `active`:``]} click={()=>handleSetFilter('soon')}/>
                    <Button text={'сейчас идет'} modes={[`uppercase`, `mobilSmall`, filter === 'work' ? `active`:``]} click={()=>handleSetFilter('work')}/>
                    <Button text={'закончен'} modes={[`uppercase`, `mobilSmall`, filter === 'finish' ? `active`:``]} click={()=>handleSetFilter('finish')}/>
                </div>
                <div className={css(s.list)}>
                    {tournamentList === null && <div className={css(s.loadingList)}>
                        <img src={loadGif} alt="load"/>
                        Загрузка...
                    </div>}
                    {tournamentList?.map((item, index) =>
                        <TournamentItem key={index} item={item}/>
                    )}
                    {tournamentList !== null && !tournamentList.length && <div className={css(s.emptyList)}>
                        Список турниров пуст
                    </div>}
                </div>
                {tournamentList !== null && tournamentList.length < (tournamentListPagination.total ? tournamentListPagination.total : 0) &&
                    <div className={css(s.btnBox)}>
                        <Button text={'показать больше'} modes={[`uppercase`, 'maxWidth', `mobilSmall`]} isLoading={isLoaderMoreBtn} click={handleMoreTournament}/>
                    </div>}
            </div>
        </div>
    );
};
