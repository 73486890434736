import {AppDispatch, RootState} from "store/ReduxStore";
import {TournamentActions} from "store/tournament/reducer/tournamentReducer";
import {serverHttp} from "config/api/api";
import {authFetch} from "lib/authFetch";
import {authThunk} from "store/auth/thunk/authThunk";

export const tournamentThunk = {
    getAdminList: (filter: string, signal?: AbortSignal) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            // await new Promise((resolve) => {
                // setTimeout(resolve, 1000)
          //   })

            const pagination = getState().TournamentReducer.listPagination
            const countList = getState().TournamentReducer.list !== null ? getState().TournamentReducer.list?.length : 0

            const response:any = await authFetch(() => dispatch(authThunk.checkAuth()))
            (`${serverHttp}/api/tournament/admin-ist?limit=${pagination.limit}&offset=${countList}&filter=${filter}`, {
                method: `GET`,
                headers: {
                    'Content-Type': 'application/json',
                },
                signal: signal
            })

            const data = await response.json()
            if (data?.error) {
                console.log(data.message)
                return
            }

            const currentCountList = getState().TournamentReducer.list !== null ? getState().TournamentReducer.list?.length : 0
            if (currentCountList === 0 && countList !== 0) {
                return
            }

            dispatch(TournamentActions.setTournamentListAction(data.rows))
            dispatch(TournamentActions.setTournamentListPaginationAction({limit: pagination.limit, total: data.count}))

        } catch (error: any) {
            console.log('error client', error)
        } finally {
            // dispatch(FetchingActions.setIsLoadingUserListAction(false))
        }
    },
    getAdminTournamentItem: (id: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            // await new Promise((resolve) => {
                // setTimeout(resolve, 1000)
          //   })

            const response:any = await authFetch(() => dispatch(authThunk.checkAuth()))
            (`${serverHttp}/api/tournament/${id}`, {
                method: `GET`,
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            const data = await response.json()
            if (data?.error) {
                console.log(data.message)
                return
            }

            dispatch(TournamentActions.setEditTournamentAction({...data, partnerList: JSON.parse(data.partnerList)}))

        } catch (error: any) {
            console.log('error client', error)
        } finally {
            // dispatch(FetchingActions.setIsLoadingUserListAction(false))
        }
    },
    changePosition: (firstId: number, secondId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            // await new Promise((resolve) => {
                // setTimeout(resolve, 1000)
          //   })

            const response:any = await authFetch(() => dispatch(authThunk.checkAuth()))
            (`${serverHttp}/api/tournament/change-position`, {
                method: `POST`,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    firstId: firstId,
                    secondId: secondId,
                })
            })

            const data = await response.json()
            if (data?.error) {
                console.log(data.message)
                return
            }

            await dispatch(TournamentActions.changePositionAction({firstId, secondId}))

        } catch (error: any) {
            console.log('error client', error)
        } finally {
            // dispatch(FetchingActions.setIsLoadingUserListAction(false))
        }
    },

    getList: (filter: string, signal?: AbortSignal) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            // await new Promise((resolve) => {
            //     setTimeout(resolve, 1000)
            // })

            const pagination = getState().TournamentReducer.listPagination
            const countList = getState().TournamentReducer.list !== null ? getState().TournamentReducer.list?.length : 0

            const response:any = await authFetch(() => dispatch(authThunk.checkAuth()))
            (`${serverHttp}/api/tournament/list?limit=${pagination.limit}&offset=${countList}&filter=${filter}`, {
                method: `GET`,
                headers: {
                    'Content-Type': 'application/json',
                },
                signal: signal
            })

            const data = await response.json()
            if (data?.error) {
                console.log(data.message)
                return
            }

            const currentCountList = getState().TournamentReducer.list !== null ? getState().TournamentReducer.list?.length : 0
            if (currentCountList === 0 && countList !== 0) {
                return
            }

            dispatch(TournamentActions.setTournamentListAction(data.rows))
            dispatch(TournamentActions.setTournamentListPaginationAction({limit: pagination.limit, total: data.count}))

        } catch (error: any) {
            console.log('error client', error)
        } finally {
            // dispatch(FetchingActions.setIsLoadingUserListAction(false))
        }
    },
    getFullTournament: (id: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            // await new Promise((resolve) => {
                // setTimeout(resolve, 1000)
          //   })

            const response:any = await authFetch(() => dispatch(authThunk.checkAuth()))
            (`${serverHttp}/api/tournament/${id}`, {
                method: `GET`,
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            const data = await response.json()
            if (data?.error) {
                window.location.pathname = '/tournament/list'
                console.log(data.message)
                return
            }

            dispatch(TournamentActions.setFullTournamentAction({...data, partnerList: JSON.parse(data.partnerList)}))

        } catch (error: any) {
            console.log('error client', error)
        } finally {
            // dispatch(FetchingActions.setIsLoadingUserListAction(false))
        }
    },
    createTournamentItem: (accessCallback: Function) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            let newTournament = getState().TournamentReducer.editTournament
            if (!newTournament) return
            newTournament = {...newTournament, partnerList: JSON.stringify(newTournament.partnerList) as any}

            const newLogo = getState().TournamentReducer.newLogo

            // console.log(newTournament)

            await new Promise((resolve) => {
                setTimeout(resolve, 500)
            })

            let formData = new FormData();

            for (let field in newTournament) {
                formData.append(`${field}`, newTournament[field])
            }

            if (!!newLogo) {
                console.log(newLogo)
                formData.append(`files`, newLogo)
            }


            // @ts-ignore
            // for(let [name, value] of formData) {
                // console.log(`${name} = ${value}`) // key1=value1, потом key2=value2
            // }

            const response = await authFetch(() => dispatch(authThunk.checkAuth()))
            (`${serverHttp}/api/tournament/create`, {
                method: `POST`,
                // headers: {
                //     'Content-Type': 'multipart/form-data; boundary=',
                // },
                body: formData
            })
            const data = await response.json()
            if (data?.error) {
                console.log(data.message)
                return
            }
            accessCallback(data.id)
            dispatch(TournamentActions.setEditTournamentAction({...data, partnerList: JSON.parse(data.partnerList)}))
            // dispatch(TournamentActions.setNewTournamentAction(null))
            dispatch(TournamentActions.setNewLogoAction(null))

        } catch (error: any) {
            alert('Не удачно, повторите попытку')
            console.log('error client', error)
        } finally {
            // dispatch(FetchingActions.setIsLoadingUserListAction(false))
        }
    },
    updateTournamentItem: (accessCallback: Function) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            let newTournament = getState().TournamentReducer.editTournament
            if (!newTournament) return
            newTournament = {...newTournament, partnerList: JSON.stringify(newTournament.partnerList) as any}

            const newLogo = getState().TournamentReducer.newLogo

            // console.log(newTournament)

            await new Promise((resolve) => {
                setTimeout(resolve, 500)
            })

            let formData = new FormData();

            for (let field in newTournament) {
                formData.append(`${field}`, newTournament[field])
            }

            if (!!newLogo) {
                formData.append(`files`, newLogo)
            }


            // @ts-ignore
            // for(let [name, value] of formData) {
                // console.log(`${name} = ${value}`) // key1=value1, потом key2=value2
            // }

            const response = await authFetch(() => dispatch(authThunk.checkAuth()))
            (`${serverHttp}/api/tournament/update`, {
                method: `PUT`,
                body: formData
            })
            const data = await response.json()
            if (data?.error) {
                console.log(data.message)
                return
            }
            accessCallback(data.id)
            dispatch(TournamentActions.setEditTournamentAction({...data, partnerList: JSON.parse(data.partnerList)}))
            // dispatch(TournamentActions.setNewTournamentAction(null))
            dispatch(TournamentActions.setNewLogoAction(null))

            alert('Турнир успешно обновлён')
        } catch (error: any) {
            alert('Не удачно, повторите попытку')
            console.log('error client', error)
        } finally {
            // dispatch(FetchingActions.setIsLoadingUserListAction(false))
        }
    },
    getResultTable: (categoryId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            dispatch(TournamentActions.setResultTableAction(null))

            await new Promise((resolve) => {
                setTimeout(resolve, 500)
            })

            const response:any = await authFetch(() => dispatch(authThunk.checkAuth()))
            (`${serverHttp}/api/result/list?category=${categoryId}`, {
                method: `GET`,
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            const data = await response.json()
            if (data?.error) {
                console.log(data.message)
                return
            }

            dispatch(TournamentActions.setResultTableAction(data))

        } catch (error: any) {
            console.log('error client', error)
        } finally {
            // dispatch(FetchingActions.setIsLoadingUserListAction(false))
        }
    },
}
