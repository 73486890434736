import {AppDispatch, RootState} from "store/ReduxStore";
import {authFetch} from "lib/authFetch";
import {authThunk} from "store/auth/thunk/authThunk";
import {serverHttp} from "config/api/api";
import {PartnerActions} from "store/partner/reducer/PartnerReducer";
import {PartnerType} from "types/PartnerType";

export const partnerThunk = {

	getList: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
		try {
			// await new Promise(res => setTimeout(() => res(''), 300))

			const response = await fetch(`${serverHttp}/api/partner/get-list`, {
				method: `GET`,
				headers: {
					'Content-Type': 'application/json',
				},
			})
			const data = await response.json()
			if (data?.error) {
				console.log(data.message)
				return
			}
			dispatch(PartnerActions.setPartnerListAction(data))
		} catch (error: any) {
			console.log('error client', error)
		}
	},
	create: (params: Omit<PartnerType, 'id'|'img'> & { newLogo?: null | File }) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
		try {
			await new Promise(res => setTimeout(() => res(''), 500))

			let formData = new FormData();

			for (let field in params) {
				formData.append(`${field}`, params[field])
			}

			const response = await authFetch(() => dispatch(authThunk.checkAuth()))
			(`${serverHttp}/api/partner/create`, {
				method: `POST`,
				// headers: {
				//     'Content-Type': 'multipart/form-data; boundary=',
				// },
				body: formData
			})
			const data = await response.json()
			if (data?.error) {
				console.log(data.message)
				return
			}
			dispatch(PartnerActions.unshiftPartnerListAction(data))
		} catch (error: any) {
			console.log('error client', error)
		}
	},
	edit: (params: Omit<PartnerType, 'img'> & { newLogo?: null | File }) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
		try {
			await new Promise(res => setTimeout(() => res(''), 500))

			let formData = new FormData();

			for (let field in params) {
				formData.append(`${field}`, params[field])
			}

			const response = await authFetch(() => dispatch(authThunk.checkAuth()))
			(`${serverHttp}/api/partner/edit`, {
				method: `PUT`,
				body: formData
			})
			const data = await response.json()
			if (data?.error) {
				console.log(data.message)
				return
			}
			dispatch(PartnerActions.updatePartnerAction(data))
		} catch (error: any) {
			console.log('error client', error)
		}
	},
	delete: (partnerId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
		try {
			await new Promise(res => setTimeout(() => res(''), 500))

			const response = await authFetch(() => dispatch(authThunk.checkAuth()))
			(`${serverHttp}/api/partner/delete?partnerId=${partnerId}`, {
				method: `DELETE`,
				headers: {
					'Content-Type': 'application/json',
				},
			})
			const data = await response.json()
			if (data?.error) {
				console.log(data.message)
				return
			}
			dispatch(PartnerActions.deletePartnerAction(data))
		} catch (error: any) {
			console.log('error client', error)
		}
	},
}
