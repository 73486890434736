import s from './index.module.scss'
import {css} from "lib/customClassName";
import React, {HTMLAttributes, InputHTMLAttributes, useMemo} from "react";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;

interface PropsType extends InputHTMLAttributes<HTMLInputElement> {
    title?: string
    value: string
    modes?: Array<'maxWidth'|'center'|'dark'|'transparent'>,
    error?: string,
    change?: Function
}
export const InputText = (props: PropsType) => {
    const {title, placeholder = '', value, modes: modeProps = [''], error, change = (str: string) => {}, ...other} = props

    const modes = useMemo(() => {
        return modeProps.map(el => s[el])
    }, [modeProps])

    const changeHandler = (e:any) => {
        change(e.target.value)
    }

    return (
        <div className={css(s.InputText, ...modes)}>
            <div className={css(s.title, ...modes)}>{title}</div>
            <div className={css(s.error)}>{error}</div>
            <input placeholder={placeholder} type={"text"} value={value} className={css(s.input, ...modes)} onChange={changeHandler} {...other}/>
        </div>
    );
};
