import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProfileType } from 'types/ProfileType';
import {UserRoleType, UserType} from "types/UserType";
import {PaginationType} from "types/Pagination";

interface stateType {
    user: null | UserType
    userList: null | Array<UserType & {profile: ProfileType} & {team?: any}>
    userListPagination: PaginationType,
}
const initialState: stateType = {
    user: null,
    userList: null,
    userListPagination: {limit: 40, total: null},
}

export const slice = createSlice({
    name: 'AuthReducer',
    initialState,
    reducers: {
        setUserAction: (state, action: PayloadAction<UserType>) : void => {
            if (state.user && (state.user.id !== action.payload.id
                || state.user.login !== action.payload.login
                || JSON.stringify(state.user.role) !== JSON.stringify(action.payload.role)
                || state.user.confirmed !== action.payload.confirmed)) {
                state.user = action.payload
            }
            if (state.user === null) state.user = action.payload
            if (action.payload === null) state.user = action.payload
        },

        setUserListAction: (state, action: PayloadAction<Array<UserType & {profile: ProfileType}> | null>) : void => {
            if (action.payload === null) {
                state.userList = null
                state.userListPagination = {...state.userListPagination, total: null}
                return
            }
            state.userList = [...state.userList ?? [], ...action.payload]
        },
        setUserListPaginationAction: (state, action: PayloadAction<PaginationType>) : void => {
            state.userListPagination = action.payload
        },

        updateUserListItemAction: (state, action: PayloadAction<UserType & {profile: ProfileType}>) : void => {
            if (state.userList === null) return
            let partner = state.userList.find(el => el.id === action.payload.id);
            if (!partner) return;
            partner.login = action.payload.login
            partner.profile = action.payload.profile
            partner.blocked = action.payload.blocked
            partner.role = action.payload.role
        },
    }
})

export const AuthReducer = slice.reducer
export const AuthActions = slice.actions



