import s from './style.module.scss'
import {css} from "lib/customClassName";
import {InputText} from "view/components/InputText";
import {Button} from "view/components/button";
import {useSelector} from "react-redux";
import {getProfileSelector} from "store/profile/selector/getProfile";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useThunks} from "../../../lib/reduxHook/index";
import {profileThunk} from "../../../store/profile/thunk/profileThunk";
import InputNumber from "../../components/InputNumber/index";
import {RadioButton} from "../../components/RadioButton/index";
import Empty_img from "view/assets/images/empty_img.jpg";
import {ProfileType} from "types/ProfileType";
import {serverHttp} from "config/api/api";
import {Helmet} from "react-helmet";
import { DESCRIPTION, KEYWORDS } from "config/constants";

export const ProfilePage = () => {
    const profile = useSelector(getProfileSelector)
    const {create, update} = useThunks(profileThunk)
    const ref = useRef<HTMLDivElement>(null)

    const [isLoading, setLoading] = useState<boolean>(false)

    const [initialEditing, setInitialEditing] = useState<boolean>(false)

    const [error, setError] = useState<string>('')

    const [newLogo, setNewLogo] = useState<null | string>(null)

    const [data, setData] = useState<Omit<ProfileType, 'id'> & { newLogo: null | File }>({
        firstName: profile?.firstName ?? '',
        lastName: profile?.lastName ?? '',
        surname: profile?.surname ?? '',
        birthDate: '',
        socialMedia: profile?.socialMedia ?? '',
        sex: profile?.sex ?? '',
        city: profile?.city ?? '',
        gym: profile?.gym ?? '',
        size: profile?.size ?? 0,
        weight: profile?.weight ?? 0,
        height: profile?.height ?? 0,
        avatar: profile?.avatar ?? '',
        newLogo: null,
    })

    const [date, setDate] = useState<{ day: number, month: number, year: number, }>({
        day: Number(profile?.birthDate?.split('-')[2]) || 0,
        month: Number(profile?.birthDate?.split('-')[1]) || 0,
        year: Number(profile?.birthDate?.split('-')[0]) || 0,
    })

    useEffect(() => {
        setDate({
            day: Number(profile?.birthDate?.split('-')[2]) || 0,
            month: Number(profile?.birthDate?.split('-')[1]) || 0,
            year: Number(profile?.birthDate?.split('-')[0]) || 0,
        })
    }, [profile])

    useMemo(() => {
        setData(prev => ({...prev, birthDate: transformDate().toString()}))
        // console.log(profile?.birthDate?.split('-'))
    }, [date.day, date.month, date.year])

    useMemo(() => {
        if (data.newLogo) {
            // console.log(URL.createObjectURL(data.newLogo))
            // return URL.createObjectURL(data.newLogo)
            setNewLogo(URL.createObjectURL(data.newLogo))
        }
    }, [data.newLogo])

    useMemo(() => {
        if (ref.current !== null && !!error) {
            window.scrollTo(0, ref.current.scrollHeight)
        }
    }, [!!error])

    const CreateProfileHandler = async () => {
        if (!checkEmptyFields()) return
        if (validateFields() === false) return
        try {
            setError('')
            setLoading(true)
            await create(data)
        } catch (err) {
            setError("Ошибка создания профиля")
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const EditProfileHandler = async () => {
        if (!profile) return
        if (validateFields() === false) return
        try {
            setLoading(true)
            setInitialEditing(false)
            await update({...data, id: profile.id})
        } catch (err) {
            setError("Ошибка редактирования профиля")
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const checkEmptyFields = () => {
        for (const field in data) {
            if (field !== 'avatar' && field !== 'newLogo' && field !== 'socialMedia' && field !== 'surname' && field !== 'city' && field !== 'gym' && !data[field]) {
                setError('Заполните обязательные поля')
                return false
            }
        }
        for (const field in date) {
            if (!date[field]) {
                setError('Заполните обязательные поля')
                return false
            }
        }
        return true
    }

    const validateFields = () => {
        for (const field in date) {
            switch (field) {
                case 'day':
                    if (Number(date.day) < 1 || Number(date.day) > 31) {
                        setError('Неверно заполнена дата рождения')
                        return false
                    } else {
                        break
                    }
                case 'month':
                    if (Number(date.month) < 1 || Number(date.month) > 12) {
                        setError('Неверно заполнена дата рождения')
                        return false
                    } else {
                        break
                    }
                case 'year':
                    if (Number(date.year) < 1920 || Number(date.year) > +(new Date().getFullYear())) {
                        setError('Неверно заполнена дата рождения')
                        return false
                    } else {
                        break
                    }
            }
        }

        for (const field in data) {
            switch (field) {
                case 'firstName':
                    if (data.firstName.length < 2) {
                        setError('Неверно заполнено имя')
                        return false
                    } else {
                        break
                    }
                case 'lastName':
                    if (data.lastName.length < 2) {
                        setError('Неверно заполнена фамилия')
                        return false
                    } else {
                        break
                    }
                case 'surname':
                    if (data.surname.length && data.surname.length < 2) {
                        setError('Неверно заполнено отчество')
                        return false
                    } else {
                        break
                    }
                case 'socialMedia':
                    if (data.socialMedia.length && data.socialMedia.length < 5) {
                        setError('Неверно заполнена социальная сеть')
                        return false
                    } else {
                        break
                    }
                case 'city':
                    if (data.city.length && data.city.length < 2) {
                        setError('Неверно заполнен город')
                        return false
                    } else {
                        break
                    }
                case 'gym':
                    if (data.gym.length && data.gym.length > 35) {
                        setError('Неверно заполнен зал для занятий')
                        return false
                    } else {
                        break
                    }
                case 'size':
                    if (data.size < 10) {
                        setError('Неверно заполнен размер одежды')
                        return false
                    } else {
                        break
                    }
                case 'weight':
                    if (data.weight < 10) {
                        setError('Неверно заполнен вес')
                        return false
                    } else {
                        break
                    }
                case 'height':
                    if (data.height < 100) {
                        setError('Неверно заполнен рост')
                        return false
                    } else {
                        break
                    }

            }
        }
    }

    const changeLogoFilesHandler = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            setInitialEditing(true)
            setData(prev => ({...prev, newLogo: e.target.files[0]}))
        }
    }

    const transformDate = () => {
        return `${date.year}-${date.month}-${date.day}`
    }

    const setDayHandler = (value) => {
        setDate(prev => ({...prev, day: value}))
        setInitialEditing(true)
        setError('')
    }

    const setMonthHandler = (value) => {
        setDate(prev => ({...prev, month: value}))
        setInitialEditing(true)
        setError('')
    }

    const setYearHandler = (value) => {
        setDate(prev => ({...prev, year: value}))
        setInitialEditing(true)
        setError('')
    }

    const setFirstNameHandler = (value) => {
        setData(prev => ({...prev, firstName: value}))
        setInitialEditing(true)
        if (value === profile?.firstName) setInitialEditing(false)
        setError('')
    }

    const setLastNameHandler = (value) => {
        setData(prev => ({...prev, lastName: value}))
        setInitialEditing(true)
        if (value === profile?.lastName) setInitialEditing(false)
        setError('')
    }

    const setSurnameHandler = (value) => {
        setData(prev => ({...prev, surname: value}))
        setInitialEditing(true)
        if (value === profile?.surname) setInitialEditing(false)
        setError('')
    }

    const setSocialMediaHandler = (value) => {
        setData(prev => ({...prev, socialMedia: value}))
        setInitialEditing(true)
        if (value === profile?.socialMedia) setInitialEditing(false)
        setError('')
    }

    const setSexHandler = (value) => {
        setData(prev => ({...prev, sex: value}))
        setInitialEditing(true)
        if (value === profile?.sex) setInitialEditing(false)
        setError('')
    }

    const setCityHandler = (value) => {
        setData(prev => ({...prev, city: value}))
        setInitialEditing(true)
        if (value === profile?.city) setInitialEditing(false)
        setError('')
    }

    const setGymHandler = (value) => {
        setData(prev => ({...prev, gym: value}))
        setInitialEditing(true)
        if (value === profile?.gym) setInitialEditing(false)
        setError('')
    }

    const setSizeHandler = (value) => {
        setData(prev => ({...prev, size: +value}))
        setInitialEditing(true)
        if (value === profile?.size) setInitialEditing(false)
        setError('')
    }

    const setWeightHandler = (value) => {
        setData(prev => ({...prev, weight: +value}))
        setInitialEditing(true)
        if (value === profile?.weight) setInitialEditing(false)
        setError('')
    }

    const setHeightHandler = (value) => {
        setData(prev => ({...prev, height: +value}))
        setInitialEditing(true)
        if (value === profile?.height) setInitialEditing(false)
        setError('')
    }

    return (
        <div className={css(s.ProfilePage)}>
            <Helmet>
                <title>Forge | Профиль</title>
                <meta name="description" content={DESCRIPTION} />
                <meta name="keywords" content={KEYWORDS} />
            </Helmet>
            <div className={css(s.container)}>
                <div className={css(s.titlePage)}>
                    {profile !== null ? "Профиль" : "Создание профиля"}
                    {!profile && <span className={css(s.clue)}>* - обязательное поле</span>}
                </div>
                <div ref={ref} className={css(s.error)}>{error}</div>
                <div className={css(s.main)}>
                    <div className={css(s.form)}>
                        <InputText
                            value={data.firstName}
                            change={setFirstNameHandler}
                            modes={profile !== null ? [`maxWidth`, `transparent`] : [`maxWidth`]}
                            placeholder={'имя'}
                            title={profile !== null ? `Имя` : `Введите ваше имя *`}
                            readOnly={isLoading}/>
                        <InputText
                            value={data.lastName}
                            change={setLastNameHandler}
                            modes={profile !== null ? [`maxWidth`, `transparent`] : [`maxWidth`]}
                            placeholder={'фамилия'}
                            title={profile !== null ? `Фамилия` :`Введите вашу фамилию *`}
                            readOnly={isLoading}/>
                        <InputText
                            value={data.surname}
                            change={setSurnameHandler}
                            modes={profile !== null ? [`maxWidth`, `transparent`] : [`maxWidth`]}
                            placeholder={profile !== null ? '-' :'отчество'}
                            title={profile !== null ? `Отчество` :`Введите ваше отчество`}
                            readOnly={isLoading}/>

                        <div className={css(s.inputWrapper)}>
                            <p className={css(s.title)}>{profile !== null ? `Дата рождения (ДД-ММ-ГОД)` : 'Введите дату вашего рождения *'}</p>
                            <div className={css(s.inputs)}>
                                <InputNumber
                                    value={date.day || ''}
                                    modes={profile !== null ? ['transparent'] : []}
                                    change={setDayHandler}
                                    placeholder={'ДД'}
                                    readOnly={isLoading}/>
                                <InputNumber
                                    value={date.month || ''}
                                    change={setMonthHandler}
                                    modes={profile !== null ? ['transparent'] : []}
                                    placeholder={'ММ'}
                                    readOnly={isLoading}/>
                                <InputNumber
                                    value={date.year || ''}
                                    change={setYearHandler}
                                    modes={profile !== null ? ['transparent'] : []}
                                    placeholder={'ГОД'}
                                    readOnly={isLoading}/>
                            </div>
                        </div>

                        <InputText
                            value={data.socialMedia}
                            change={setSocialMediaHandler}
                            modes={profile !== null ? [`maxWidth`, `transparent`] : [`maxWidth`]}
                            placeholder={profile !== null ? '-' : 'СОЦИАЛЬНАЯ СЕТЬ'}
                            title={profile !== null ? `Соц. сеть для связи` :`Введите ссылку на вашу социальную сеть для связи *виден только администраторам  `}
                            readOnly={isLoading}/>
                        <RadioButton
                            title={profile !== null ? `Пол` :`Выберите ваш пол *`}
                            value={data.sex}
                            change={str => setSexHandler(str)}
                            list={[`Мужской`, `Женский`]}/>
                        <InputText
                            value={data.city}
                            change={setCityHandler}
                            modes={profile !== null ? [`maxWidth`, `transparent`] : [`maxWidth`]}
                            placeholder={profile !== null ? '-' : 'город'}
                            title={profile !== null ? `Город` :`Введите ваш город`}
                            readOnly={isLoading}/>
                        <InputText
                            value={data.gym}
                            change={setGymHandler}
                            modes={profile !== null ? [`maxWidth`, `transparent`] : [`maxWidth`]}
                            placeholder={profile !== null ? '-' : 'зал'}
                            title={profile !== null ? `Зал для занятий` :`Введите зал для занятий (свободный текст с ограничением в 35 символов)`}
                            readOnly={isLoading}/>
                        <InputNumber
                            value={data.size || ''}
                            change={setSizeHandler}
                            modes={profile !== null ? [`maxWidth`, `transparent`] : [`maxWidth`]}
                            placeholder={'размер'}
                            title={profile !== null ? `Размер одежды` :`Введите ваш размер одежды *`}
                            readOnly={isLoading}/>
                        <InputNumber
                            value={data.weight || ''}
                            change={setWeightHandler}
                            modes={profile !== null ? [`maxWidth`, `transparent`] : [`maxWidth`]}
                            placeholder={'вес'}
                            title={profile !== null ? `Вес` :`Введите ваш вес (кг) *`}
                            readOnly={isLoading}/>
                        <InputNumber
                            value={data.height || ''}
                            change={setHeightHandler}
                            modes={profile !== null ? [`maxWidth`, `transparent`] : [`maxWidth`]}
                            placeholder={'рост'}
                            title={profile !== null ? `Рост` :`Введите ваш рост (см) *`}
                            readOnly={isLoading}/>
                        <div className={css(s.sendBtnBox)}>
                            {profile === null
                                ? <Button text={isLoading ? "Сохранение..." : `Создать профиль    →`} isLoading={isLoading} modes={[`red`, `noRadius`, `maxWidth`]} disable={!!error} click={CreateProfileHandler}/>
                                : <Button text={isLoading ? "Сохранение..." : `Сохранить изменения    →`} isLoading={isLoading} modes={[`red`, `noRadius`, `maxWidth`]} disable={!initialEditing || !!error} click={EditProfileHandler}/>}
                        </div>
                    </div>
                    <div className={css(s.avatar)}>
                        <div className={css(s.imgBox)}>
                            <div className={css(s.imgBoxWrapper)}>
                                {!newLogo && profile?.avatar && <img src={`${serverHttp}/upload/` + profile?.avatar} alt="profile_avatar"/>}
                                {!newLogo && profile?.avatar.length === 0 && <img src={Empty_img} alt="profile_avatar"/>}
                                {newLogo && <img src={newLogo} alt="profile_avatar"/>}
                            </div>
                            <label className={css(s.changeFile)}>
                                <div className={css(s.fileName)}> редактировать</div>
                                <input className={css(s.inputLogo)} type="file" accept={'.png, .jpg, .jpeg, .gif'} multiple={false}
                                       onChange={changeLogoFilesHandler}/>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
