import s from './style.module.scss'
import { css } from "lib/customClassName";
import { Button } from "view/components/button";
import { useNavigate } from "react-router-dom";
import { useLayoutEffect } from "react";

export const PrivacyPolicy = () => {
    const navigate = useNavigate()

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    },[])

    return (
        <div className={ css(s.PrivacyPolicy) }>
            <Button text={`←    Назад`} modes={[`mobilSmall`]} click={()=>navigate(-1)}/>
            <h1><strong>Политика конфиденциальности</strong></h1>
                {/*<p>&nbsp;</p>*/}
                <ol>
                    <li>ОБЩИЕ ПОЛОЖЕНИЯ</li>
                </ol>
                <p>1.1.&nbsp;&nbsp; Настоящий документ определяет политику ООО "КузницаСпорта" (далее – Компания) в отношении обработки персональных данных и излагает систему основных принципов, применяемых в отношении обработки персональных данных в Компании.</p>
                <p>1.2&nbsp; Действие настоящей Политики распространяется на все процессы по сбору, записи, систематизации, накоплению, хранению, уточнению, извлечению, использованию, передачи (распространению, предоставлению, доступу), обезличиванию, блокированию, удалению, уничтожению персональных данных, осуществляемых с использованием средств автоматизации и без использования таких средств.</p>
                <p>1.3 Настоящая Политика обязательна для ознакомления и исполнения всеми лицами, допущенными к обработке персональных данных в Компании и лицами, участвующими в организации процессов обработки и обеспечения безопасности персональных данных в Компании.</p>
                <p>1.4 Настоящая Политика составлена в соответствии с Федеральным законом РФ «О персональных данных» №152-ФЗ от 27 июля 2006 г.</p>
                <p>1.5 Настоящая Политика подлежит актуализации в случае изменения законодательства РФ о персональных данных.</p>
            <p>&nbsp;</p>
                <ol >
                    <li>ПРИНЦИПЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</li>
                </ol>
                <p>2.1. Обработка персональных данных осуществляется на основе следующих принципов:</p>
                <p>2.1.1. Обработка персональных данных осуществляется на законной и справедливой основе;</p>
                <p>2.1.2. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных;</p>
                <p>2.1.3. Не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместных между собой;</p>
                <p>2.1.4. Обработке подлежат только те персональные данные, которые отвечают целям их обработки;</p>
                <p>2.1.5. Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки. Обрабатываемые персональные данные не являются избыточными по отношению к заявленным целям обработки;</p>
                <p>2.1.6. При обработке персональных данных обеспечивается точность персональных данных, их достаточность, а в необходимых случаях и актуальность по отношению к заявленным целям их обработки.</p>
                <p>2.1.7. Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных. Обрабатываемые персональные данные подлежат уничтожению, либо обезличиванию по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.</p>
            <p>&nbsp;</p>
                <ol >
                    <li>УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</li>
                </ol>
                <p>3.1 Обработка персональных данных осуществляется с соблюдением принципов и правил, установленных Федеральным законом «О персональных данных». Обработка персональных данных допускается в следующих случаях:</p>
                <p>3.1.1. Обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных;</p>
                <p>3.1.2. Обработка персональных данных необходима для достижения целей, предусмотренных международным договором Российской Федерации или законом, для осуществления и выполнения возложенных законодательством Российской Федерации на оператора функций, полномочий и обязанностей;</p>
                <p>3.1.3. Обработка персональных данных необходима для осуществления правосудия, исполнения судебного акта, акта другого органа или должностного лица, подлежащих исполнению в соответствии с законодательством Российской Федерации об исполнительном производстве;</p>
                <p>3.1.4. Обработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект персональных данных, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или поручителем;</p>
                <p>3.1.5. Обработка персональных данных необходима для защиты жизни, здоровья или иных жизненно важных интересов субъекта персональных данных, если получение согласия субъекта персональных данных невозможно;</p>
                <p>3.1.6. Обработка персональных данных необходима для осуществления прав и законных интересов оператора или третьих лиц, либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы субъекта персональных данных;</p>
                <p>3.1.7. Обработка персональных данных осуществляется в статистических или иных исследовательских целях, при условии обязательного обезличивания персональных данных. Исключение составляет обработка персональных данных в целях продвижения товаров, работ, услуг на рынке путем осуществления прямых контактов с потенциальным потребителем с помощью средств связи, а также в целях политической агитации;</p>
                <p>3.1.8. Осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных, либо по его просьбе (далее – персональные данные, сделанные общедоступными субъектом персональных данных);</p>
                <p>3.1.9. Осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с федеральным законом.</p>
                <p>3.2. Компания может включать персональные данные субъектов в общедоступные источники персональных данных, при этом Компания берет письменное согласие субъекта на обработку его персональных данных.</p>
                <p>3.3. Компания может осуществлять обработку специальных категорий персональных данных, касающихся расовой, национальной принадлежности, состояния здоровья, при этом Компания обязуется брать письменное согласие субъекта на обработку его персональных данных</p>
                <p>3.4. Биометрические персональные данные (сведения, которые характеризуют физиологические и биологические особенности человека, на основании которых можно установить его личность и которые используются оператором для установления личности субъекта персональных данных) в Компании не обрабатываются.</p>
                <p>3.5. Принятие на основании исключительно автоматизированной обработки персональных данных решений, порождающих юридические последствия в отношении субъекта персональных данных или иным образом затрагивающих его права и законные интересы, не осуществляется.</p>
                <p>3.6. В условиях лицензии на осуществление деятельности Компании отсутствует запрет на передачу персональных данных третьим лицам без согласия в письменной форме субъекта персональных данных.</p>
                <p>3.7. При отсутствии необходимости письменного согласия субъекта на обработку его персональных данных согласие субъекта может быть дано субъектом персональных данных или его представителем в любой позволяющей получить факт его получения форме.</p>
                <p>3.8. Компания вправе поручить обработку персональных данных другому лицу с согласия субъекта персональных данных, если иное не предусмотрено федеральным законом, на основании заключаемого с этим лицом договора (далее – поручение оператора). При этом Компания в договоре обязует лицо, осуществляющее обработку персональных данных по поручению Компании, соблюдать принципы и правила обработки персональных данных, предусмотренные настоящим Федеральным законом.</p>
                <p>3.9. В случае если Компания поручает обработку персональных данных другому лицу, ответственность перед субъектом персональных данных за действия указанного лица несет Компания. Лицо, осуществляющее обработку персональных данных по поручению Компании, несет ответственность перед Компанией.</p>
                <p>3.10. Компания обязуется и обязует иные лица, получившие доступ к персональным данным, не раскрывать третьим лицам и не распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено федеральным законом.</p>
            <p>&nbsp;</p>
                <ol >
                    <li>ОБЯЗАННОСТИ КОМПАНИИ</li>
                </ol>
                <p>4.1. В соответствии с требованиями Федерального закона № 152-ФЗ «О персональных данных» Компания обязана:</p>
                <p>4.1.1. Предоставлять субъекту персональных данных по его запросу информацию, касающуюся обработки его персональных данных, либо на законных основаниях предоставить отказ.</p>
                <p>4.1.2. По требованию субъекта персональных данных уточнять обрабатываемые персональные данные, блокировать или удалять, если персональных данных являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки.</p>
                <p>4.1.3. Вести Журнал учета обращений субъектов персональных данных, в котором должны фиксироваться запросы субъектов персональных данных на получение персональных данных, а также факты предоставления персональных данных по этим запросам.</p>
                <p>4.1.4. Уведомлять субъекта персональных данных об обработке персональных данных в том случае, если персональные данные были получены не от субъекта персональных данных. Исключение составляют следующие случаи:</p>
                <p>4.1.4.1. Субъект ПДн уведомлен об осуществлении обработки его ПДн соответствующим оператором;</p>
                <p>4.1.4.2. &nbsp;ПДн получены Компанией на основании федерального закона или в связи с исполнением договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект ПДн;</p>
                <p>4.1.4.3. &nbsp;ПДн сделаны общедоступными субъектом ПДн или получены из общедоступного источника;</p>
                <p>4.1.4.4. &nbsp;Компания осуществляет обработку ПД для статистических или иных исследовательских целей, для осуществления профессиональной деятельности журналиста либо научной, литературной или иной творческой деятельности, если при этом не нарушаются права и законные интересы субъекта ПДн;</p>
                <p>4.1.4.5. &nbsp;Предоставление субъекту ПД сведений, содержащихся в Уведомлении об обработке ПД нарушает права и законные интересы третьих лиц.</p>
                <p>4.2. &nbsp;В случае достижения цели обработки персональных данных незамедлительно прекратить обработку персональных данных и уничтожить соответствующие персональные данные в срок, не превышающий тридцати дней с даты достижения цели обработки персональных данных, если иное не предусмотрено договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных, иным соглашением между Компанией и субъектом персональных данных либо если Компания не вправе осуществлять обработку персональных данных без согласия субъекта персональных данных на основаниях, предусмотренных №152-ФЗ «О персональных данных» или другими федеральными законами.</p>
                <p>4.3. &nbsp;В случае отзыва субъектом персональных данных согласия на обработку своих персональных данных прекратить обработку персональных данных и уничтожить персональные данные в срок, не превышающий тридцати дней с даты поступления указанного отзыва, если иное не предусмотрено соглашением между Компанией и субъектом персональных данных. Об уничтожении персональных данных Компания обязана уведомить субъекта персональных данных.</p>
                <p>4.4. &nbsp;В случае поступления требования субъекта о прекращении обработки персональных данных в целях продвижения товаров, работ, услуг на рынке немедленно прекратить обработку персональных данных.</p>
                <p>&nbsp;</p>
                <ol >
                    <li>МЕРЫ ПО ОБЕСПЕЧЕНИЮ БЕЗОПАСНОСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ ПРИ ИХ ОБРАБОТКЕ</li>
                </ol>
                <p>5.1. При обработке персональных данных Компания принимает необходимые правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных.</p>
                <p>5.2 Обеспечение безопасности персональных данных достигается, в частности:</p>
                <p>5.2.1. определением угроз безопасности персональных данных при их обработке в информационных системах персональных данных;</p>
                <p>5.2.2. применением организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных, необходимых для выполнения требований к защите персональных данных, исполнение которых обеспечивает установленные Правительством Российской Федерации уровни защищенности персональных данных;</p>
                <p>5.2.3. применением прошедших в установленном порядке процедуру оценки соответствия средств защиты информации;</p>
                <p>5.2.4. оценкой эффективности принимаемых мер по обеспечению безопасности персональных данных до ввода в эксплуатацию информационной системы персональных данных;</p>
                <p>5.2.5. учетом машинных носителей персональных данных;</p>
                <p>5.2.6. обнаружением фактов несанкционированного доступа к персональным данным и принятием мер;</p>
                <p>5.2.7. восстановлением персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;</p>
                <p>5.2.8. установлением правил доступа к персональным данным, обрабатываемым в информационной системе персональных данных, а также обеспечением регистрации и учета всех действий, совершаемых с персональными данными в информационной системе персональных данных;</p>
                <p>5.2.9. контролем за принимаемыми мерами по обеспечению безопасности персональных данных и уровня защищенности информационных систем персональных данных.</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
        </div>
    );
};
