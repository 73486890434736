import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ResultType} from "types/ResultType";
import {PaginationType} from "types/Pagination";

interface stateType {
    resultItems: { [key: string]: ResultType }
    adminResultList: Array<ResultType> | null
    adminResultListPagination: PaginationType,
    confirmResult: ResultType | null
}
const initialState: stateType = {
    resultItems: {},
    adminResultList: null,
    adminResultListPagination: {limit: 50, total: null},
    confirmResult: null,
}

export const slice = createSlice({
    name: 'ResultReducer',
    initialState,
    reducers: {
        addResultItemsAction: (state, action: PayloadAction<ResultType>) : void => {
            state.resultItems[`${action.payload.taskId}`] = {...action.payload}
        },
        changeResultByIdAction: (state, action: PayloadAction<ResultType>) : void => {
            state.resultItems[`${action.payload.taskId}`] = {...action.payload, value: String(action.payload.value)}
        },
        changeAdminResultByIdAction: (state, action: PayloadAction<ResultType>) : void => {
            if (!state.adminResultList) return
            state.adminResultList = state.adminResultList?.map(el => el.id === action.payload.id ? {...el,...action.payload} : el)
        },

        setAdminResultListAction: (state, action: PayloadAction<Array<ResultType> | null>) : void => {
            if (action.payload === null) {
                state.adminResultList = null
                state.adminResultListPagination = {...state.adminResultListPagination, total: null}
                return
            }
            state.adminResultList = [...state.adminResultList ?? [], ...action.payload]
        },
        setAdminResultListPaginationAction: (state, action: PayloadAction<PaginationType>) : void => {
            state.adminResultListPagination = action.payload
        },

        setConfirmResultAction: (state, action: PayloadAction<ResultType | null>) : void => {
            state.confirmResult = action.payload
        },
        setConfirmResultCommentAction: (state, action: PayloadAction<string>) : void => {
            if (!state.confirmResult) return
            state.confirmResult.adminComment = action.payload
        },
        // updateTaskItemAction: (state, action: PayloadAction<TaskType>) : void => {
        //     state.taskList = state.taskList?.map(el => el.id === action.payload.id ? action.payload : el) ?? []
        // },
        // setEditTaskAction: (state, action: PayloadAction<TaskType | null>) : void => {
        //     state.editTask = action.payload
        // },
    }
})

export const ResultReducer = slice.reducer
export const ResultActions = slice.actions



