import {AppDispatch, RootState} from "store/ReduxStore";
import {authFetch} from "lib/authFetch";
import {authThunk} from "store/auth/thunk/authThunk";
import {serverHttp} from "config/api/api";
import {ProfileActions} from '../reducer/ProfileReducer';
import {ProfileType} from "types/ProfileType";

export const profileThunk = {
	getMyProfile: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
		try {
			// // await new Promise(res => setTimeout(() => res(''), 1000))

			const response = await authFetch(() => dispatch(authThunk.checkAuth()))
			(`${serverHttp}/api/profile`, {
				method: `GET`,
				headers: {
					'Content-Type': 'application/json',
				},
			})

			const data = await response.json()

			if (data?.error) {
				console.log(data.message)
				dispatch(ProfileActions.setMyProfileAction(null))
				return
			}

			dispatch(ProfileActions.setMyProfileAction(data))

		} catch (error: any) {
			console.log('error client', error)
		}
	},
	create: (params: Omit<ProfileType, 'id'> & { newLogo: null | File }) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
		try {
			// await new Promise(res => setTimeout(() => res(''), 1000))

			let formData = new FormData();

			for (let field in params) {
				formData.append(`${field}`, params[field])
			}

			const response = await authFetch(() => dispatch(authThunk.checkAuth()))
			(`${serverHttp}/api/profile/create`, {
				method: `POST`,
				// headers: {
				//     'Content-Type': 'multipart/form-data; boundary=',
				// },
				body: formData
			})
			const data = await response.json()
			if (data?.error) {
				console.log(data.message)
				return
			}
			dispatch(ProfileActions.setMyProfileAction(data))
		} catch (error: any) {
			console.log('error client', error)
		}
	},
	update: (params: ProfileType & { newLogo: null | File }) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
		try {
			// await new Promise(res => setTimeout(() => res(''), 1000))

			let formData = new FormData();



			for (let field in params) {
				formData.append(`${field}`, params[field])
			}

			const response = await authFetch(() => dispatch(authThunk.checkAuth()))
			(`${serverHttp}/api/profile/update`, {
				method: `PUT`,
				// headers: {
				//     'Content-Type': 'multipart/form-data; boundary=',
				// },
				body: formData
			})

			const data = await response.json()
			if (data?.error) {
				console.log(data.message)
				return
			}
			dispatch(ProfileActions.setMyProfileAction(data))
		} catch (error: any) {
			console.log('error client', error)
		}
	}
}
