import {createBrowserRouter, Navigate} from "react-router-dom";
import {AppLayout} from "view/layout/AppLayout";
import {IsRequireHOC} from "routing/isRequireHOC";
import {TournamentLayout} from "view/layout/TournamentLayout";
import {RulesPage} from "view/pages/RulesPage";
import {TournamentListPage} from "view/pages/TournamentListPage";
import {TournamentPage} from "view/pages/TournamentPage";
import {PrizePage} from "view/pages/PrizePage";
import {SelectCategory} from "view/pages/SelectCategory";
import {AddResultPage} from "view/pages/addResultPage";
import {ProfilePage} from "view/pages/ProfilePage";
import {AdminLayout} from "view/layout/AdminLayout";
import {UserPage} from "view/pages/AdminPage/userPage";
import {AdminTournamentList} from "view/pages/AdminPage/tournamentList";
import {PartnerPage} from "view/pages/AdminPage/partnerPage";
import {EditTournament} from "view/pages/AdminPage/editTournament";
import {CategoryList} from "view/pages/AdminPage/categoryList";
import {ResultPage} from "view/pages/AdminPage/resultPage";
import {StatisticPage} from "view/pages/AdminPage/statisticPage";
import {AddResultItemPage} from "view/pages/AddResultItemPage";
import {ChangePasswordPage} from "view/pages/ChangePasswordPage";
import {ConfirmEmailPage} from "view/pages/ConfirmEmailPage";
import {CategoryListLayout} from "view/layout/CategoryListLayout";
import {EditCategoryForm} from "view/module/admin/editCategoryForm";
import {EditTaskForm} from "view/module/admin/editTaskForm";
import {RemindPasswordPage} from "view/pages/RemindPasswordPage";
import { TournamentUserPage } from "view/pages/AdminPage/tournamentUserPage";
import { BigTaskItem } from "view/components/bigTaskItem";
import { PublicOffer } from "view/pages/PublicOffer";
import { PrivacyPolicy } from "view/pages/PrivacyPolicy";
import { Cookies } from "view/pages/Cookies";
import { ManagerLayout } from "view/layout/ManagerLayout";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <IsRequireHOC permissionRoles={['regular', 'guest', 'manager', 'admin']}>
                    <AppLayout />
                </IsRequireHOC>
            ,
        errorElement: <Navigate to={`/`} />,
        children: [
            {
                path: "profile",
                element: <IsRequireHOC
                    permissionRoles={['regular','admin']}
                    permissionElement={<Navigate to={'/'} replace={true}/>}
                >
                    <ProfilePage />
                </IsRequireHOC>,
                errorElement: <Navigate to={`/`} />,
            },
            {
                path: "tournament",
                element: <IsRequireHOC permissionRoles={['regular', 'guest', 'admin']}>
                    <TournamentLayout />
                </IsRequireHOC>,
                errorElement: <Navigate to={`/`} />,
                children: [
                    {
                        path: ":id",
                        element: <TournamentPage />,
                        errorElement: <Navigate to={`/`} />,
                    },
                    {
                        path: "rules/:id",
                        element: <RulesPage />,
                        errorElement: <Navigate to={`/`} />,
                    },
                    {
                        path: "prize/:id",
                        element: <PrizePage />,
                        errorElement: <Navigate to={`/`} />,
                    },
                    {
                        path: "select/:id",
                        element: <SelectCategory />,
                        errorElement: <Navigate to={`/`} />,
                    },
                    {
                        path: "add/:id",
                        element: <AddResultPage />,
                        errorElement: <Navigate to={`/`} />,
                    },
                    {
                        path: "add-item/:id",
                        element: <AddResultItemPage />,
                        errorElement: <Navigate to={`/`} />,
                    },
                    {
                        path: "task-item/:taskId",
                        element: <BigTaskItem />,
                        errorElement: <Navigate to={`/`} />,
                    },
                    {
                        path: "",
                        element: <Navigate to={`/tournament/list`} />,
                        errorElement: <Navigate to={`/`} />,
                    },
                ]
            },
            {
                path: "tournament/list",
                element: <TournamentListPage />,
                errorElement: <Navigate to={`/`} />,
            },
            {
                path: "admin",
                element: <IsRequireHOC
                    permissionRoles={['admin']}
                    permissionElement={<Navigate to={'/'} replace={true}/>}
                >
                    <AdminLayout />
                </IsRequireHOC>,
                errorElement: <Navigate to={`/`} />,
                children: [
                    {
                        path: "tournament",
                        element: <AdminTournamentList />,
                        errorElement: <Navigate to={`/`} />,
                    },
                    {
                        path: "tournament/edit/:id",
                        element: <EditTournament />,
                        errorElement: <Navigate to={`/`} />,
                    },
                    {
                        path: "tournament/edit",
                        element: <EditTournament />,
                        errorElement: <Navigate to={`/`} />,
                    },
                    {
                        path: "tournament/category-list/:id",
                        element: <CategoryListLayout />,
                        errorElement: <Navigate to={`/`} />,
                        children: [
                            {
                                path: "",
                                element: <CategoryList />,
                                errorElement: <Navigate to={`/`} />,
                            },
                            {
                                path: "edit",
                                element: <EditCategoryForm />,
                                errorElement: <Navigate to={`/`} />,
                            },
                            {
                                path: "edit-task",
                                element: <EditTaskForm />,
                                errorElement: <Navigate to={`/`} />,
                            },
                        ]
                    },
                    {
                        path: "result",
                        element: <ResultPage />,
                        errorElement: <Navigate to={`/`} />,
                    },
                    {
                        path: "tournament-user",
                        element: <TournamentUserPage />,
                        errorElement: <Navigate to={`/`} />,
                    },
                    {
                        path: "user",
                        element: <UserPage />,
                        errorElement: <Navigate to={`/`} />,
                    },
                    {
                        path: "statistic",
                        element: <StatisticPage />,
                        errorElement: <Navigate to={`/`} />,
                    },
                    {
                        path: "partner",
                        element: <PartnerPage />,
                        errorElement: <Navigate to={`/`} />,
                    },
                    {
                        path: "",
                        element: <Navigate to={`tournament`} />,
                        errorElement: <Navigate to={`/`} />,
                    },
                ]
            },
            {
                path: "manager",
                element: <IsRequireHOC
                    permissionRoles={['manager']}
                    permissionElement={<Navigate to={'/'} replace={true}/>}
                >
                    <ManagerLayout />
                </IsRequireHOC>,
                errorElement: <Navigate to={`/`} />,
                children: [
                    {
                        path: "result",
                        element: <ResultPage />,
                        errorElement: <Navigate to={`/`} />,
                    },
                    {
                        path: "tournament-user",
                        element: <TournamentUserPage />,
                        errorElement: <Navigate to={`/`} />,
                    },
                    {
                        path: "",
                        element: <Navigate to={`result`} />,
                        errorElement: <Navigate to={`/`} />,
                    },
                ]
            },

            {
                path: "change-password",
                element: <IsRequireHOC
                    permissionRoles={['regular','admin']}
                    permissionElement={<Navigate to={'/'} replace={true}/>}
                >
                    <ChangePasswordPage />
                </IsRequireHOC>,
                errorElement: <Navigate to={`/`} />,
            },
            {
                path: "password_remind",
                element: <IsRequireHOC
                    permissionRoles={['guest']}
                    permissionElement={<Navigate to={'/'} replace={true}/>}
                >
                    <RemindPasswordPage />
                </IsRequireHOC>,
                errorElement: <Navigate to={`/`} />,
            },
            {
                path: "confirmation/:token/:email",
                element: <ConfirmEmailPage />,
                errorElement: <Navigate to={`/`} />,
            },
            {
                path: 'public-offer',
                element: <PublicOffer />,
                errorElement: <Navigate to={`/`} />,
            },
            {
                path: 'privacy-policy',
                element: <PrivacyPolicy />,
                errorElement: <Navigate to={`/`} />,
            },
            {
                path: 'cookies',
                element: <Cookies />,
                errorElement: <Navigate to={`/`} />,
            },
            {
                path: "",
                element: <Navigate to={`/tournament`} />,
                errorElement: <Navigate to={`/`} />,
            },
            // {
            //     path: "confirmation/:token/:email",
            //     element:  <ConfirmEmailPage />,
            //     errorElement: <Navigate to={`/`} />,
            // },
        ]
    },

]);

