import s from './style.module.scss'
import { css } from "lib/customClassName";
import { Button } from "view/components/button";
import { useSelector } from "react-redux";
import { getStatisticListSelector } from "store/statistic/selector/getStatisticList";
import { getStatisticListPaginationSelector } from "store/statistic/selector/getStatisticListPagination";
import { useEffect, useRef, useState } from "react";
import { UserType } from "types/UserType";
import { ProfileType } from "types/ProfileType";
import { useActions, useThunks } from "lib/reduxHook";
import { authThunk } from "store/auth/thunk/authThunk";
import { getTournamentListPaginationSelector } from "store/tournament/selector/getTournamentListPagination";
import { authFetch } from "lib/authFetch";
import { serverHttp } from "config/api/api";
import { useClickOutside } from "lib/useClickOutside";
import loadGif from "view/assets/images/Load.gif";
import { StatisticActions } from "store/statistic/reducer/statisticReducer";
import { statisticThunk } from "store/statistic/thunk/authThunk";
import { getAllPriceSelector } from "store/statistic/selector/getAllPrice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const StatisticPage = () => {

    const statisticList = useSelector(getStatisticListSelector)

    const statisticListPagination = useSelector(getStatisticListPaginationSelector)

    const allPrice = useSelector(getAllPriceSelector)

    const [ isLoaderMoreBtn, setIsLoaderMoreBtn ] = useState(false)

    const [ currentTournament, setCurrentTournament ] = useState<any>(null)
    const [ currentCategory, setCurrentCategory ] = useState<any>(null)
    const [ startDate, setStartDate ] = useState<any>(null)
    const [ endDate, setEndDate ] = useState<any>(null)

    const [ showSelectBox, setShowSelectBox ] = useState<boolean>(false)

    const { setStatisticListAction } = useActions(StatisticActions)

    const { getStatisticList } = useThunks(statisticThunk)

    useEffect(() => {

        if ((startDate !== null && endDate !== null) || (startDate === null && endDate === null)) {
            getStatisticList(startDate, endDate, currentTournament?.id, currentCategory?.id)
            return () => {
                setStatisticListAction(null)
            }
        }

    }, [ currentTournament, currentCategory, startDate, endDate ])

    useEffect(() => {
        if (!showSelectBox && !!startDate && !endDate) {
            setEndDate(new Date())
        }

    }, [ showSelectBox ])

    const handleMoreUser = async () => {
        setIsLoaderMoreBtn(true)
        await getStatisticList(startDate, endDate,currentTournament?.id, currentCategory?.id)
        setIsLoaderMoreBtn(false)
    }

    const speed = useRef(2)
    const table = useRef<any>(null)
    const [ drag, setDrag ] = useState(false)
    const left = useRef(0)
    const coorX = useRef(0)

    const moveHandler = (e: any) => {
        if (!table?.current) return

        if (e.buttons === 1) {
            setDrag(true)
            if (window?.getSelection) {
                window?.getSelection()?.removeAllRanges();
            }
            table.current.scrollLeft = left.current - (e.pageX - table.current.offsetLeft - coorX.current) * speed.current;
        }
    }

    const onChange = (dates) => {
        const [ start, end ] = dates;
        setStartDate(start);
        setEndDate(end);
        if (!start !== null && end !== null) {
            setShowSelectBox(false)
        }
    };

    const onClearHandler = (e) => {
        e.stopPropagation()
        setShowSelectBox(false)
        setStartDate(null);
        setEndDate(null);
    };

    useClickOutside(() => setShowSelectBox(false), [ `dateBtn`, `dateBtnSVG`, `datePickerBox` ])

    return (
        <div className={ css(s.StatisticPage) }>
            <div className={ css(s.head) }>
                Статистика
            </div>
            <div className={ css(s.selectBox) }>
                <SelectTournament currentTournament={ currentTournament }
                                  setCurrentTournament={ setCurrentTournament }
                                  setCurrentCategory={ setCurrentCategory }/>
                { currentTournament !== null &&
                    <SelectCategory currentTournament={ currentTournament } currentCategory={ currentCategory }
                                    setCurrentCategory={ setCurrentCategory }/> }
            </div>
            <div className={ css(s.dateBox) }>
                <div className={ css(s.title) }>Выбрать даты</div>
                <div className={ css(s.currentTournament, showSelectBox && s.active) }>
                    <div id={ `dateBtn` } className={ css(s.name) }
                         onClick={ () => setShowSelectBox(prev => !prev) }>
                        { (startDate === null && endDate === null) ? `Всё время` : `${ startDate?.toLocaleDateString('ru') ?? '' } - ${ endDate?.toLocaleDateString('ru') ?? new Date().toLocaleDateString('ru') }` }
                        {(startDate !== null || endDate !== null) && <div className={css(s.clearDate)} onClick={onClearHandler}><b>&#10006;</b></div>}
                    </div>
                    <div id={ `dateBtnSVG` } className={ css(s.showSelectBtn) }
                         onClick={ () => setShowSelectBox(prev => !prev) }>
                        <svg width="30" height="30" viewBox="0 0 12 8" xmlns="https://www.w3.org/2000/svg">
                            <path
                                d="M5.59021 7.15047L0.452036 2.0122C0.333101 1.89336 0.267578 1.73471 0.267578 1.56556C0.267578 1.3964 0.333101 1.23776 0.452036 1.11891L0.83034 0.740515C1.07685 0.494289 1.47749 0.494289 1.72363 0.740515L6.03836 5.05525L10.3579 0.735728C10.4768 0.616886 10.6354 0.55127 10.8044 0.55127C10.9737 0.55127 11.1322 0.616886 11.2513 0.735728L11.6295 1.11413C11.7484 1.23306 11.8139 1.39161 11.8139 1.56077C11.8139 1.72993 11.7484 1.88857 11.6295 2.00741L6.48659 7.15047C6.36728 7.26959 6.20798 7.33502 6.03864 7.33464C5.86864 7.33502 5.70943 7.26959 5.59021 7.15047Z"/>
                        </svg>
                    </div>
                    { showSelectBox &&
                        <div id={ `datePickerBox` } className={ css(s.datePickerBox) }>

                            <DatePicker
                                selected={ startDate }
                                onChange={ onChange }
                                startDate={ startDate }
                                endDate={ endDate }
                                selectsRange
                                inline
                            />
                        </div> }
                </div>
            </div>
            <div className={ css(s.allPrice) }>
                Всего: <span>{ allPrice ?? '...' }</span>руб.
            </div>
            { statisticList === null && <div className={ css(s.loadingList) }>
                <img src={ loadGif } alt="load"/>
                Загрузка...
            </div> }

            { statisticList !== null && <div className={ css(s.list, drag && s.drag) }
                                             ref={ table }
                                             onMouseMove={ moveHandler }
                                             onSelect={ e => e.stopPropagation() }
                                             onMouseDown={ e => {
                                                 coorX.current = e.pageX - table.current.offsetLeft;
                                                 // drag.current = true
                                             } }
                                             onMouseUp={ e => {
                                                 left.current = table.current.scrollLeft;
                                                 // drag.current = false
                                                 setDrag(false)
                                             } }
                                             onScroll={ (e:any) => {
                                                 if (!drag) {
                                                     left.current = table.current.scrollLeft;
                                                 }
                                             } }
            >
                { statisticList !== null && !statisticList.length && <div className={ css(s.emptyList) }>
                    Список пуст
                </div> }
                { statisticList !== null && !!statisticList.length && <div className={ css(s.header) }>
                    <div className={ css(s.field, s.name) }>ФИО</div>
                    <div className={ css(s.field) }>Почта</div>
                    <div className={ css(s.field) }>Турнир</div>
                    <div className={ css(s.field) }>Категория</div>
                    <div className={ css(s.field) }>Сумма</div>
                    <div className={ css(s.field) }>Дата</div>
                </div> }
                { statisticList?.map(statistic => <StatisticItem key={ statistic.id } statistic={ statistic }/>) }
                { statisticList !== null && statisticList.length < (statisticListPagination.total ? statisticListPagination.total : 0) &&
                    <div className={ css(s.btnBox) }>
                        <Button text={ 'показать больше' } modes={ [ `uppercase`, 'maxWidth', `mobilSmall` ] }
                                isLoading={ isLoaderMoreBtn } click={ handleMoreUser }/>
                    </div> }
            </div> }
        </div>
    );
};

export const StatisticItem = (props: any) => {
    const { statistic } = props

    const [ isLoad, setIsLoad ] = useState(false)

    console.log(statistic)
    return (
        <div className={ css(s.StatisticItem) }>
            <div className={ css(s.main) }>
                <div
                    className={ css(s.field, s.name) }>{ statistic.user.profile.lastName }{ statistic.user.profile.firstName }</div>
                <div className={ css(s.field) }>{ statistic.user.login }</div>
                <div className={ css(s.field) }>{ statistic.tournament.name }</div>
                <div className={ css(s.field) }>{ statistic.category?.name }</div>
                <div className={ css(s.field) }>{ statistic.price } руб</div>
                <div
                    className={ css(s.field) }>{ new Date(Number(statistic.accessDate)).toLocaleDateString('ru') }</div>
            </div>
        </div>
    );
};

export const SelectTournament = ({ currentTournament, setCurrentTournament, setCurrentCategory }: any) => {

    const [ showSelectBox, setShowSelectBox ] = useState<boolean>(false)

    const { limit } = useSelector(getTournamentListPaginationSelector)

    const [ isLoaderMoreBtn, setIsLoaderMoreBtn ] = useState(false)

    const [ tournamentList, setTournamentList ] = useState<any>(null)
    const [ totalTournamentList, setTotalTournamentList ] = useState<any>(null)

    const getList = async () => {
        const countList = tournamentList !== null ? tournamentList?.length : 0

        await new Promise((resolve) => {
            setTimeout(resolve, 300)
        })

        const response = await authFetch(() => authThunk.checkAuth())
        (`${ serverHttp }/api/tournament/list?limit=${ limit }&offset=${ countList }`, {
            method: `GET`,
            headers: {
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()
        if (data?.error) {
            console.log(data.message)
            return
        }

        const newRow = [ ...tournamentList ?? [], ...data.rows ]

        setTournamentList(newRow)
        setTotalTournamentList(data.count)
    }

    useEffect(() => {
        if (showSelectBox) {
            getList()
        }
        return () => {
            setTournamentList(null)
            setTotalTournamentList(null)
        }
    }, [ showSelectBox ])

    useClickOutside(() => setShowSelectBox(false), [ `currentTournament`, `MoreBtn` ])

    const handleSelectTournament = (e: any, tournament: any) => {
        e.stopPropagation()
        if (tournament === null) {
            setCurrentCategory(null)
        }
        setCurrentTournament(tournament)
        setShowSelectBox(false)
    }

    const handleMoreTournament = async (e) => {
        e.stopPropagation()
        setIsLoaderMoreBtn(true)
        await getList()
        setIsLoaderMoreBtn(false)
    }

    return (
        <div className={ css(s.SelectTournament) }>
            <div className={ css(s.title) }>Выбрать турнир</div>
            <div id={ `currentTournament` } className={ css(s.currentTournament, showSelectBox && s.active) }
                 onClick={ () => setShowSelectBox(prev => !prev) }>
                <div
                    className={ css(s.name) }>{ currentTournament === null ? `Не выбран` : currentTournament.name }</div>
                <div className={ css(s.showSelectBtn) }>
                    <svg width="30" height="30" viewBox="0 0 12 8" xmlns="https://www.w3.org/2000/svg">
                        <path
                            d="M5.59021 7.15047L0.452036 2.0122C0.333101 1.89336 0.267578 1.73471 0.267578 1.56556C0.267578 1.3964 0.333101 1.23776 0.452036 1.11891L0.83034 0.740515C1.07685 0.494289 1.47749 0.494289 1.72363 0.740515L6.03836 5.05525L10.3579 0.735728C10.4768 0.616886 10.6354 0.55127 10.8044 0.55127C10.9737 0.55127 11.1322 0.616886 11.2513 0.735728L11.6295 1.11413C11.7484 1.23306 11.8139 1.39161 11.8139 1.56077C11.8139 1.72993 11.7484 1.88857 11.6295 2.00741L6.48659 7.15047C6.36728 7.26959 6.20798 7.33502 6.03864 7.33464C5.86864 7.33502 5.70943 7.26959 5.59021 7.15047Z"/>
                    </svg>
                </div>
                { showSelectBox && <div className={ css(s.tournamentList) }>
                    <div className={ css(s.item) } onClick={ (e) => handleSelectTournament(e, null) }>Не выбран</div>
                    { tournamentList?.map(el => <div key={ el.id } className={ css(s.item) }
                                                     onClick={ (e) => handleSelectTournament(e, el) }>
                        { el.name }
                    </div>) }
                    { tournamentList === null && <div className={ css(s.loadingList) }>
                        <img src={ loadGif } alt="load"/>
                        Загрузка...
                    </div> }
                    { tournamentList !== null && tournamentList.length < (totalTournamentList ? totalTournamentList : 0) &&
                        <div id={ `MoreBtn` } className={ css(s.btnBox) }>
                            <Button text={ 'показать больше' }
                                    modes={ [ `uppercase`, 'maxWidth', `mobilSmall`, `noRadius`, `grey` ] }
                                    isLoading={ isLoaderMoreBtn } click={ handleMoreTournament }/>
                        </div> }
                </div> }
            </div>
        </div>
    );
};
export const SelectCategory = ({ currentCategory, setCurrentCategory, currentTournament }: any) => {

    // const categoryList = useSelector(getAdminResultCategoryListSelector)
    const [ categoryList, setCategoryList ] = useState<any>(null)

    const [ showSelectBox, setShowSelectBox ] = useState<boolean>(false)

    useClickOutside(() => setShowSelectBox(false), [ `currentCategory` ])

    const getList = async () => {

        await new Promise((resolve) => {
            setTimeout(resolve, 300)
        })

        const response = await authFetch(() => authThunk.checkAuth())

        (`${ serverHttp }/api/category/list/${ Number(currentTournament.id) }`, {
            method: `GET`,
            headers: {
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()
        if (data?.error) {
            console.log(data.message)
            return
        }

        setCategoryList(data)
    }
    useEffect(() => {
        if (showSelectBox) {
            getList()
        }
        return () => {
            setCategoryList(null)
        }
    }, [ showSelectBox ])

    const handleSelectCategory = (e: any, category: any) => {
        e.stopPropagation()
        setCurrentCategory(category)
        setShowSelectBox(false)
    }

    return (
        <div className={ css(s.SelectTournament) }>
            <div className={ css(s.title) }>Выбрать категорию</div>
            <div id={ `currentCategory` } className={ css(s.currentTournament, showSelectBox && s.active) }
                 onClick={ () => setShowSelectBox(prev => !prev) }>
                <div
                    className={ css(s.name) }>{ currentCategory === null ? `Все категории` : currentCategory.name }</div>
                <div className={ css(s.showSelectBtn) }>
                    <svg width="30" height="30" viewBox="0 0 12 8" xmlns="https://www.w3.org/2000/svg">
                        <path
                            d="M5.59021 7.15047L0.452036 2.0122C0.333101 1.89336 0.267578 1.73471 0.267578 1.56556C0.267578 1.3964 0.333101 1.23776 0.452036 1.11891L0.83034 0.740515C1.07685 0.494289 1.47749 0.494289 1.72363 0.740515L6.03836 5.05525L10.3579 0.735728C10.4768 0.616886 10.6354 0.55127 10.8044 0.55127C10.9737 0.55127 11.1322 0.616886 11.2513 0.735728L11.6295 1.11413C11.7484 1.23306 11.8139 1.39161 11.8139 1.56077C11.8139 1.72993 11.7484 1.88857 11.6295 2.00741L6.48659 7.15047C6.36728 7.26959 6.20798 7.33502 6.03864 7.33464C5.86864 7.33502 5.70943 7.26959 5.59021 7.15047Z"/>
                    </svg>
                </div>
                { showSelectBox && <div className={ css(s.tournamentList) }>
                    <div className={ css(s.item) } onClick={ (e) => handleSelectCategory(e, null) }>Все категории</div>
                    { categoryList?.map(el => <div key={ el.id } className={ css(s.item) }
                                                   onClick={ (e) => handleSelectCategory(e, el) }>{ el.name }</div>) }
                    { categoryList === null && <div className={ css(s.loadingList) }>
                        <img src={ loadGif } alt="load"/>
                        Загрузка...
                    </div> }
                </div> }
            </div>
        </div>
    );
};
