import s from './style.module.scss'
import {css} from "lib/customClassName";
import {Outlet} from "react-router-dom";
import React from "react";
import { ManagerNavigate } from "view/module/manager/navigate";

export const ManagerLayout = () => {

    return (
        <div className={css(s.ManagerLayout)}>
            <div className={css(s.container)}>
                <ManagerNavigate />
                <Outlet/>
            </div>
        </div>
    );
};
