import s from './style.module.scss'
import {css} from "lib/customClassName";
import {Button} from "view/components/button";
import Empty_img from "view/assets/images/empty_img.jpg";
import {serverHttp} from "config/api/api";
import {useEffect, useState} from "react";
import {Popup} from "view/components/popup";
import {InputText} from "view/components/InputText";
import {useThunks} from "lib/reduxHook";
import {partnerThunk} from "store/partner/thunk/partnerThunk";
import {useSelector} from "react-redux";
import {getPartnerList} from "store/partner/selector/getPartnerList";
import loadGif from "view/assets/images/Load.gif";

export const PartnerPage = () => {

    const [editPartner, setEditPartner] = useState<any>(null)

    const partnerList = useSelector(getPartnerList)

    const {getList} = useThunks(partnerThunk)

    useEffect(() => {
        getList()
    }, [])

    return (
        <div className={css(s.PartnerPage)}>
            <div className={css(s.head)}>
                Управление партнёрами
            </div>
            <div className={css(s.addBtnBox)}>
                <Button text={`Добавить`} modes={[`maxWidth`,`mobilSmall`]} click={()=>setEditPartner({status: `new`, name: '', img: '', link: ''})}/>
            </div>
            <div className={css(s.list)}>
                {partnerList === null && <div className={css(s.loadingList)}>
                    <img src={loadGif} alt="load"/>
                    Загрузка...
                </div>}
                {partnerList !== null && !partnerList.length && <div className={css(s.emptyList)}>
                    Список партнёров пуст
                </div>}
                {partnerList?.map(partner =>
                    <div key={partner.id} className={css(s.partnerItem)}>
                        <div className={css(s.img)}>
                            {partner.img === '' && <img src={Empty_img} alt="partner_item" />}
                            {partner?.img !== '' && <img src={`${serverHttp}/upload/`+partner.img} alt="partner_item" />}
                        </div>
                        <div className={css(s.main)}>
                            <div className={css(s.name)}>
                                {partner.name}
                            </div>
                            <div className={css(s.btnBox)}>
                                <Button text={`Изменить`} modes={[`maxWidth`,`mobilSmall`]} click={()=>setEditPartner({status: `edit`, name: partner.name, img: partner.img, link: partner.link, id: partner.id})}/>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {editPartner !== null && <EditPartner editPartner={editPartner} close={()=>setEditPartner(null)}/>}
        </div>
    );
};

export const EditPartner = ({editPartner, close}:any) => {

    const [load, setLoad] = useState(false)
    const [newLogo, setNewLogo] = useState<any>(null)
    const [name, setName] = useState<string>(editPartner.name)
    const [link, setLink] = useState<string>(editPartner.link)

    const {create, edit, delete: deletePartner} = useThunks(partnerThunk)

    const changeLogoFilesHandler = (e:any) => {
        if (e.target.files && e.target.files[0]) {
            setNewLogo(e.target.files[0])
        }
    }

    const createPartnerHandler = async () => {
        setLoad(true)
        await create({
            name,
            link,
            newLogo: newLogo
        })
        close()
        setLoad(false)
    }
    const editPartnerHandler = async () => {
        setLoad(true)
        await edit({
            id: editPartner.id,
            name,
            link,
            newLogo: newLogo
        })
        close()
        setLoad(false)
    }
    const deletePartnerHandler = async () => {
        setLoad(true)
        await deletePartner(editPartner.id)
        close()
        setLoad(false)
    }

    return (
        <Popup clickCloseBtnCallback={close}>
            <div className={css(s.EditPartner)}>
                <div className={css(s.img)}>
                    {newLogo === null &&editPartner.img === '' && <img src={Empty_img} alt="tournament_item" />}
                    {newLogo === null &&editPartner?.img !== '' && <img src={`${serverHttp}/upload/`+editPartner.img} alt="tournament_item" />}
                    {newLogo !== null && <img src={URL.createObjectURL(newLogo)} alt="partner_avatar"/>}
                </div>
                <label className={css(s.changeFile)}>
                    <div className={css(s.fileName)}>редактировать изображение</div>
                    <input className={css(s.inputLogo)} type="file" accept={'.png, .jpg'} multiple={false}
                           onChange={changeLogoFilesHandler}/>
                </label>
                <div className={css(s.inputName)}>
                    <InputText value={name} change={setName} title={`Название`} modes={[`maxWidth`,`dark`]}/>
                    <InputText value={link} change={setLink} title={`Ссылка`} modes={[`maxWidth`,`dark`]}/>
                </div>
                <div className={css(s.btnBox)}>
                    {editPartner.status === `new` && <Button text={`Создать`} modes={[`maxWidth`,`mobilSmall`]} isLoading={load} click={createPartnerHandler}/>}
                    {editPartner.status === `edit` && <Button text={`Сохранить`} modes={[`maxWidth`,`mobilSmall`]} isLoading={load} click={editPartnerHandler}/>}
                    {editPartner.status === `edit` && <Button text={`Удалить`} modes={[`maxWidth`,`mobilSmall`,`red`]} isLoading={load} click={deletePartnerHandler}/>}
                </div>
            </div>
        </Popup>
    );
};
