import s from './index.module.scss'
import {useThunks} from "lib/reduxHook"
import {authThunk} from "store/auth/thunk/authThunk"
import {css} from "lib/customClassName"
import {useAuth} from "../../../store/auth/hook/useAuth";
import {useState} from "react";

type PropsType = {
    closePopup: Function
}

export const EmailWarningPopup = (props: PropsType) => {
    const {closePopup} = props

    const user = useAuth()
    const {getNewConfirmEmailLink} = useThunks(authThunk)

    const [loading, setLoading] = useState<boolean>(false)
    const [successMessage, setSuccessMessage] = useState<string|null>(null)
    const [error, setError] = useState<string|null>(null)

    const errorCallback = (str: string) => {
        setError(str)
    }

    const successCallback = () => {
        setSuccessMessage('Новая ссылка отправлена по указанному адресу')
    }

    const getNewLinkHandler = async (e:any) => {
        e.preventDefault()
        if (!user) return
        if (user.id === undefined) return

        setError(null)
        setLoading(true)

        await getNewConfirmEmailLink({
            data: {id: user.id},
            errorCallback,
            successCallback,
        })
        setLoading(false)
    }

    return (
        <div className={css(s.EmailWarningPopup)}>
            <div className={css(s.title)}>Почта не подтверждена!</div>
            {(successMessage || error) && <div className={css(s.subtitle, successMessage ? s.success : s.error)}>{successMessage || error}</div>}
            <div className={css(s.info)}>
                <div className={css(s.item)}>На указанную вами почту отправлена ссылка для потверждения</div>
                <div className={css(s.item)}>Если вы не подтвердите почту, то можете потерять аккаунт</div>
                {!successMessage && <div className={css(s.item, s.buttonBlock)}>
                    <span>Не пришло сообщение?</span>
                    <button onClick={getNewLinkHandler} disabled={loading} className={css(s.button)}>Отправить новую ссылку</button>
                </div>}
            </div>
        </div>
    )
}
