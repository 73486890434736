import s from './style.module.scss'
import {css} from "lib/customClassName";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import {useSelector} from "react-redux";
import {getFullTournamentSelector} from "store/tournament/selector/getFullTournament";
import {TournamentHead} from "view/module/tournament/tournamentHead";
import {TournamentDescription} from "view/module/tournament/tournamentDescription";
import {TournamentPartners} from "view/module/tournament/tournamentPartners";
import {TournamentTable} from "view/module/tournament/tournamentTable";
import React, { useEffect, useLayoutEffect } from "react";
import { useActions, useThunks } from "lib/reduxHook";
import {tournamentThunk} from "store/tournament/thunk/tournamentThunk";
import loadGif from "view/assets/images/Load.gif";
import { TournamentActions } from "store/tournament/reducer/tournamentReducer";
import {Helmet} from "react-helmet";
import { DESCRIPTION, KEYWORDS } from "config/constants";

export const TournamentPage = () => {
    const {id} = useParams()
    const navigate = useNavigate()
    const tournament = useSelector(getFullTournamentSelector)

    const {setFullTournamentAction} = useActions(TournamentActions)

    const {getFullTournament} = useThunks(tournamentThunk)

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    },[])

    useEffect(() => {
        getFullTournament(Number(id))
        // return () => {
        //     setFullTournamentAction(null)
        // }
    },[])

    if (!id) return null
    if (tournament === null) {
        return null
        // return <div className={css(s.loadingList)}>
        //     <img src={loadGif} alt="load"/>
        //     <span>Загрузка...</span>
        // </div>
    }
    return (
        <div className={css(s.TournamentPage)}>
            <Helmet>
                <title>Турнир | {tournament.name}</title>
                <meta name="description" content={!tournament.descriptionSmall || !tournament.descriptionSmall.length ? DESCRIPTION : tournament.descriptionSmall} />
                <meta name="keywords" content={!tournament.keywords || !tournament.keywords.length ? KEYWORDS : tournament.keywords} />
            </Helmet>
            <div className={css(s.container)}>
                <TournamentHead tournament={tournament}/>
                {tournament.isShowTable && <TournamentTable paidMe={tournament?.paidMe[0] ?? null} tournamentStatus={tournament.status}/>}
                <TournamentDescription text={tournament.description}/>
                {!!tournament.partnerList.length && <TournamentPartners partnerList={tournament.partnerList}/>}
            </div>
            <Outlet />
        </div>
    );
};
