import s from './style.module.scss'
import { css } from "lib/customClassName";
import { DOMAIN } from "config/constants";
import { Button } from "view/components/button";
import { useNavigate } from "react-router-dom";
import { useLayoutEffect } from "react";

export const PublicOffer = () => {
    const navigate = useNavigate()

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    },[])

    return (
        <div className={ css(s.PublicOffer) }>
            <Button text={`←    Назад`} modes={[`mobilSmall`]} click={()=>navigate(-1)}/>
            <h1><strong>Договор публичной оферты</strong></h1>
            <p>1.1. Данный документ является официальной публичной офертой&nbsp;ИП Салманов Александр
                Вячеславович&nbsp;(в дальнейшем именуемого “Исполнитель”) и содержит все существенные условия
                предоставления Услуг на сайте Исполнителя.</p>
            <p>1.2. В соответствии с п. 2 ст. 437 Гражданского Кодекса РФ в случае принятия изложенных ниже условий
                и оплаты услуг юридическое или физическое лицо, производящее акцепт этой оферты становится
                Заказчиком (в соответствии с п. 3 ст. 438 ГК РФ акцепт оферты равносилен заключению договора на
                условиях, изложенных в оферте), а Исполнитель и Заказчик совместно — Сторонами договора Оферты.</p>
            <p>&nbsp;</p>
            <ol>
                <li>ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</li>
            </ol>
            <p>&nbsp;В целях настоящей Оферты нижеприведенные термины используются в следующем значении:</p>
            <p>2.1. «Оферта» — настоящий документ Публичная Оферта предоставления услуг на сайте
                Исполнителя.&nbsp;</p>
            <p>2.2. «Сайт Исполнителя» – сайт, расположенный по адресу:&nbsp;<a href={DOMAIN} target="_self"
                                                                                rel="noopener">{DOMAIN}.</a>
            </p>
            <p>2.3. «Акцепт Оферты» – совершение Заказчиком действий, указанных в настоящей Оферте,
                свидетельствующих о принятии данным лицом условий Оферты в полном объеме, в том числе совершении
                действий по выполнению указанных в настоящей Оферте условий.&nbsp;</p>
            <p>2.4. «Заказчик» — лицо, осуществившее Акцепт Оферты, и становящееся таким образом Заказчиком Услуг
                Исполнителя по заключенному данному договору.</p>
            <p>2.5. «Договор» – означает настоящий Договор-оферту на оказание Услуг, выбранных Заказчиком на Сайте
                Исполнителя, заключенный между Исполнителем и Заказчиком в результате Акцепта Заказчиком Оферты,
                наделяющий Исполнителя и Заказчика правами и обязанностями, указанными в настоящей Оферте.</p>
            <p>2.6. «Стороны» – именуемые совместно стороны Договора – Исполнитель и Заказчик.&nbsp;</p>
            <p>2.7. «Услуга» / «Услуги» – деятельность Исполнителя, связанная с оказанием услуг по предоставлению
                доступа Заказчикам к отдельным разделам Сайта Исполнителя, либо с предоставлением доступа к контенту
                Сайта Исполнителя. Описание Услуг, их условия и стоимость доступны на Сайте Исполнителя:&nbsp;<a
                    href={DOMAIN} target="_self" rel="noopener">{DOMAIN}.</a></p>
            <p>2.8. Условия выбранных Заказчиком Услуг становятся частью Договора.&nbsp;</p>
            <p>&nbsp;</p>
            <ol>
                <li>ОБЩИЕ ПОЛОЖЕНИЯ</li>
            </ol>
            <p>3.1. Настоящая оферта определяет все существенные условия договора между Исполнителем и Заказчиком, в
                том числе порядок оказания Услуг Исполнителя.&nbsp;</p>
            <p>3.2. Оферта, а также вся информация об Услугах Исполнителя: стоимость, виды и сроки оказания Услуг
                опубликованы на Сайте Исполнителя.&nbsp;</p>
            <p>&nbsp;</p>
            <ol>
                <li>ПРЕДМЕТ ДОГОВОРА</li>
            </ol>
            <p>4.1. Предметом настоящей оферты является предоставление Заказчику Услуг, опубликованных на сайте
                Исполнителя.</p>
            <p>4.2. Заказчик обязуется оплачивать Услуги на условиях и в порядке, определенных настоящим
                Договором.&nbsp;</p>
            <p>&nbsp;</p>
            <ol>
                <li>ПОРЯДОК ЗАКЛЮЧЕНИЯ ДОГОВОРА. СПОСОБЫ ОПЛАТЫ УСЛУГ</li>
            </ol>
            <p>5.1. Надлежащим безоговорочным Акцептом настоящей Оферты в соответствии со статьей 438 Гражданского
                кодекса Российской Федерации является оплата Клиентом Услуг Исполнителя на условиях 100% предоплаты,
                если иное не указано в предложении Услуги.&nbsp;</p>
            <p>5.2. Стоимость Услуг и способы оплаты Услуг Заказчиком, описание предоставляемых Исполнителем
                дополнительных возможностей для оплаты и получения Услуг публикуются на Сайте Исполнителя. Оплачивая
                и/или заказывая Услуги, Заказчик выражает полное и безоговорочное согласие с действующими на момент
                оплаты условиями Оферты, частью которых являются условия оказания Услуг.&nbsp;</p>
            <p>&nbsp;</p>
            <ol>
                <li>ПРАВА И ОБЯЗАННОСТИ СТОРОН</li>
            </ol>
            <p>6.1. Заказчик обязуется:&nbsp;</p>
            <p>6.1.1. Полностью оплатить Услуги в порядке 100%-ной предварительной оплаты до начала их
                оказания;&nbsp;</p>
            <p>6.1.2. Сообщать Исполнителю сведения (в том числе, персональные данные), необходимые Исполнителю для
                предоставления Заказчику Услуг и исполнения других своих обязательств, установленных Договором и
                действующим законодательством РФ;&nbsp;</p>
            <p>6.1.3. Не копировать, не распространять, не доводить до всеобщего сведения, а также любым иным
                образом не использовать любые результаты интеллектуальной деятельности, которые становятся доступны
                Заказчику в процессе или в результате оказания Услуг.&nbsp;</p>
            <p>6.2. Заказчик имеет право получить заказанную Услугу при условии ее предварительной оплаты.&nbsp;</p>
            <p>6.3. Исполнитель обязуется оказать выбранную Заказчиком Услугу качественно и с учетом описания
                Услуги, которая выбрана Заказчиком.&nbsp;</p>
            <p>6.4. Исполнитель имеет право:&nbsp;</p>
            <p>6.4.1. Самостоятельно избирать форму оказания Услуг с учетом условий оказания Услуг, выбранных
                Заказчиком;</p>
            <p>6.4.2. Оказывать услуги с привлечением сторонних специалистов;&nbsp;</p>
            <p>6.4.3. В любое время полностью или частично изменять описание Услуг, с учетом предварительного
                уведомления Заказчика. Такие изменения и дополнения вступают в силу немедленно после
                соответствующего уведомления, которое может быть направлено Заказчику в любой форме, включая, но, не
                ограничиваясь, размещением соответствующего уведомления на Сайте Исполнителя и/или либо иным
                образом.&nbsp;</p>
            <p>&nbsp;</p>
            <ol>
                <li>ОСОБЫЕ УСЛОВИЯ И ОТВЕТСТВЕННОСТЬ СТОРОН</li>
            </ol>
            <p>7.1. Заказчик несет ответственность за полноту и достоверность сведений (в том числе персональных
                данных), предоставленных Исполнителю при заказе Услуг. Заказчик самостоятельно и в полном объеме
                несет все риски, связанные с отсутствием у Исполнителя актуальных сведений (в том числе персональных
                данных).&nbsp;</p>
            <p>7.2. Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение своих
                обязательств по Договору, если докажут, что надлежащее исполнение оказалось невозможным вследствие
                обстоятельств непреодолимой силы, которые стороны не могли предвидеть и избежать — форс-мажорных
                обстоятельств. При этом наличие форс-мажорных обстоятельств продлевает срок исполнения Сторонами
                принятых на себя по Договору обязательств до прекращения соответствующих форс-мажорных
                обстоятельств.</p>
            <p>&nbsp;</p>
            <ol>
                <li>ХРАНЕНИЕ И ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ</li>
            </ol>
            <p>8.1. Заказчик в соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных» в
                результате Акцепта Оферты даёт Исполнителю согласие на сбор, хранение и обработку, в том числе,
                автоматизированную, информации, относящейся к персональным данным (далее «Персональные данные»)
                Заказчика либо третьего лица, в интересах которого Заказчик заключает договор (фамилию, имя,
                отчество, адрес регистрации, места жительства, контактные телефоны, адреса электронной почты)
                включая сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение),
                использование, распространение (в том числе передачу), обезличивание, блокирование, уничтожение
                персональных данных. Обработка Персональных данных осуществляется в целях заключения с Исполнителем
                Договора на основании настоящей Оферты, любых иных договоров и их дальнейшего исполнения,
                осуществления расчётов с Заказчиком, принятия решений или совершения иных действий, порождающих
                юридические последствия в отношении Заказчика или третьих лиц, предоставления Заказчику информации
                об оказываемых Исполнителем услугах, исполнения договорных обязательств перед третьими лицами, а
                также в целях информирования Заказчика, об изменениях в условиях оказания Услуг, условиях Оферты, о
                новых продуктах и услугах, разрабатываемых и/или предлагаемых Исполнителем и/или его контрагентами и
                партнерами. Заказчик при Акцепте Оферты соглашается на получение рекламной информации.&nbsp;</p>
            <p>8.2. Согласие, данное Заказчиком в отношении обработки персональных данных, указанное в п. 8.1.
                Оферты, дается Исполнителю до истечения сроков хранения соответствующей информации или документов,
                содержащих вышеуказанную информацию, определяемых в соответствии с законодательством Российской
                Федерации, после чего может быть отозвано путем направления Заказчиком соответствующего письменного
                уведомления Исполнителю не менее чем за 3 (три) месяца до момента отзыва согласия. Отзыв согласия на
                обработку персональных данных автоматически означает односторонний отказ от Услуг
                Заказчика.&nbsp;</p>
            <p>&nbsp;</p>
            <ol>
                <li>СРОК ДЕЙСТВИЯ ДОГОВОРА</li>
            </ol>
            <p>9.1. Договор вступает в силу с момента Акцепта настоящей Оферты в порядке, установленном п. 4.1
                Договора, и действует в течение срока оказания Услуг в части обязательств по оказанию Услуг и в
                течение иных сроков, если такие сроки, указаны в Оферте (например, в отношении согласия на обработку
                персональных данных).</p>
            <p>&nbsp;</p>
            <ol>
                <li>РЕКВИЗИТЫ ИСПОЛНИТЕЛЯ:</li>
            </ol>
            <p>Индивидуальный предприниматель Салманов Александр Вячеславович,</p>
            <p>ИНН 740904049526, ОГРНИП: 321745600053963.</p>
            <p>Юридический адрес: 454080, г. Челябинск, ул. Южная, д. 2а, кв.59.</p>
            <p>Расчетный счет: 40802810672000010438 в ЧЕЛЯБИНСКОМ ОТДЕЛЕНИИ №8597 ПАО СБЕРБАНК. Корреспондентский
                счет: 30101810700000000602, БИК: 047501602.</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </div>
    )
};
