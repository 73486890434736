import {AppDispatch, RootState} from "store/ReduxStore";
import {ACCESS_TOKEN, REFRESH_TOKEN} from "config/constants";
import {AuthActions} from "store/auth/reducer/authReducer";
import {serverHttp} from "config/api/api";
import {UserRoleType} from "types/UserType";
import jwt_decode from 'jwt-decode';
import {authFetch} from "lib/authFetch";
import {TournamentActions} from "store/tournament/reducer/tournamentReducer";

export const authThunk = {

    registration: (params: {
        data: { login: string, pswd: string },
        errorCallback: (str: string) => void
    }) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {

            // // await new Promise(res => setTimeout(() => res(''), 1000))

            const response = await fetch(`${serverHttp}/api/auth/registration`, {
                method: `POST`,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    login: params.data.login,
                    password: params.data.pswd,
                })
            })
            const data = await response.json()

            if (data?.error) {
                console.log(data.message)
                params.errorCallback(data.message)
                return
            }

            localStorage.setItem(REFRESH_TOKEN, data.refreshToken)
            localStorage.setItem(ACCESS_TOKEN, data.accessToken)
            const user = jwt_decode(data.accessToken) as {
                id: number
                role: UserRoleType
                login: string
                confirmed: boolean
            }

            dispatch(AuthActions.setUserAction({
                id: user.id,
                login: user.login,
                role: user.role,
                confirmed: user.confirmed
            }))

        } catch (error: any) {
            console.log('error client', error)
        }
    },
    getNewConfirmEmailLink: (params: {
        data: { id: number },
        errorCallback: (str: string) => void,
        successCallback: () => void,
    }) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            // // await new Promise(res => setTimeout(() => res(''), 1000))

            if (!params.data.id) {
                params.errorCallback('Непредвиденная ошибка')
                return
            }

            const response:any = await authFetch(() => dispatch(authThunk.checkAuth()))
            (`${serverHttp}/api/auth/send-registration-link`, {
                method: `POST`,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: params.data.id,
                })
            })

            const data = await response.json()

            if (data?.error) {
                console.log(data.message)
                params.errorCallback(data.message)
                return
            }

            if (!data?.error) {
                params.successCallback()
                return
            }

        } catch (error: any) {
            console.log('error client', error)
        }
    },

    changePassword: (params: {
        data: { oldPassword: string, newPassword: string },
        errorCallback: (str: string) => void,
        successCallback: () => void,
    }) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            // // await new Promise(res => setTimeout(() => res(''), 1000))

            const accessToken = localStorage.getItem(ACCESS_TOKEN)
            if (!accessToken) {
                localStorage.removeItem(REFRESH_TOKEN)
                localStorage.removeItem(ACCESS_TOKEN)
                dispatch(AuthActions.setUserAction({role: ['guest'], login: 'Гость'}))
                return
            }
            const response:any = await authFetch(() => dispatch(authThunk.checkAuth()))
            (`${serverHttp}/api/auth/change-password`, {
                method: `PUT`,
                headers: {
                    'Content-Type': 'application/json',
                    'jwt': `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    oldPassword: params.data.oldPassword,
                    newPassword: params.data.newPassword,
                })
            })
            const data = await response.json()

            if (data?.error) {
                console.log(data.message)
                params.errorCallback(data.message)
                return
            }

            if (!data?.error) {
                params.successCallback()
                return
            }

        } catch (error: any) {
            console.log('error client', error)
        }
    },

    // отправляем ссылку на почту
    getRemindPasswordLink: (params: {
        data: { email: string },
        errorCallback: (str: string) => void,
        successCallback: () => void,
    }) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            // // await new Promise(res => setTimeout(() => res(''), 1000))

            const response = await fetch(`${serverHttp}/api/auth/remind-password`, {
                method: `POST`,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    login: params.data.email,
                })
            })

            const data = await response.json()

            if (data?.error) {
                console.log(data.message)
                params.errorCallback(data.message)
                return
            }

            if (!data?.error) {
                params.successCallback()
                return
            }

        } catch (error: any) {
            console.log('error client', error)
        }
    },
    // смена пароля по коду
    resetPassword: (params: {
        data: { token: string, password: string },
        errorCallback: (str: string) => void,
        successCallback: () => void,
    }) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            await new Promise(res => setTimeout(() => res(''), 300))

            const response = await fetch(`${serverHttp}/api/auth/reset-password`, {
                method: `PUT`,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: params.data.token,
                    password: params.data.password,
                })
            })

            const data = await response.json()

            if (data?.error) {
                params.errorCallback(`${data.message}`)
                return
            }

            localStorage.setItem(REFRESH_TOKEN, data.refreshToken)
            localStorage.setItem(ACCESS_TOKEN, data.accessToken)

            const user = jwt_decode(data.accessToken) as {
                id: number
                role: UserRoleType
                login: string
                confirmed: boolean
            }

            dispatch(AuthActions.setUserAction({
                id: user.id,
                login: user.login,
                role: user.role,
                confirmed: user.confirmed
            }))

        } catch (error: any) {
            console.log('error client', error)
        }
    },
    // проверка действительная ли ссылка или нет, логика ДЕРЬМО! (Кирилл!!!)
    verifyRemindPasswordLink: (params: {
        data: { token: string },
        errorCallback: (str: string) => void,
    }) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            // // await new Promise(res => setTimeout(() => res(''), 1000))

            const response = await fetch(`${serverHttp}/api/auth/verify-remind-link`, {
                method: `POST`,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: params.data.token,
                })
            })

            const data = await response.json()

            if (data?.error) {
                params.errorCallback(`${data.message}`)
                return
            }

            if (!data?.error) {
                return
            }

        } catch (error: any) {
            console.log('error client', error)
        }
    },

    checkAuth: (delay?: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const accessToken = localStorage.getItem(ACCESS_TOKEN)
            const refreshToken = localStorage.getItem(REFRESH_TOKEN)
            if (!accessToken || !refreshToken) {
                localStorage.removeItem(REFRESH_TOKEN)
                localStorage.removeItem(ACCESS_TOKEN)
                dispatch(AuthActions.setUserAction({role: ['guest'], login: 'Гость'}))
                return
            }

            const oldUser = jwt_decode(accessToken) as {
                id: number
                role: UserRoleType
                login: string
                confirmed: boolean
            }

            dispatch(AuthActions.setUserAction({
                id: oldUser.id,
                login: oldUser.login,
                role: oldUser.role,
                confirmed: oldUser.confirmed
            }))

            await new Promise(res => setTimeout(() => res(''), delay ?? 0))


            const response = await fetch(`${serverHttp}/api/auth/refresh`, {
                method: `POST`,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({token: refreshToken})
            })
            const data = await response.json()
            if (data?.error) {
                console.log(data.message)
                localStorage.removeItem(REFRESH_TOKEN)
                localStorage.removeItem(ACCESS_TOKEN)
                dispatch(AuthActions.setUserAction({role: ['guest'], login: 'Гость'}))
                return
            }

            localStorage.setItem(REFRESH_TOKEN, data.refreshToken)
            localStorage.setItem(ACCESS_TOKEN, data.accessToken)
            const user = jwt_decode(data.accessToken) as {
                id: number
                role: UserRoleType
                login: string
                confirmed: boolean
            }

            dispatch(AuthActions.setUserAction({
                id: user.id,
                login: user.login,
                role: user.role,
                confirmed: user.confirmed
            }))

        } catch (error: any) {
            dispatch(AuthActions.setUserAction({role: ['guest'], login: 'Гость'}))
            console.log('error client', error)
        }
    },
    login: (params: {
        data: { login: string, pswd: string },
        errorCallback: (str: string) => void
    }) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {

            // // await new Promise(res => setTimeout(() => res(''), 1000))

            const response = await fetch(`${serverHttp}/api/auth/login`, {
                method: `POST`,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    login: params.data.login,
                    password: params.data.pswd,
                })
            })
            const data = await response.json()
            if (data?.error) {
                console.log(data.message)
                params.errorCallback(data.message)
                return
            }

            localStorage.setItem(REFRESH_TOKEN, data.refreshToken)
            localStorage.setItem(ACCESS_TOKEN, data.accessToken)
            const user = jwt_decode(data.accessToken) as {
                id: number
                role: UserRoleType
                login: string
                confirmed: boolean
            }

            dispatch(AuthActions.setUserAction({
                id: user.id,
                login: user.login,
                role: user.role,
                confirmed: user.confirmed
            }))

        } catch (error: any) {
            console.log('error client', error)
        }
    },
    logout: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const refreshToken = localStorage.getItem(REFRESH_TOKEN)
            if (!refreshToken) {
                dispatch(AuthActions.setUserAction({role: ['guest'], login: 'Гость'}))
                return
            }

            const response = await fetch(`${serverHttp}/api/auth/logout`, {
                method: `POST`,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: refreshToken,
                })
            })
            const data = await response.json()

            if (data?.error) {
                console.log(data.message)
                return
            }

        } catch (error: any) {
            console.log('error client', error)
        } finally {
            localStorage.removeItem(REFRESH_TOKEN)
            localStorage.removeItem(ACCESS_TOKEN)
            window.location.replace('/')
            // dispatch(AuthActions.setUserAction({role: ['guest'], login: 'Гость'}))
        }
    },

    getUserList: (tournamentId?: number | undefined, categoryId?: number | undefined) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            // await new Promise((resolve) => {
            //     setTimeout(resolve, 1000)
            // })

            const pagination = getState().AuthReducer.userListPagination
            const total = getState().AuthReducer.userList !== null ? getState().AuthReducer.userList?.length : 0

            const response:any = await authFetch(() => dispatch(authThunk.checkAuth()))
            (`${serverHttp}/api/auth/user-list?limit=${pagination.limit}&offset=${total}${tournamentId ? '&tournamentId='+tournamentId : ''}${categoryId ? '&categoryId='+categoryId : ''}`, {
                method: `GET`,
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            const data = await response.json()
            if (data?.error) {
                console.log(data.message)
                return
            }

            dispatch(AuthActions.setUserListAction(data.rows))
            dispatch(AuthActions.setUserListPaginationAction({limit: pagination.limit, total: data.count}))

        } catch (error: any) {
            console.log('error client', error)
        } finally {
            // dispatch(FetchingActions.setIsLoadingUserListAction(false))
        }
    },
    getTournamentUserList: (tournamentId?: number | undefined, categoryId?: number | undefined) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            // await new Promise((resolve) => {
            //     setTimeout(resolve, 1000)
            // })

            const pagination = getState().AuthReducer.userListPagination
            const total = getState().AuthReducer.userList !== null ? getState().AuthReducer.userList?.length : 0

            const response:any = await authFetch(() => dispatch(authThunk.checkAuth()))
            (`${serverHttp}/api/auth/tournament-user-list?limit=${pagination.limit}&offset=${total}${tournamentId ? '&tournamentId='+tournamentId : ''}${categoryId ? '&categoryId='+categoryId : ''}`, {
                method: `GET`,
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            const data = await response.json()
            if (data?.error) {
                console.log(data.message)
                return
            }

            const currentCountList = getState().AuthReducer.userList !== null ? getState().AuthReducer.userList?.length : 0
            if (currentCountList === 0 && total !== 0) {
                return
            }

            dispatch(AuthActions.setUserListAction(data.rows))
            dispatch(AuthActions.setUserListPaginationAction({limit: pagination.limit, total: data.count}))

        } catch (error: any) {
            console.log('error client', error)
        } finally {
            // dispatch(FetchingActions.setIsLoadingUserListAction(false))
        }
    },
    blocked: (userId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            await new Promise((resolve) => {
                setTimeout(resolve, 300)
            })

            const response:any = await authFetch(() => dispatch(authThunk.checkAuth()))
            (`${serverHttp}/api/auth/blocked?userId=${userId}`, {
                method: `GET`,
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            const data = await response.json()
            if (data?.error) {
                console.log(data.message)
                return
            }

            dispatch(AuthActions.updateUserListItemAction(data))

        } catch (error: any) {
            console.log('error client', error)
        } finally {
            // dispatch(FetchingActions.setIsLoadingUserListAction(false))
        }
    },
    setManager: (userId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            await new Promise((resolve) => {
                setTimeout(resolve, 300)
            })

            const response:any = await authFetch(() => dispatch(authThunk.checkAuth()))
            (`${serverHttp}/api/auth/set-manager?userId=${userId}`, {
                method: `GET`,
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            const data = await response.json()
            if (data?.error) {
                console.log(data.message)
                return
            }

            dispatch(AuthActions.updateUserListItemAction(data))

        } catch (error: any) {
            console.log('error client', error)
        } finally {
            // dispatch(FetchingActions.setIsLoadingUserListAction(false))
        }
    },
}
