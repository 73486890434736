import s from './style.module.scss'
import {css} from "lib/customClassName";
import {Button} from "view/components/button";
import loadGif from "view/assets/images/Load.gif";
import {TournamentItem} from "view/components/tournamentItem";
import {useSelector} from "react-redux";
import {getTournamentListSelector} from "store/tournament/selector/getTournamentList";
import {useActions, useThunks} from "lib/reduxHook";
import {tournamentThunk} from "store/tournament/thunk/tournamentThunk";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {TournamentActions} from "store/tournament/reducer/tournamentReducer";
import {getTournamentListPaginationSelector} from "store/tournament/selector/getTournamentListPagination";

export const AdminTournamentList = () => {

    const navigate = useNavigate()

    const tournamentList = useSelector(getTournamentListSelector)

    const tournamentListPagination = useSelector(getTournamentListPaginationSelector)

    const [isLoaderMoreBtn, setIsLoaderMoreBtn] = useState(false)

    const [filter, setFilter] = useState('all')

    const {setTournamentListAction} = useActions(TournamentActions)

    const {getAdminList, changePosition} = useThunks(tournamentThunk)

    useEffect(() => {
        const ctrl = new AbortController();
        getAdminList(filter, ctrl.signal)
        return () => {
            ctrl && ctrl.abort();
            setTournamentListAction(null)
        }
    }, [filter])

    const handleMoreTournament = async () => {
        setIsLoaderMoreBtn(true)
        await getAdminList(filter)
        setIsLoaderMoreBtn(false)
    }

    const handleSetFilter = async (name: string) => {
        setFilter(name)
    }

    const handleChangePosition = async (tournamentId: number, isPrev: boolean) => {

        if (!tournamentList) return
        const itemIndex = tournamentList?.findIndex(el => el.id === tournamentId)
        if (itemIndex === undefined) return
        if (isPrev) {
            if (itemIndex === 0) return
            const prevItemId = tournamentList[itemIndex - 1]?.id
            await changePosition(tournamentId,prevItemId ?? tournamentId)
        } else {
            if (itemIndex === tournamentList.length - 1) return
            const prevItemId = tournamentList[itemIndex + 1]?.id
            await changePosition(tournamentId,prevItemId ?? tournamentId)
        }
    }

    return (
        <div className={css(s.TournamentList)}>
            <div className={css(s.container)}>
                <div className={css(s.head)}>
                    <div className={css(s.headTitle)}>
                        редактирование турниров
                    </div>
                    <Button text={`Добавить +`} modes={[`maxWidth`,`red`]} click={() => navigate(`/admin/tournament/edit`)}/>
                </div>
                <div className={css(s.filter)}>
                    <Button text={'все'} modes={[`uppercase`, `mobilSmall`, filter === 'all' ? `active`:``]} click={()=>handleSetFilter('all')}/>
                    <Button text={'скоро старт'} modes={[`uppercase`, `mobilSmall`, filter === 'soon' ? `active`:``]} click={()=>handleSetFilter('soon')}/>
                    <Button text={'сейчас идет'} modes={[`uppercase`, `mobilSmall`, filter === 'work' ? `active`:``]} click={()=>handleSetFilter('work')}/>
                    <Button text={'закончен'} modes={[`uppercase`, `mobilSmall`, filter === 'finish' ? `active`:``]} click={()=>handleSetFilter('finish')}/>
                </div>
                <div className={css(s.list)}>
                    {/*{tournamentList === null && <div className={css(s.loadingList)}>*/}
                    {/*    <img src={loadGif} alt="load"/>*/}
                    {/*</div>}*/}
                    {tournamentList?.map((item, index) =>
                        <TournamentItem key={item.id} item={item} isEditable={true} changePosition={handleChangePosition}/>
                    )}
                    {tournamentList !== null && !tournamentList.length && <div className={css(s.emptyList)}>
                        Список турниров пуст
                    </div>}
                </div>
                {tournamentList !== null && tournamentList.length < (tournamentListPagination.total ? tournamentListPagination.total : 0) &&
                    <div className={css(s.btnBox)}>
                        <Button text={'показать больше'} modes={[`uppercase`, 'maxWidth', `mobilSmall`]} isLoading={isLoaderMoreBtn} click={handleMoreTournament}/>
                    </div>}
            </div>
        </div>
    );
};
