import s from './style.module.scss'
import { css } from "lib/customClassName";
import { useNavigate } from "react-router-dom";
import { Button } from "view/components/button";
import { useLayoutEffect } from "react";

export const Cookies = () => {
    const navigate = useNavigate()

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    },[])

    return (
        <div className={ css(s.Cookies) }>
            <Button text={`←    Назад`} modes={[`mobilSmall`]} click={()=>navigate(-1)}/>
            <h1><strong>Уведомление о файлах cookie</strong></h1>
                {/*<p>Уведомление ООО "КузницаСпорта", в дальнейшем компания, о файлах cookie, действующее с момента публикации.</p>*/}
                <p><strong>Дополнительные сведения о файлах cookie.</strong></p>
                <p>Компания стремится к тому, чтобы взаимодействие с нашими веб-сайтами было максимально информативным и отвечало вашим интересам. Для этого мы используем файлы cookie и подобные средства. Мы считаем, что вам важно знать, какие файлы cookie использует наш веб-сайт и для каких целей. Это поможет защитить ваши персональные сведения и обеспечит максимальное удобство нашего веб-сайта для пользователя.</p>
                <p><strong>Что из себя представляют файлы cookie? </strong></p>
                <p>Файлы cookie представляют собой небольшие текстовые файлы, которые сохраняются на вашем компьютере или мобильном устройстве при посещении определенных веб-сайтов. Компанией могут использоваться подобные технологии, например пиксели, веб-маяки, "отпечатки" устройства и т. д. В дальнейшем все эти технологии будут именоваться собирательно "файлы cookie".</p>
                <p><strong>Для чего используются файлы cookie? </strong></p>
                <p>Файлы cookie могут использоваться в различных целях. Во-первых, они могут требоваться для нормальной работы веб-сайта. Например, без файлов cookie веб-сайт не сможет запомнить, что вы выполнили вход или поместили товары в корзину. Такие файлы cookie называются строго необходимыми. Файлы cookie также нужны для анализа использования веб-сайта, подсчета количества посетителей и усовершенствования веб-сайта. Мы не ассоциируем статистику использования веб-сайта и другие отчеты с конкретными людьми. Такие файлы cookie называются аналитическими. В третьих, файлы cookie для социальных сетей используются для интеграции социальных сетей с веб-сайтом, чтобы вы могли использовать функции "Нравится" и "Поделиться" в любимой социальной сети. Четвертая, важная сфера применения файлов cookie — онлайн-реклама. С их помощью на веб-сайтах компании и других веб-сайтах отображаются только наиболее полезные и интересные для вас объявления. Такие файлы cookie называются рекламными.&nbsp;</p>
                <p><strong>Как управлять файлами cookie или отключать их в браузере?</strong></p>
                <p>Вы можете найти информацию о том, как отключить файлы cookie или изменить настройки файлов cookie для вашего браузера, перейдя по следующим ссылкам:</p>
            <p>&nbsp;</p>
                <ul>
                    <li>Google Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=ru">https://support.google.com/chrome/answer/95647?hl=ru</a></li>
                    <li>Opera:&nbsp;<a href="https://help.opera.com/Mac/10.63/ru/cookies.html">https://help.opera.com/Mac/10.63/ru/cookies.html</a>&nbsp;</li>
                    <li>Firefox <a href="https://support.mozilla.org/ru/kb/vklyuchenie-i-otklyuchenie-kukov-ispolzuemyh-veb-s">https://support.mozilla.org/ru/kb/vklyuchenie-i-otklyuchenie-kukov-ispolzuemyh-veb-s </a></li>
                    <li>Internet Explorer: <a href="https://windows.microsoft.com/en-GB/windows-vista/Block-or-allow-cookies">https://windows.microsoft.com/en-GB/windows-vista/Block-or-allow-cookies </a></li>
                    <li>Safari: <a href="https://help.apple.com/safari/mac/8.0/#/sfri11471">https://help.apple.com/safari/mac/8.0/#/sfri11471</a>&nbsp;</li>
                </ul>
        </div>
    );
};
