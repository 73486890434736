import s from './index.module.scss'
import {css} from "lib/customClassName";
import React, {InputHTMLAttributes, useMemo} from "react";

interface PropsType extends InputHTMLAttributes<HTMLTextAreaElement> {
    title?: string
    value: string
    modes?: Array<'maxWidth'|'white'>
    change?: Function
}
export const InputTextArea = (props: PropsType) => {
    const {title, placeholder = '', value, modes: modeProps = [''], change = (str: string) => {}, ...other} = props

    const modes = useMemo(() => {
        return modeProps.map(el => s[el])
    }, [modeProps])

    const changeHandler:any = (e:any) => {
        change(e.target.value)
    }

    return (
        <div className={css(s.InputTextArea)}>
            <div className={css(s.title)}>{title}</div>
            <textarea placeholder={placeholder} value={value} className={css(s.textarea, ...modes)} onChange={changeHandler} {...other}/>
        </div>
    );
};
